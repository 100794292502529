import { nls } from 'shared/locale/language';
import React from 'react';
import {
  DialogActions,
  DialogButton,
  DialogContent,
  DialogContentText,
  DialogProgressBar,
  DialogWrapper,
} from './common';
import { DialogProps } from './types';

export default function Alert({
  content,
  progressBar = false,
  primaryButtonProps,
  ...rest
}: Omit<DialogProps, 'type'>) {
  const { children, color, ...restButtonProps } = primaryButtonProps || {};
  return (
    <DialogWrapper {...rest}>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      {progressBar && <DialogProgressBar />}
      <DialogActions>
        <DialogButton color={color || 'primary'} {...restButtonProps}>
          {children || nls.confirm()}
        </DialogButton>
      </DialogActions>
    </DialogWrapper>
  );
}
