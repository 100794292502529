import {
  AddPhotoBoxMediaFileToAlbumParams,
  CreatePhotoBoxAlbumParams,
  CreatePhotoBoxAlbumResult,
  DeletePhotoBoxMediaFileFromAlbumParams,
  MediaListMeta,
  MediaListParams,
  PhotoBoxAlbumDetailListParams,
  PhotoBoxAlbumDetailListResult,
  PhotoBoxAlbumListResult,
  PhotoBoxExtraListResponseEntity,
  PhotoBoxMediaExistShootingDateParams,
  PhotoBoxMediaListResult,
  PhotoBoxMediaResponseEntity,
  UpdateMediaFileParams,
  UpdatePhotoBoxAlbumParams,
  UploadMediaFileParams,
  V4Response,
} from 'shared/query/photoBox/types';
import { apiAxios } from 'shared/common/api/common';
import { CancelToken } from 'axios';

export default {
  /* 미디어 파일 관리 */
  async getMediaList({
    lastMediaId,
    limit,
    projectId,
    type,
    zoneId,
    extra,
    memo,
    startShootingDate,
    endShootingDate,
    createdByUser,
    orderBy,
  }: MediaListParams) {
    const response = await apiAxios.get<V4Response<PhotoBoxMediaListResult, MediaListMeta>>(
      `/api/v4/photobox/media`,
      {
        params: {
          last_media_id: lastMediaId,
          limit,
          project_id: projectId,
          type,
          zone_id: zoneId,
          extra,
          memo,
          start_shooting_date: startShootingDate,
          end_shooting_date: endShootingDate,
          created_by_user: createdByUser,
          order_by: orderBy,
        },
      },
    );
    return response.data.result;
  },
  async uploadMediaFile({
    mediaList,
    cancelToken,
  }: UploadMediaFileParams & { cancelToken: CancelToken }) {
    const response = await apiAxios.post<V4Response<PhotoBoxMediaListResult, any>>(
      `/api/v4/photobox/media`,
      { media_list: mediaList },
      { cancelToken, timeout: 3600_000 },
    );
    return response.data.result;
  },
  async updateMediaFile({ mediaList }: UpdateMediaFileParams) {
    const response = await apiAxios.put<V4Response<PhotoBoxMediaListResult, any>>(
      `api/v4/photobox/media`,
      {
        media_list: mediaList,
      },
    );
    return response.data.result;
  },
  async deleteMediaFile(mediaId: string) {
    const response = await apiAxios.delete<V4Response<{ mediaIdList: number[] }, any>>(
      `api/v4/photobox/media`,
      {
        params: { media_id: mediaId },
      },
    );
    return response.data.result;
  },
  /* 앨범 관리 */
  async getAlbumList(projectId: number) {
    const response = await apiAxios.get<V4Response<PhotoBoxAlbumListResult, any>>(
      `/api/v4/photobox/album`,
      {
        params: { project_id: projectId },
      },
    );
    return response.data.result;
  },
  async createAlbum({
    projectId,
    latitude,
    longitude,
    coordinate,
    name,
  }: CreatePhotoBoxAlbumParams) {
    const response = await apiAxios.post<V4Response<CreatePhotoBoxAlbumResult, any>>(
      `/api/v4/photobox/album`,
      { project_id: projectId, latitude, longitude, coordinate, name },
    );
    return response.data.result;
  },
  async deleteAlbum(albumId: string) {
    const response = await apiAxios.delete<V4Response<{ albumIdList: number[] }, any>>(
      `/api/v4/photobox/album`,
      { params: { album_id: albumId } },
    );
    return response.data.result;
  },
  async updateAlbum({ albumId, latitude, longitude, coordinate, name }: UpdatePhotoBoxAlbumParams) {
    const response = await apiAxios.post<V4Response<CreatePhotoBoxAlbumResult, any>>(
      `/api/v4/photobox/album/${albumId}`,
      { latitude, longitude, coordinate, name },
    );
    return response.data.result;
  },
  async getAlbumDetailList({ albumId, lastMediaId, limit }: PhotoBoxAlbumDetailListParams) {
    const response = await apiAxios.get<V4Response<PhotoBoxAlbumDetailListResult, MediaListMeta>>(
      `/api/v4/photobox/album/${albumId}/media`,
      { params: { last_media_id: lastMediaId, limit } },
    );
    return response.data.result;
  },
  async addMediaFileToAlbum({ albumId, mediaIdList }: AddPhotoBoxMediaFileToAlbumParams) {
    const response = await apiAxios.post<V4Response<AddPhotoBoxMediaFileToAlbumParams, any>>(
      `/api/v4/photobox/album/${albumId}/media`,
      { media_id_list: mediaIdList },
    );
    return response.data.result;
  },
  async deleteMediaFileFromAlbum({ albumId, mediaIdList }: DeletePhotoBoxMediaFileFromAlbumParams) {
    const response = await apiAxios.delete<V4Response<DeletePhotoBoxMediaFileFromAlbumParams, any>>(
      `/api/v4/photobox/album/${albumId}/media`,
      { params: { media_id: mediaIdList.toString() } },
    );
    return response.data.result;
  },
  async getMediaExistShootingDate({
    projectId,
    zoneId,
    targetShootingDate,
  }: PhotoBoxMediaExistShootingDateParams) {
    const response = await apiAxios.get<V4Response<{ dateList: string[] }, any>>(
      `/api/v4/photobox/media/shooting-date/monthly`,
      {
        params: {
          project_id: projectId,
          zone_id: zoneId,
          target_shooting_date: targetShootingDate,
        },
      },
    );
    return response.data.result;
  },
  async getExtraList(projectId: number) {
    const response = await apiAxios.get<V4Response<PhotoBoxExtraListResponseEntity, any>>(
      `/api/v4/photobox/project/${projectId}/extra`,
    );
    return response.data.result;
  },
  async getPhotoBoxDetailMedia(mediaId: number) {
    if (!Boolean(mediaId)) return null;
    const response = await apiAxios.get<V4Response<{ media: PhotoBoxMediaResponseEntity }, any>>(
      `/api/v4/photobox/media/${mediaId}`,
    );
    return response.data.result.media;
  },
};
