import { ISSUE_POLYGON } from 'shared/common/policies/2d';
import L from 'leaflet';
import { Issue } from 'shared/query/issue/types';
import React, { useEffect, useMemo, useRef } from 'react';
import { Marker, Polygon, useMap } from 'react-leaflet';
import { getAnnotationNumberMarker } from '../annotation/common/utils';

const outerPolygon: [[number, number], [number, number], [number, number], [number, number]] = [
  [90, -180],
  [90, 180],
  [-90, 180],
  [-90, -180],
];

interface Props {
  issue: Issue;
  editable?: boolean;
  onDragEndPosition?: (id: number, position: L.LatLng, idx: number) => void;
  onClick?: () => void;
}

export default function IssuePolygon({ issue, editable, onDragEndPosition, onClick }: Props) {
  const map = useMap();
  const polygonRef = useRef<L.Polygon>(null);

  const [icons] = useMemo(() => {
    return [
      issue?.positions?.map((_, i) =>
        getAnnotationNumberMarker(ISSUE_POLYGON.COLOR[issue?.status], i + 1),
      ),
    ];
  }, [issue]);

  useEffect(() => {
    const positions = issue?.positions;
    if (positions?.length) {
      const bounds = L.latLngBounds(positions.map((x) => ({ lat: x.latitude, lng: x.longitude })));
      map.fitBounds(bounds);
    }
  }, [issue?.positions]);

  function onDragPosition(e, idx) {
    const latLngs = getLatLngs();
    if (!latLngs) return;

    polygonRef.current.setLatLngs([
      [outerPolygon, [...latLngs.slice(0, idx), e.target.getLatLng(), ...latLngs.slice(idx + 1)]],
    ]);
  }

  function getLatLngs(): L.LatLng[] | false {
    if (!polygonRef.current) return false;
    return polygonRef?.current?.getLatLngs()[0][1] as L.LatLng[];
  }

  function onDragEnd(idx: number) {
    const latLngs = getLatLngs();
    if (!latLngs || !issuePositions || issuePositions?.length === 0) return;

    onDragEndPosition?.(issue?.id, latLngs[idx], idx);
  }

  const issuePositions = issue?.positions?.map((x) => ({ lat: x.latitude, lng: x.longitude }));

  return (
    <>
      <Polygon
        ref={polygonRef}
        positions={[[outerPolygon, issuePositions]]}
        color={ISSUE_POLYGON.COLOR[issue?.status]}
        fillColor={ISSUE_POLYGON.DIMMED_BACKGROUND_COLOR}
        fillOpacity={ISSUE_POLYGON.DIMMED_OPACITY}
        eventHandlers={{
          click: onClick,
        }}
      />
      {editable &&
        issuePositions?.map((pos, i) => (
          <Marker
            key={i}
            icon={icons?.[i]}
            position={pos}
            draggable
            eventHandlers={{
              drag: (e) => onDragPosition(e, i),
              dragend: () => onDragEnd(i),
            }}
          />
        ))}
    </>
  );
}
