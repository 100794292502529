import { apiAxios } from 'shared/common/api/common';
import { ExportArchive, ExportArchiveType, ExportUrls, FilePathType } from './types';

export default {
  async read(snapshotId: number): Promise<ExportUrls> {
    const response = await apiAxios.get<ExportUrls>(`/api/v3/snapshots/${snapshotId}/export/urls`);
    return response?.data;
  },
  async checkExist(snapshotId: number, fileTypes: string[]): Promise<FilePathType> {
    const response = await apiAxios.get<FilePathType>(
      `/api/v3/snapshots/${snapshotId}/export/check-file`,
      {
        params: { file_types: `[${fileTypes.join(',')}]` },
      },
    );
    return response?.data;
  },
  archive: {
    async create(snapshotId: number, fileType: ExportArchiveType): Promise<ExportArchive> {
      const response = await apiAxios.post<ExportArchive>(
        `/api/v3/snapshots/${snapshotId}/export/archive`,
        { fileType },
      );
      return response?.data;
    },
    async read(snapshotId: number, resourceId: number): Promise<ExportArchive> {
      const response = await apiAxios.get<ExportArchive>(
        `/api/v3/snapshots/${snapshotId}/export/archive`,
        {
          params: { resource_id: resourceId },
        },
      );
      return response?.data;
    },
  },
};
