import { CoordinateSystem } from 'shared/common/types';
import { nls } from 'shared/locale/language';
import { MapProvider } from 'shared/query/project/types';
import { Z_INDEX } from './2d';

export const DEFAULT_MAP_PROVIDER = 'GOOGLE';

// 소숫점 최대 자리수 : DB 기준, 위경도 좌표계용
export const MAX_DECIMAL_PLACES = 16;

export const DEFAULT_ZOOM_LEVEL = 18;

export const MIN_ZOOM = 5;
export const MAX_ZOOM = 25;
export const UPDATE_WHEN_IDLE = true;
export const UPDATE_WHEN_ZOOMING = false;

export const DEFAULT_MAX_NATIVE_ZOOM = 18;
export const SATELLITE_VWORLD_MAX_NATIVE_ZOOM = 19;
export const CADASTRAL_MIN_NATIVE_ZOOM = 17;
export const CADASTRAL_MAX_NATIVE_ZOOM = 19;
export const CADASTRAL_MIN_ZOOM = 16;
export const DEFAULT_ZOOM_SNAP = 0.125;

// 이 줌레벨부터는 클러스터링을 해제함
export const DISABLE_CLUSTERING_AT_ZOOM = 19;

// 기본 center값 - 서울시청
export const DEFAULT_CENTER = { lat: 37.56669, lng: 126.97842 };

export const DEFAULT_API_EPSG_CODE = CoordinateSystem.latlng;

export const mapTypes: { value: MapProvider; label: string }[] = [
  { value: 'GOOGLE', label: nls.projectMapProviderGoogle() },
  { value: 'BING', label: nls.projectMapProviderBing() },
  { value: 'VWORLD', label: nls.projectMapProviderVworld() },
];

// FIXME: 이게 일본 대응 최선인지 잘 모르겠다...
export const mapTypesJp: { value: MapProvider; label: string }[] = [
  { value: 'GOOGLE', label: nls.projectMapProviderGoogle() },
  { value: 'BING', label: nls.projectMapProviderBing() },
];

export const VARI_RANGE = { min: -0.4, max: 0.4 };
export const THERMAL_RANGE = { min: 0.0, max: 1.0 };
export const NDMI_RANGE = { min: -0.4, max: 0.4 };

/* New Version */
export const DEFAULT_2D_MAP_STYLES = {
  width: '100%',
  height: '100%',
};

export const COMMON_MAP_OPTIONS = {
  zIndex: Z_INDEX.SATELLITE,
  updateWhenIdle: UPDATE_WHEN_IDLE,
  updateWhenZooming: UPDATE_WHEN_ZOOMING,
  maxZoom: MAX_ZOOM,
  maxNativeZoom: DEFAULT_MAX_NATIVE_ZOOM,
};

export const GOOGLE_MAP_OPTIONS = {
  ...COMMON_MAP_OPTIONS,
  apiKey: process.env.GOOGLE_API_KEY || '',
  type: 'satellite',
};

export const BING_MAP_OPTIONS = {
  ...COMMON_MAP_OPTIONS,
  attribution: false,
  bingkey: process.env.BING_API_KEY || '',
};
