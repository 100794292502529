import { nls } from 'shared/locale/language';
import React from 'react';
import {
  DialogActions,
  DialogButton,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogTitleText,
  DialogWrapper,
} from './common';
import { DialogProps } from './types';

export default function Confirm({
  title,
  content,
  primaryButtonProps,
  secondaryButtonProps,
  children,
  ...rest
}: Omit<DialogProps, 'type'>) {
  const {
    title: primaryButtonTitle,
    color: primaryButtonColor,
    ...restPrimaryButtonProps
  } = primaryButtonProps || {};
  const {
    title: secondaryButtonTitle,
    color: secondaryButtonColor,
    hidden: secondaryButtonHidden,
    ...restSecondaryButtonProps
  } = secondaryButtonProps || {};
  return (
    <DialogWrapper {...rest}>
      <DialogTitle>
        <DialogTitleText>{title}</DialogTitleText>
      </DialogTitle>
      <DialogContent>
        {content && <DialogContentText>{content}</DialogContentText>}
        {children}
      </DialogContent>
      <DialogActions>
        {!secondaryButtonHidden && (
          <DialogButton color={secondaryButtonColor || 'inherit'} {...restSecondaryButtonProps}>
            {secondaryButtonTitle || nls.cancel()}
          </DialogButton>
        )}
        <DialogButton color={primaryButtonColor || 'primary'} {...restPrimaryButtonProps}>
          {primaryButtonTitle || nls.confirm()}
        </DialogButton>
      </DialogActions>
    </DialogWrapper>
  );
}
