import { verifyEmail } from 'shared/common/utils';
import { PLACEHOLDER_VALUE } from 'features/common/select/Select';
import { nls } from 'shared/locale/language';
import { ProjectUser } from 'stores/data/types';

export function validateEmail(
  email: string,
  participants: {
    tentativeInvitees: ProjectUser[];
    invitees: ProjectUser[];
    projectUsers: ProjectUser[];
  },
): string {
  if (!email.trim()) {
    return nls.emailEmptyError();
  }
  if (!verifyEmail(email)) {
    return nls.emailFormatError();
  }
  if (participants.tentativeInvitees.find((x) => (x.email || x.user?.email) === email)) {
    return nls.emailAlreadyInvited();
  }
  if (participants.invitees.find((x) => (x.email || x.user?.email) === email)) {
    return nls.emailAlreadyInvited();
  }
  if (participants.projectUsers.find((x) => (x.email || x.user?.email) === email)) {
    return nls.emailAlreadyUser();
  }
  return '';
}

export function validateGrade(grade: string): string {
  if (!grade || grade === PLACEHOLDER_VALUE) {
    return nls.gradeEmptyError();
  }
  return '';
}
