import { ProjectWithOrganization } from 'features/genaral/project/types';
import { useQuery } from 'react-query';
import api from './api';
import { keys } from './queryKeys';

type Params = {
  ancestorFolderId?: number | false;
  organizationId?: number;
};

export default function useProjectWithOrganizationQuery({
  ancestorFolderId,
  organizationId,
}: Params = {}) {
  return useQuery<ProjectWithOrganization>(
    keys.projectWithOrganization(ancestorFolderId, organizationId),
    () => api.organization.list({ ancestorFolderId, organizationId }),
  );
}
