import React from 'react';
import styled from 'styled-components';
import NavigationBar from '../NavigationBar';
import NoticeSnackbarProvider from '../topbar/Notice/SnackbarProvider';
import WarningBrowserBar from '../topbar/WarningBrowserBar';

interface Props {
  fixed?: boolean;
  disabledNotice?: boolean;
  onProjectClick?: () => void;
}
function NavigationNoticeLayout({
  fixed = false,
  disabledNotice = false,
  onProjectClick = null,
}: Props) {
  return (
    <Wrapper>
      <NavigationBar fixed={fixed} onProjectClick={onProjectClick} />
      {!disabledNotice && <NoticeSnackbarProvider />}
      <WarningBrowserBar />
    </Wrapper>
  );
}

export default NavigationNoticeLayout;

const Wrapper = styled.div`
  display: flex;
`;
