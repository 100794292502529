import Snackbar, { SnackbarProps } from '@material-ui/core/Snackbar';
import TextButton from 'features/common/button/TextButton';
import { nls } from 'shared/locale/language';
import React from 'react';
import styled from 'styled-components';
import theme from 'shared/styles/mui/theme';

interface Props extends SnackbarProps {
  showAction: boolean;
  onCancel: () => void;
  onSave: () => void;
}

export default function EditControlSnackbar({
  message,
  open,
  showAction,
  onCancel,
  onSave,
}: Props) {
  const action = showAction && (
    <ActionContainer>
      <CancelButton onClick={onCancel}>{nls.cancel()}</CancelButton>
      <Divider />
      <SaveButton onClick={onSave}>{nls.done()}</SaveButton>
    </ActionContainer>
  );

  return <StyledSnackbar message={message} open={open} action={action} />;
}

const CancelButton = styled(TextButton)`
  color: ${theme.palette.grey[400]};
`;

const SaveButton = styled(TextButton)`
  color: ${theme.palette.primary.main};
`;

const StyledSnackbar = styled(Snackbar)`
  .MuiSnackbarContent-root {
    min-width: 0;
    background-color: ${theme.palette.grey[900]};
  }
  .MuiSnackbarContent-action {
    padding-left: 1.25rem;
  }
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Divider = styled.div`
  width: 1px;
  height: 1.125rem;
  background-color: ${theme.palette.grey[600]};
`;
