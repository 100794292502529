import {
  DEFAULT_QUERY_STALE_TIME,
  VEHICLE_LIST_QUERY_REFETCH_INTERVAL,
} from 'shared/common/policies/request';
import formatISO from 'date-fns/formatISO';
import { useQuery } from 'react-query';
import { VehicleBaseDate, VehicleSlider, VehicleTabValue } from 'stores/data/types';
import api from 'shared/query/vehicle/api';
import { keys } from './queryKeys';

type Props = {
  projectId: number;
  enabled: boolean;
  baseDate: VehicleBaseDate;
  slider: VehicleSlider;
};
export function useVehicleLocationListQuery({
  projectId,
  enabled,
}: Pick<Props, 'projectId' | 'enabled'>): void {
  useQuery(keys.list(projectId), () => api.list({ projectId }), {
    enabled,
    notifyOnChangePropsExclusions: ['isFetching', 'isRefetching'],
    refetchInterval: VEHICLE_LIST_QUERY_REFETCH_INTERVAL,
    refetchOnWindowFocus: false,
  });
}

export function useVehicleLocationHistoryListQuery({
  projectId,
  baseDate,
  slider,
  enabled,
}: Pick<Props, 'projectId' | 'baseDate' | 'slider' | 'enabled'>): void {
  const baseDateStr = formatISO(baseDate);
  const baseTime = new Date(baseDate);
  const hours = Math.floor(slider / 1000 / 60 / 60);

  baseTime.setHours(hours, 0, 0, 0);
  const currentTime = formatISO(baseTime);
  getLocationHistoryList(projectId, baseDateStr, currentTime, enabled);
}

function getLocationHistoryList(
  projectId: number,
  baseDate: string,
  baseTime: string,
  enabled: boolean,
) {
  return useQuery(
    keys.listHistoryByBaseDateAndTime(projectId, baseDate, baseTime),
    () => api.listHistory({ projectId, baseTime }),
    {
      enabled,
      refetchOnWindowFocus: false,
      staleTime: DEFAULT_QUERY_STALE_TIME,
    },
  );
}

export function useVehicleLocationTabQuery({ projectId }: { projectId: number }) {
  return useQuery<VehicleTabValue>(keys.tab(projectId), {
    initialData: 'VEHICLE_LOCATION_LIST',
    staleTime: Infinity,
  });
}

export function useVehicleHistoryQuery({
  projectId,
  page,
  search,
  startAt,
  endAt,
  vehicleType,
}: {
  projectId: number;
  page?: number;
  search: string;
  startAt?: string;
  endAt?: string;
  vehicleType?: string;
}) {
  return useQuery(keys.listHistoryV2(projectId, page, search, startAt, endAt, vehicleType), () =>
    api.listHistoryV2({ projectId, page, search, startAt, endAt, vehicleType }),
  );
}

export function useVehicleHistoryExistDate({
  projectId,
  startAt,
  endAt,
}: {
  projectId: number;
  startAt: string;
  endAt: string;
}) {
  return useQuery(keys.historyExistDate(projectId, startAt, endAt), () =>
    api.historyExistDate({ projectId, startAt, endAt }),
  );
}

export function useVehicleLocationExistDate({
  projectId,
  date,
}: {
  projectId: number;
  date: string;
}) {
  return useQuery(keys.locationExistDate(projectId, date), () =>
    api.locationExistDate({ projectId, date }),
  );
}

export function useVehicleDrivingRouteData({
  projectId,
  historyId,
}: {
  projectId: number;
  historyId: string;
}) {
  return useQuery(keys.historyDrivingRouteData(projectId, historyId), () =>
    api.historyDrivingRouteData({ projectId, historyId }),
  );
}
