import { layerIcons } from 'features/snapshot/detailsPage/sideMenu/common/layerUtils';
import { nls } from 'shared/locale/language';
import { BaseAnnotation, getLowerCaseAnnotationType } from 'shared/query/annotation/types';
import { ResourceType, getLowerCaseResourceType } from 'shared/query/resource/types';

export function getFileIcon(type: BaseAnnotation['annotationType'] | ResourceType) {
  if (isAnnotation(type)) {
    return layerIcons[getLowerCaseAnnotationType(type as BaseAnnotation['annotationType'])];
  }
  if (isResource(type)) {
    return layerIcons[getLowerCaseResourceType(type as ResourceType)];
  }
  if (isFileSettingResource(type)) {
    return layerIcons.etc;
  }
  return null;
}

export function isAnnotation(type: BaseAnnotation['annotationType'] | ResourceType) {
  return ['POINT', 'POLYLINE', 'POLYGON'].includes(type);
}

export function isResource(
  type: BaseAnnotation['annotationType'] | ResourceType,
): type is ResourceType {
  return [
    'PLAN_LEVEL',
    'BIM',
    'STRATUM',
    'DRAWING_RASTER',
    'DRAWING_VECTOR',
    'DRAWING_POSITIONING',
    'ANNOTATION_CSV',
    'POLYGON_VECTOR',
    'PHOTOBOX_IMAGE',
    'PHOTOBOX_VIDEO',
  ].includes(type);
}

export function isFileSettingResource(type: BaseAnnotation['annotationType'] | ResourceType) {
  return ['STORAGE_PILE', 'STORAGE_EARTHWORK'].includes(type);
}

export const FILE_ARCHIVE_TYPE_NAME = {
  POINT: nls.fileArchiveTypePoint(),
  POLYLINE: nls.fileArchiveTypePolyline(),
  POLYGON: nls.fileArchiveTypePolygon(),
  ANNOTATION_CSV: nls.fileArchiveTypeChain(),
  PLAN_LEVEL: nls.fileArchiveTypePlanLevel(),
  BIM: nls.fileArchiveTypeBim(),
  STRATUM: nls.fileArchiveTypeStratum(),
  DRAWING_RASTER: nls.fileArchiveTypeDrawingRaster(),
  DRAWING_VECTOR: nls.fileArchiveTypeDrawingVector(),
  DRAWING_POSITIONING: nls.fileArchiveTypeDrawingPositioning(),
  POLYGON_VECTOR: nls.fileArchiveTypePolygonVector(),
  STORAGE_PILE: nls.fileArchiveTypePile(),
  STORAGE_EARTHWORK: nls.fileArchiveTypeEarthwork(),
  PHOTOBOX_IMAGE: nls.fileArchivePhotoBoxFileImageType(),
  PHOTOBOX_VIDEO: nls.fileArchivePhotoBoxFileVideoType(),
};
