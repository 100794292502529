import { CancelToken } from 'axios';
import { apiAxios } from 'shared/common/api/common';
import { CreateGcpResourceType, Gcp, GcpResource } from './types';

interface Props {
  snapshotId: number;
  gcpId: number;
  locationId: number;
}
export default {
  async list({ snapshotId }: Pick<Props, 'snapshotId'>) {
    const response = await apiAxios.get<{ results: Gcp[] }>(`/api/v3/snapshots/${snapshotId}/gcps`);
    return response?.data?.results;
  },
  async createGcpResource({
    snapshotId,
    gcpId,
    data,
    cancelToken,
  }: Pick<Props, 'snapshotId' | 'gcpId'> & {
    data: CreateGcpResourceType;
    cancelToken: CancelToken;
  }) {
    const response = await apiAxios.post<CreateGcpResourceType>(
      `/api/v3/snapshots/${snapshotId}/gcps/${gcpId}/resources`,
      data,
      { cancelToken },
    );
    return response?.data;
  },
  async deleteGcpResource({
    snapshotId,
    gcpId,
    resourceId,
  }: Pick<Props, 'snapshotId' | 'gcpId'> & { resourceId: GcpResource['id'] }) {
    const response = await apiAxios.delete(
      `/api/v3/snapshots/${snapshotId}/gcps/${gcpId}/resources/${resourceId}`,
    );
    return response?.data;
  },
};
