import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import CheckCircle from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import { nls } from 'shared/locale/language';
import React from 'react';
import { LoadingStatus } from 'stores/data/types';
import styled, { css } from 'styled-components';
import theme from 'shared/styles/mui/theme';

export type RequestStatus = LoadingStatus | 'WARNING' | 'IN_PROGRESS' | 'DONE' | 'COMPLETED';
export type RequestMessage = Exclude<RequestStatus, ['PARTIAL', number, number] | 'NONE'>;

interface Props {
  status: RequestStatus;
  progress?: JSX.Element;
  message?: { [key in RequestMessage]?: string };
  vertical?: boolean;
  direction?: 'flex-end' | 'flex-start';
  size?: 'small' | 'default';
  textAlign?: 'center' | 'left';
}

/**
 *  figma의 Text Feedback 을 공통 컴포넌트로 구현함.
 *  @link https://www.figma.com/file/PP9uWWFob8FJNr7H21Hkbx/Platform-3.0-Redesign-Web?node-id=4365%3A2
 * */
export default function RequestStatusMessage(props: Props) {
  const {
    status,
    message,
    vertical = false,
    direction = 'flex-end',
    size = 'default',
    textAlign = 'left',
    progress = <StyledCircularProgress size={size === 'small' ? '1.25rem' : '1.5rem'} />,
  } = props;
  switch (status) {
    case 'LOADING':
    case 'IN_PROGRESS':
      return (
        <StatusMessage>
          <Wrapper $direction={direction} $vertical={vertical}>
            {progress}
            <Message color="textSecondary" $size={size} $textAlign={textAlign}>
              {message?.LOADING ?? message?.IN_PROGRESS ?? nls.textFeedbackLoadingMessage()}
            </Message>
          </Wrapper>
        </StatusMessage>
      );
    case 'ERROR':
      return (
        <StatusMessage>
          <Wrapper $direction={direction} $vertical={vertical}>
            <StyledErrorIcon color="error" $size={size} />
            <Message color="error" $size={size} $textAlign={textAlign}>
              {message?.ERROR ?? nls.textFeedbackErrorMessage()}
            </Message>
          </Wrapper>
        </StatusMessage>
      );
    case 'WARNING':
      return (
        <StatusMessage>
          <Wrapper $direction={direction} $vertical={vertical}>
            <StyledWarningIcon $size={size} />
            <Message className="warning" $size={size} $textAlign={textAlign}>
              {message?.WARNING ?? nls.textFeedbackWarningMessage()}
            </Message>
          </Wrapper>
        </StatusMessage>
      );
    case 'SUCCESS':
    case 'DONE':
      return (
        <StatusMessage>
          <Wrapper $direction={direction} $vertical={vertical}>
            <ColoredCheckCircle $size={size} />
            <Message color="textSecondary" $size={size} $textAlign={textAlign}>
              {message?.SUCCESS ?? message?.DONE ?? nls.textFeedbackSuccessMessage()}
            </Message>
          </Wrapper>
        </StatusMessage>
      );
    default:
      return null;
  }
}
const StatusMessage = styled(Typography).attrs({ variant: 'body2', component: 'div' })`
  display: flex;
  align-items: center;
  flex: 1;
`;

const getIconSize = ({ $size }) => {
  if ($size === 'small') {
    return css`
      width: 1.25rem;
      height: 1.25rem;
    `;
  }
  return css`
    width: 1.5rem;
    height: 1.5rem;
  `;
};
const StyledCircularProgress = styled(CircularProgress).attrs({ color: 'primary' })`
  margin-right: 0.5rem;
`;
const ColoredCheckCircle = styled(CheckCircle)<{ $size: 'small' | 'default' }>`
  color: ${theme.palette.success.main};
  margin-right: 0.5rem;
  ${getIconSize}
`;
const StyledErrorIcon = styled(ErrorIcon)<{ $size: 'small' | 'default' }>`
  margin-right: 0.5rem;
  ${getIconSize}
`;
const StyledWarningIcon = styled(WarningIcon)<{ $size: 'small' | 'default' }>`
  margin-right: 0.5rem;
  color: ${theme.palette.warning.main};
  ${getIconSize}
`;

const Wrapper = styled.div<{
  $direction: 'flex-end' | 'flex-start';
  $vertical: boolean;
}>`
  display: flex;
  flex-direction: ${({ $vertical }) => ($vertical ? 'column' : 'row')};
  flex: 1;
  align-items: center;
  justify-content: ${({ $direction }) => $direction};
  gap: ${({ $vertical }) => ($vertical ? '1rem' : '0')};

  ${({ $direction }) =>
    $direction === 'flex-end' &&
    css`
      margin-right: 1rem;
    `}
`;

const Message = styled(Typography)<{
  $size: 'small' | 'default';
  $textAlign: 'center' | 'left';
}>`
  text-align: ${({ $textAlign }) => $textAlign};
  font-size: ${({ $size }) =>
    $size === 'small' ? theme.typography.caption.fontSize : theme.typography.body2.fontSize};
  line-height: ${({ $size }) =>
    $size === 'small' ? theme.typography.caption.lineHeight : theme.typography.body2.lineHeight};
  white-space: pre-line;
  word-break: keep-all;
  &.warning {
    color: ${theme.palette.warning.main};
  }
`;
