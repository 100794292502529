import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Cancel from '@material-ui/icons/Cancel';
import Search from '@material-ui/icons/Search';
import { IconButton } from 'features/common/button/IconButton';
import { others } from 'shared/styles/colors/others';
import theme from 'shared/styles/mui/theme';
import { nls } from 'shared/locale/language';
import React, { useRef } from 'react';
import styled from 'styled-components';

interface Props {
  value: string;
  setValue: (value: string) => void;
  setTrimSearchWord: () => void;
  clearSearch: () => void;
}

export default function FileArchiveSearchBar({
  value,
  setValue,
  setTrimSearchWord,
  clearSearch,
}: Props) {
  const disableBlur = useRef<boolean>(false);

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value);
  }
  function onKeyPress(e) {
    if (e.key === 'Enter') {
      setTrimSearchWord();
    }
  }
  function onBlur() {
    if (disableBlur.current) return;
    setTrimSearchWord();
  }
  function clearValue() {
    clearSearch();
    onMouseLeaveClearButton();
  }
  function onMouseEnterClearButton() {
    disableBlur.current = true;
  }
  function onMouseLeaveClearButton() {
    disableBlur.current = false;
  }

  return (
    <SearchField
      margin="dense"
      variant="outlined"
      value={value}
      onChange={onChange}
      onKeyPress={onKeyPress}
      onBlur={onBlur}
      placeholder={nls.fileArchiveSearchPlaceholder()}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment: value.length > 0 && (
          <InputAdornment position="end">
            <IconButton
              edge="end"
              size="small"
              onClick={clearValue}
              onMouseEnter={onMouseEnterClearButton}
              onMouseLeave={onMouseLeaveClearButton}
            >
              <Cancel />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

const SearchField = styled(TextField)`
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: ${theme.palette.background.paper};

  .MuiSvgIcon-root {
    color: ${theme.palette.text.secondary};
  }

  .MuiInputBase-input {
    font-size: 1rem;
    font-weight: 400;
    line-height: 175%;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${others.BORDER};
  }
`;
