import { ErrorMessageCode } from 'shared/common/types';
import useGlobalDialog from 'features/common/hooks/useGlobalDialog';
import { nls } from 'shared/locale/language';
import { CancelledError, useQueryClient } from 'react-query';

/** react-query를 사용한 API 통신을 할 때, error 발생 시, Alert을 띄우는 기본 옵션으로 설정
 *
 *  해당 옵션 없이 따로 error handling을 하고 싶은 경우,
 *  useQuery, useMutation를 사용하는 시점에 onError 옵션을 사용하면 기본으로 설정된 옵션이 무시 됨.
 */

export default function useReactQueryDefaultOptions() {
  const queryClient = useQueryClient();
  const { showAlert } = useGlobalDialog();

  queryClient.setDefaultOptions({
    queries: {
      onError,
      useErrorBoundary: () => false,
      retry: 0,
      refetchOnWindowFocus: false,
    },
    mutations: { onError },
  });

  function onError(e) {
    const { data } = e.response ?? {};
    const errorCode = data?.errorCode || data?.error_code;
    if (e instanceof CancelledError && e.silent) {
      // Cancelled Error : debounce 등의 이유로 요청이 취소된 경우
    } else {
      if (Boolean(errorCode)) {
        showAlert({
          content: getErrorMessage(errorCode),
        });
      }
    }
  }

  function getErrorMessage(errorCode: string) {
    return nls(errorCode) || nls(ErrorMessageCode.SERVER_FAIL_CODE);
  }
}
