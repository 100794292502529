import { DEFAULT_QUERY_STALE_TIME } from 'shared/common/policies/request';
import { UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query';
import api from './api';
import { keys } from './queryKeys';
import { HazardArea, HazardEventHistory, HazardEventHistoryParams } from './types';

export function useHazardAreaListQuery(projectId: number, options?: UseQueryOptions<HazardArea[]>) {
  const { enabled = true, ...restOptions } = options ?? {};
  return useQuery(keys.list(projectId), () => api.list({ projectId }), {
    enabled: Boolean(projectId && enabled),
    staleTime: DEFAULT_QUERY_STALE_TIME,
    ...restOptions,
  });
}

export function useHazardAreaQuery(projectId: number, hazardAreaId: number) {
  return useQuery(
    keys.detail(projectId, hazardAreaId),
    () => api.read({ projectId, hazardAreaId }),
    {
      enabled: !!projectId && !!+hazardAreaId,
      staleTime: DEFAULT_QUERY_STALE_TIME,
    },
  );
}

export function useHazardEventHistoryListQuery(projectId: number, data: HazardEventHistoryParams) {
  return useQuery<HazardEventHistory[]>(
    keys.historyList(projectId, data),
    () =>
      api.eventHistory({
        projectId,
        data: { ...data, annotationId: data.annotationId === -1 ? undefined : data.annotationId },
      }),
    {
      enabled: !!projectId,
      staleTime: DEFAULT_QUERY_STALE_TIME,
    },
  );
}

export function useHazardAreaMutation() {
  const queryClient = useQueryClient();
  const createHazardArea = useMutation(
    ({ zoneId, data }: Parameters<typeof api.create>[0]) => api.create({ zoneId, data }),
    { onSuccess: () => queryClient.invalidateQueries(keys.all()) },
  );
  const updateHazardArea = useMutation(
    ({ zoneId, hazardAreaId, data }: Parameters<typeof api.update>[0]) =>
      api.update({ zoneId, hazardAreaId, data }),
    { onSuccess: () => queryClient.invalidateQueries(keys.all()) },
  );
  const updateHazardAreaPositions = useMutation(
    ({ zoneId, hazardAreaId, positionId, data }: Parameters<typeof api.updatePositions>[0]) =>
      api.updatePositions({ zoneId, hazardAreaId, positionId, data }),
    { onSuccess: () => queryClient.invalidateQueries(keys.all()) },
  );
  const deleteHazardArea = useMutation(
    ({ zoneId, hazardAreaId }: Parameters<typeof api.delete>[0] & { projectId: number }) =>
      api.delete({ zoneId, hazardAreaId }),
    {
      onSuccess: (_, v) => {
        queryClient.invalidateQueries(keys.list(v.projectId));
        queryClient.invalidateQueries(keys.allHistoryList());
      },
    },
  );
  return {
    createHazardArea,
    updateHazardArea,
    updateHazardAreaPositions,
    deleteHazardArea,
  };
}
