import React, { lazy } from 'react';
const CompareSnapshotsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "compareSnapshotsPage" */ 'features/snapshot/comparePage/CompareSnapshotsPage'
    ),
);

function Slider(props) {
  return <CompareSnapshotsPage type="slider" {...props} />;
}

export default Slider;
