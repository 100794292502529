import React, { lazy } from 'react';
const UserEditPageContainer = lazy(
  () => import(/* webpackChunkName: "userEdit" */ 'features/user/userEditPage/UserEdit'),
);

function UserEdit() {
  return <UserEditPageContainer />;
}

export default UserEdit;
