import React, { lazy } from 'react';
const ProjectLogManipulatePage = lazy(
  () =>
    import(
      /* webpackChunkName: "ProjectLogManipulatePage" */
      'features/green/maintenanceLogPage/ProjectLogManipulatePage'
    ),
);

function ProjectLogManipulate() {
  return <ProjectLogManipulatePage />;
}

export default ProjectLogManipulate;
