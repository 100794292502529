import React, { lazy } from 'react';
const GcpEditorPage = lazy(
  () =>
    import(
      /* webpackChunkName: "gcpEditorPage" */ 'features/snapshot/gcpEditorPage/GcpEditorPage'
    ),
);

function GcpEditor() {
  return <GcpEditorPage />;
}

export default GcpEditor;
