import React, { lazy } from 'react';
const CompareByDatePage = lazy(
  () =>
    import(
      /* webpackChunkName: "compareByDatePage" */ 'features/snapshot/comparePage/green/CompareByDatePage'
    ),
);

function CompareGreenByDate() {
  return <CompareByDatePage />;
}

export default CompareGreenByDate;
