import React, { lazy } from 'react';
const SnapshotFormPage = lazy(
  () =>
    import(
      /* webpackChunkName: "SnapshotFormPage" */ 'features/snapshot/formPage/SnapshotFormPage'
    ),
);

function New() {
  return <SnapshotFormPage />;
}

export default New;
