const MAIN = '#00ACC1';
const DARK = '#00838F';
const LIGHT = '#26C6DA';
const LIGHT_BG = '#E5F7F9';
const CONTRAST_TEXT = '#FFFFFF';

export const info = {
  MAIN,
  DARK,
  LIGHT,
  LIGHT_BG,
  CONTRAST_TEXT,
};
