import ProjectListSort from 'features/project/listPage/ProjectListSort';
import { SortOrder } from 'features/project/listPage/SortOrder';
import SearchField from 'features/styleComponents/input/SearchField';
import React from 'react';
import { nls } from 'shared/locale/language';
import styled from 'styled-components';

interface Props {
  showOnlyProject: boolean;
  searchKeyword?: string;
  setSearchKeyword: React.Dispatch<React.SetStateAction<string>>;
  sortOrder: SortOrder;
  setSortOrder: (sortOrder: SortOrder) => void;
}

export default function ProjectListSearchAndSortArea(props: Props) {
  const { searchKeyword, setSearchKeyword, sortOrder, setSortOrder, showOnlyProject } = props;

  return (
    <Container>
      <SearchField
        variant="outlined"
        value={searchKeyword || ''}
        onChange={(e) => setSearchKeyword(e.target.value)}
        placeholder={nls.projectListSearchPlaceholder()}
      />
      {showOnlyProject && <ProjectListSort sortOrder={sortOrder} setSortOrder={setSortOrder} />}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  padding: 4rem 0 1.5rem 0;
  > :first-child {
    max-width: 33.5rem;
  }
`;
