import React, { lazy } from 'react';
const DrawingConfigPage = lazy(
  () =>
    import(
      /* webpackChunkName: "drawingConfigPage" */ 'features/snapshot/drawingConfigPage/DrawingConfigPage'
    ),
);

function Index() {
  return <DrawingConfigPage />;
}

export default Index;
