import { useMatchParams } from 'features/common/hooks';
import useGlobalDialog from 'features/common/hooks/useGlobalDialog';
import { nls } from 'shared/locale/language';
import { Permission } from 'shared/query/permission/types';
import React from 'react';
import styled from 'styled-components';
import BasicPagination from './BasicPagination';
import FileArchiveSearchBar from './FileArchiveSearchBar';
import FileArchiveTable from './FileArchiveTable';
import useTableData from './useTableData';

interface Props {
  permission: Permission;
}

export function FileArchiveTableContainer({ permission }: Props) {
  const { showConfirm } = useGlobalDialog();
  const { projectId } = useMatchParams();
  const {
    page,
    setPage,
    rows,
    maxPage,
    value,
    setValue,
    setTrimSearchWord,
    clearSearch,
    search,
    isLoading,
  } = useTableData({
    projectId,
    showAnnotationDeleteConfirm,
    showResourceDeleteConfirm,
    permission,
  });
  const canDownloadFile = Boolean(permission?.downloadProjectFiles);
  const canDeleteFile = Boolean(permission?.snapshotfileDelete);
  const canDeleteAnnotation = Boolean(permission?.annotationDelete);

  function onChangePage(_, p: number) {
    setPage(p);
  }

  function showAnnotationDeleteConfirm(deleteFn: () => void, annotationName: string) {
    showConfirm({
      title: nls.fileStorageDeleteAnnotationTitle(),
      content: nls.fileStorageDeleteAnnotationMessage(annotationName),
      primaryButtonProps: {
        title: nls.fileStorageDeleteAnnotationConfirmButton(),
        color: 'secondary',
        onClick: deleteFn,
      },
    });
  }

  function showResourceDeleteConfirm(deleteFn: () => void) {
    showConfirm({
      title: nls.fileStorageDeleteResourceTitle(),
      content: nls.fileStorageDeleteFileAlertMessage(),
      primaryButtonProps: {
        title: nls.fileStorageDeleteResourceConfirmButton(),
        color: 'secondary',
        onClick: deleteFn,
      },
    });
  }

  return (
    <Wrapper>
      <FileArchiveSearchBar
        value={value}
        setValue={setValue}
        setTrimSearchWord={setTrimSearchWord}
        clearSearch={clearSearch}
      />
      <FileArchiveTable
        rows={rows}
        canDownloadFile={canDownloadFile}
        canDeleteFile={canDeleteFile}
        canDeleteAnnotation={canDeleteAnnotation}
        isLoading={isLoading}
        isDirty={Boolean(search)}
      />
      <BasicPagination page={page} count={maxPage} onChange={onChangePage} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
`;
