import React, { lazy } from 'react';
const ZoneMapPage = lazy(
  () => import(/* webpackChunkName: "zoneMap" */ 'features/zone/mapPage/ZoneMapPage'),
);

function ZoneMap() {
  return <ZoneMapPage />;
}

export default ZoneMap;
