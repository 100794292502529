import { AxiosError } from 'axios';
import { DEFAULT_QUERY_STALE_TIME } from 'shared/common/policies/request';
import { Error } from 'shared/common/types';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { Snapshot } from 'stores/data/types';
import api from './api';
import { keys } from './queryKeys';

export function useSnapshotListQuery(
  { zoneId }: Parameters<typeof api.list>[0],
  options?: UseQueryOptions<Snapshot[], AxiosError<Error>>,
) {
  const { enabled = true, ...rest } = options || {};
  return useQuery(keys.list(zoneId), () => api.list({ zoneId }), {
    enabled: Boolean(zoneId && enabled),
    staleTime: DEFAULT_QUERY_STALE_TIME,
    ...rest,
  });
}

export function useSnapshotQuery(
  { zoneId, snapshotId, token }: Parameters<typeof api.read>[0],
  options?: UseQueryOptions<Snapshot, AxiosError<Error>>,
) {
  const { enabled = true, ...rest } = options || {};
  return useQuery(keys.detail(zoneId, snapshotId), () => api.read({ zoneId, snapshotId, token }), {
    enabled: Boolean(zoneId && snapshotId && enabled),
    staleTime: DEFAULT_QUERY_STALE_TIME,
    ...rest,
  });
}
export function useDsmSnapshotListQuery(zoneId: number) {
  return useQuery(
    keys.dsmSnapshot.list(zoneId),
    async () => {
      const list = await api.list({ zoneId, onlyDsm: true });
      return list.sort(orderByTakeDate);
    },
    {
      enabled: !!zoneId,
      staleTime: DEFAULT_QUERY_STALE_TIME,
    },
  );
}

export function useSnapshotMutation({ zoneId }: { zoneId: number }) {
  const queryClient = useQueryClient();

  const createSnapshot = useMutation(
    ({
      data,
      onSuccess,
    }: Omit<Parameters<typeof api.create>[0], 'zoneId'> & {
      onSuccess?: (res: Snapshot) => void;
    }) => api.create({ zoneId, data }),
    {
      onSuccess: (data: Snapshot, variables) => {
        queryClient.invalidateQueries(keys.list(zoneId));
        variables?.onSuccess?.(data);
      },
    },
  );

  const updateSnapshot = useMutation(
    ({
      snapshotId,
      data,
    }: Omit<Parameters<typeof api.update>[0], 'zoneId'> & {
      onSuccess?: (res: Snapshot) => void;
      onError?: (error: AxiosError<Error>) => void;
    }) => api.update({ zoneId, snapshotId, data }),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(keys.detail(zoneId, variables?.snapshotId));
        queryClient.invalidateQueries(keys.list(zoneId));
        variables?.onSuccess?.(data); // 컴포넌트에서 mutate 사용할 경우 옵션인 두번째 인자로 값 넘길 경우, 정상동작하지 않아서 인자로 넘겨서 실행시키도록 수정
      },
    },
  );

  const deleteSnapshot = useMutation(
    ({
      snapshotId,
      onSuccess,
    }: Omit<Parameters<typeof api.delete>[0], 'zoneId'> & { onSuccess?: () => void }) =>
      api.delete({ zoneId, snapshotId }),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(keys.list(zoneId));
        variables?.onSuccess?.();
      },
    },
  );
  return { createSnapshot, updateSnapshot, deleteSnapshot };
}

const orderByTakeDate = (a: Snapshot, b: Snapshot) => {
  if (a.takeDate === b.takeDate) {
    return b.id - a.id;
  }
  return -a.takeDate.localeCompare(b.takeDate);
};
