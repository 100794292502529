import { useURLQueryParams } from 'features/common/hooks';
import Select from 'features/common/select/Select';
import GA from 'shared/ga';
import { TWO_D_CURRENT_CCTV_ANOTHER_CCTV } from 'shared/ga/actions/twoDCurrent';
import { TWO_D_CURRENT } from 'shared/ga/category';
import { nls } from 'shared/locale/language';
import { Cctv, getCctvUrl } from 'shared/query/cctv/types';
import React, { useState } from 'react';
import styled from 'styled-components';

interface Props {
  cctvId: number | undefined;
  cctvList: Cctv[];
  onFocusedCctvChange: (cctvId: number) => void;
}
export default function CctvPlayer({ cctvId: initialId, cctvList, onFocusedCctvChange }: Props) {
  const [cctvId, setCctvId] = useState(initialId);
  const options = (cctvList || [])?.map((x) => ({ value: x?.id, name: x?.name }));
  const queryParams = useURLQueryParams();
  const partCount = queryParams.get('part');

  const handleChange = (e: React.ChangeEvent<{ name: string; value: string }>) => {
    const targetCctvId = +e.target?.value;
    setCctvId(targetCctvId);
    onFocusedCctvChange?.(targetCctvId);
    GA.event(TWO_D_CURRENT, TWO_D_CURRENT_CCTV_ANOTHER_CCTV, partCount);
  };

  const selectedCctv = cctvId && cctvList?.find((x) => x?.id === cctvId);
  return (
    <>
      <SelectWrapper>
        <Select
          className="dark-style"
          value={cctvId ?? initialId}
          onChange={handleChange}
          options={options}
          placeholder={nls.cctvPlayerSelectPlaceholder()}
        />
      </SelectWrapper>
      {selectedCctv && (
        <StyledIframe
          src={getCctvUrl(selectedCctv?.clientStreamUrl)}
          title={selectedCctv?.name}
          width="100%"
          height="100%"
          scrolling="no"
        />
      )}
    </>
  );
}

const SelectWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  max-width: 100%;

  .MuiInputBase-root {
    min-width: 10rem;
  }
`;

const StyledIframe = styled.iframe`
  display: flex;
  flex: 1;
  border: none;
`;
