import React, { lazy } from 'react';
const WalkthroughComparePage = lazy(
  () =>
    import(
      /* webpackChunkName: "WalkthroughComparePage" */ 'features/building/WalkthroughComparePage'
    ),
);

function WalkthroughCompare() {
  return <WalkthroughComparePage />;
}

export default WalkthroughCompare;
