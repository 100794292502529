import React, { lazy } from 'react';
const PhotoBoxAlbumPage = lazy(
  () =>
    import(
      /* webpackChunkName: "PhotoBoxAlbumPage" */
      'features/photoBox/photoBoxPage/PhotoBoxAlbumPage'
    ),
);

function PhotoBoxAlbumDetail() {
  return <PhotoBoxAlbumPage />;
}

export default PhotoBoxAlbumDetail;
