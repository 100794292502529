import { PermissionType } from 'shared/common/types';
import { useMutation } from 'react-query';
import api from './api';

export function useProjectUserMutation(projectId: number, projectUserId: number) {
  const update = useMutation((payload: { permission: PermissionType }) =>
    api.update({ projectId, projectUserId, data: payload }),
  );
  return { update };
}
