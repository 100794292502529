import axios, { CancelToken } from 'axios';
import { useMatchParams } from 'features/common/hooks';
import { requestUploadFile, s3DirectFileUpload } from 'shared/query/fileUpload/s3Upload';
import { S3FileUploadType } from 'shared/query/fileUpload/types';
import { fileSettingKeys } from 'shared/query/queryKeys';
import { useMutation, useQueryClient } from 'react-query';
import api from './api';
import { TempFile } from './types';

export default function useResourceV4Mutation() {
  const { projectId, zoneId } = useMatchParams();
  const queryClient = useQueryClient();

  const createResourceMutation = useMutation(
    (params: { filePath: string; fileName: string; fileSize: number; parentFolderId: number }) =>
      api.v4.create({ projectId, zoneId, ...params }),
  );

  const createMutation = useMutation(
    async ({
      tempFile,
      parentFolderId,
      cancelToken,
    }: {
      tempFile: TempFile;
      parentFolderId: number;
      cancelToken: CancelToken;
      options?: {
        onSuccess?: (t: TempFile) => void;
        onError?: (t: TempFile, errorCode?: string) => void;
        onCancel?: (t: TempFile) => void;
      };
    }) => {
      const { data: dataForUploads } = await requestUploadFile(tempFile);

      const { fields } = dataForUploads as S3FileUploadType;
      await s3DirectFileUpload(dataForUploads, tempFile?.file, cancelToken);

      const response = await createResourceMutation.mutateAsync({
        filePath: fields.key,
        fileName: tempFile.name,
        fileSize: tempFile.file.size,
        parentFolderId,
      });
      return response;
    },
    {
      onSuccess: (data, variables) => {
        variables?.options?.onSuccess({ ...data, ...variables.tempFile });
        queryClient.invalidateQueries(fileSettingKeys.all());
      },
      onError: (error, variables) => {
        if (axios.isCancel(error)) {
          variables?.options?.onCancel?.(variables.tempFile);
        }
      },
    },
  );

  const updateMutation = useMutation(
    (params: { resourceId: number; name?: string; description?: string }) => api.v4.update(params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(fileSettingKeys.all());
      },
    },
  );
  const deleteMutation = useMutation((params: { resourceId: number }) => api.v4.delete(params), {
    onSuccess: () => {
      queryClient.invalidateQueries(fileSettingKeys.all());
    },
  });

  return {
    createMutation,
    updateMutation,
    deleteMutation,
  };
}
