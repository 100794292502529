import Typography from '@material-ui/core/Typography';
import Info from '@material-ui/icons/Info';
import { memberPermission } from 'shared/common/types';
import { nls } from 'shared/locale/language';
import React from 'react';
import styled from 'styled-components';
import theme from 'shared/styles/mui/theme';

interface Props {
  grades: typeof memberPermission.OWNER[];
}

export default function GradeList({ grades }: Props) {
  return (
    <GradeListLayout>
      <GradeListHeader>
        <StyledInfo />
        <span>{nls.participantGradeTooltipHeader()}</span>
      </GradeListHeader>
      {grades.map((x) => (
        <StyledGradeListItem grade={x} key={x.value} />
      ))}
    </GradeListLayout>
  );
}

function GradeListItem({
  grade,
  className,
}: {
  grade: typeof memberPermission.OWNER;
  className?: string;
}) {
  return (
    <div className={className}>
      <Grade>{grade.title}</Grade>
      <Description>{grade.desc}</Description>
    </div>
  );
}

const GradeListLayout = styled.div`
  background-color: ${theme.palette.action.hover};
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
`;

const GradeListHeader = styled(Typography).attrs({
  variant: 'caption',
  component: 'div',
})`
  color: ${theme.palette.info.main};
  padding: 1rem;
  display: flex;
  align-items: center;
`;

const StyledInfo = styled(Info)`
  width: 0.75em;
  height: 0.75em;
  margin-right: 0.6rem;
`;

const StyledGradeListItem = styled(GradeListItem)`
  display: flex;
  align-items: center;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  margin-bottom: 0.75rem;

  &:last-child {
    margin-bottom: 1.375rem;
  }
`;

const Grade = styled(Typography).attrs({ variant: 'subtitle2', component: 'div' })`
  color: ${theme.palette.text.secondary};
  width: 5rem;
  min-width: 5rem;
`;

const Description = styled(Typography).attrs({ variant: 'body2', component: 'div' })`
  color: ${theme.palette.text.secondary};
`;
