import { Divider, Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import theme from 'shared/styles/mui/theme';
import CoordinateInput from './CoordinateInput';

interface Props {
  title: string;
  measure?: string;
  coordinates: { x: number; y: number; id }[];
  handleCoordinates: (index: number, key: 'x' | 'y', value: number) => void;
  decimalPlaces?: number;
}

/** @todo: EditorPageLayout와 독립적인 컴포넌트로 확장
    확장 시 필요 작업: 현재 배경이 검은색으로 고정되어 있어서, 화이트 테마 추가하여 선택 옵션 제공
*/
export default function CoordinateEditor({
  title,
  measure,
  coordinates,
  handleCoordinates,
  decimalPlaces,
}: Props) {
  return (
    <Container>
      <Title>
        <Typography variant="subtitle2" color="inherit">
          {title}
        </Typography>
        <AreaTypography>{measure}</AreaTypography>
      </Title>
      <StyledDivider />
      <ul>
        {coordinates?.map((coordinate, index) => (
          <CoordinateInput
            key={coordinate.id}
            index={index + 1}
            value={coordinate}
            handleValue={(key, value) => handleCoordinates(index, key, value)}
            decimalPlaces={decimalPlaces}
          />
        ))}
      </ul>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  ul {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AreaTypography = styled(Typography)`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 160%; /* 1.2rem */
  color: ${theme.palette.grey[500]};
`;

const StyledDivider = styled(Divider)`
  background-color: ${theme.palette.grey[800]};
`;
