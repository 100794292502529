import { useMatchParams } from 'features/common/hooks';
import PUHeader from 'features/common/pu/Header';
import { nls } from 'shared/locale/language';
import { usePermissionQuery, useProjectQuery } from 'shared/query';
import React from 'react';
import styled from 'styled-components';
import ProjectPageLayout from '../ProjectPageLayout';
import { FileArchiveTableContainer } from './table/FileArchiveTableContainer';

export default function ProjectFileArchivePage() {
  const { projectId } = useMatchParams();
  const { data: permission } = usePermissionQuery(projectId);
  const project = useProjectQuery(projectId)?.data ?? {};

  return (
    <ProjectPageLayout project={project}>
      <Wrapper>
        <PUHeader title={nls.fileStorage()} divider={false} />
        <FileArchiveTableContainer permission={permission} />
      </Wrapper>
    </ProjectPageLayout>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  width: 100%;
`;
