import { Collapse, TextField, Typography } from '@material-ui/core';
import Confirm from 'features/styleComponents/dialog/Confirm';
import React, { useEffect, useState } from 'react';
import { sendProjectFolderAddBtn } from 'shared/ga/actions';
import { nls } from 'shared/locale/language';
import styled from 'styled-components';
import { PROJECT_FOLDER_NAME_LIMIT_LENGTH } from '../projectFolder.const';

interface Props {
  open: boolean;
  mode?: 'create' | 'edit';
  initialName?: string;
  duplicateNameError?: boolean;
  onClose: () => void;
  onConfirm: (name: string) => void;
}

export default function EditingProjectFolderModal({
  open,
  onClose,
  mode = 'create',
  initialName = '',
  onConfirm,
  duplicateNameError = false,
}: Props) {
  const [name, setName] = useState(initialName || '');

  useEffect(() => {
    setName(initialName);
  }, [initialName]);

  function handleClose() {
    setName('');
    onClose();
  }

  const primaryButtonProps = {
    title: mode === 'create' ? nls.add() : nls.done(),
    onClick: () => {
      onConfirm(name);
      if (mode === 'create') {
        sendProjectFolderAddBtn();
      }
    },
    disabled: !name,
  };
  const secondaryButtonProps = { onClick: handleClose };

  const title =
    mode === 'create' ? nls.projectCreateFolderModalTitle() : nls.projectEditFolderModalTitle();
  return (
    <Confirm
      open={open}
      title={title}
      primaryButtonProps={primaryButtonProps}
      secondaryButtonProps={secondaryButtonProps}
    >
      <Content>
        <Typography variant="body1" color="textSecondary">
          {nls.folderNameEnter()}
        </Typography>
        <Section>
          <TextField
            variant="outlined"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            inputProps={{ maxLength: PROJECT_FOLDER_NAME_LIMIT_LENGTH }}
            error={duplicateNameError}
            placeholder={nls.folderNameEnter()}
          />
          <Collapse in={duplicateNameError}>
            <HelperText>{nls.folderNameDuplicate()}</HelperText>
          </Collapse>
        </Section>
      </Content>
    </Confirm>
  );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 0.75rem 2rem;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
`;

const HelperText = styled(Typography).attrs({ color: 'error' })`
  margin: 0.1875rem 0.875rem 0 0.875rem;
  font-size: 0.75rem;
  line-height: 1.6;
`;
