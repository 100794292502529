import { apiAxios } from 'shared/common/api/common';
import { Video } from 'shared/query/video/types';

export default {
  async create({ zoneId, formData }: { zoneId: number; formData: FormData }) {
    const response = await apiAxios.post<Video>(`/api/v2/zones/${zoneId}/videos`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 0,
    });
    return response.data;
  },
  async read({ zoneId, videoId }: { zoneId: number; videoId: number }) {
    const response = await apiAxios.get<Video>(`/api/v2/zones/${zoneId}/videos/${videoId}`);
    return response.data;
  },
  async update({
    zoneId,
    videoId,
    data,
  }: {
    zoneId: number;
    videoId: number;
    data: Partial<Video>;
  }) {
    const response = await apiAxios.patch(`/api/v2/zones/${zoneId}/videos/${videoId}`, data);
    return response.data;
  },
  async delete({ zoneId, videoId }: { zoneId: number; videoId: number }) {
    const response = await apiAxios.delete(`/api/v2/zones/${zoneId}/videos/${videoId}`);
    return response.data;
  },
  async list({ zoneId }: { zoneId: number }) {
    const response = await apiAxios.get<{ results: Video[] }>(`/api/v2/zones/${zoneId}/videos`);
    return response.data?.results;
  },
};
