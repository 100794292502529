import { baseUrl } from 'shared/common/urls';
import { StaticFileType } from './types';
import { pureAxios } from 'shared/common/api/common';

export default {
  async readFile({ type }: { type: StaticFileType }) {
    const response = await pureAxios.get(`${baseUrl}/static/${type}`);
    return response.data;
  },
  async listProj4Definition() {
    const response = await pureAxios.get(`${baseUrl}/static/coordinates/used`);
    return response.data?.results || [];
  },
  async listCoordinates({ search, limit }: { search: string; limit: string }) {
    const response = await pureAxios.get(
      `${baseUrl}/static/coordinates?search=${search}&limit=${limit}`,
    );
    return response.data?.results || [];
  },
};
