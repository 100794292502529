import React, { lazy } from 'react';
const LoginPage = lazy(
  () => import(/* webpackChunkName: "loginPage" */ 'features/loginPage/LoginPage'),
);

function Login() {
  return <LoginPage />;
}

export default Login;
