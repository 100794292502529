import { DEFAULT_FONT_FAMILY } from 'features/common/style/TextStyles';
import * as color from 'shared/common/colorScheme';
import { scrollbarWidth } from 'shared/common/policies/sizes';
import { background } from 'shared/styles/colors/background';
import { SCROLLBAR_THUMB_COLOR, SCROLLBAR_TRACK_COLOR } from 'shared/styles/colors/scrollbar';
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  html,
  body,
  #app {
    width: 100%;
    height: 100%;
    background-color: ${background.DEFAULT};
    font-size: 16px;

    .clearfix {
      zoom:1;

      &::after{
        content:' ';
        clear:both;
        display: block;
        height: 0;
        visibility: hidden;
      }
    }
  }

  body {
    font-family: ${DEFAULT_FONT_FAMILY};
    overflow-y: auto;

    & .rc-slider-tooltip {
      z-index: 2;
    }
  }

  @media (max-width: 1910px) {
    html{
      font-size:14px;
    }
  }
  @media (max-width: 1580px) {
    html{
      font-size:13px;
    }
  }
  @media (max-width: 1450px) {
    html{
      font-size:12px;
    }
  }

  a {
    text-decoration: none;
    color: initial;
    :hover{
      text-decoration: none;
    }
  }

  img {
    color: ${color.TRANSPARENT};
    -ms-interpolation-mode: bicubic;
  }

  p, ul {
    margin: 0;
    padding: 0;
  }

  ul {
    list-style: none;
  }

  ::-webkit-scrollbar {
    width: ${scrollbarWidth};
    height: ${scrollbarWidth};
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background-color: ${SCROLLBAR_THUMB_COLOR};
    border: 0.125rem solid transparent;
    background-clip: padding-box;
    transition: background-color 0.2s ;
  }
  ::-webkit-scrollbar-track {
    background-color: ${SCROLLBAR_TRACK_COLOR};
    border: none;
    transition: background-color 0.2s ;
  }
`;
