import { Permission } from 'shared/query/permission/types';

/** @deprecated features 하위로 이동 완료 후 삭제 필요 */
export type ProjectPaginationParams = {
  searchKeyword?: string;
  order?: string;
  page: number;
};

/** @deprecated features 하위로 이동 완료 후 삭제 필요 */
export type ProjectPagination = {
  result: {
    projects: ProjectListItem[];
  };
  meta: ProjectPaginationMeta;
};

/** @deprecated features 하위로 이동 완료 후 삭제 필요 */
export type ProjectPaginationMeta = {
  totalCount: number;
  hasNext: boolean;
};

/** @deprecated features 하위로 이동 완료 후 삭제 필요 */
export type ProjectListItem = {
  id: number;
  name: string;
  description?: string;
  thumbnailImg?: string;
  processedSnapshotCount?: number;
  latestTakeDate?: string;
  oldestZone?: ProjectZone;
  mapProvider: MapProvider;
  coordinate: string;
  permissionInfo?: Permission;
};

/** @deprecated features 하위로 이동 완료 후 삭제 필요 */
export type ProjectZone = {
  id: number;
  name: string;
  projectId: number;
};

export enum ProjectDomainType {
  CONSTRUCTION = 'CONSTRUCTION',
  GOLF = 'GOLF',
}

/** @deprecated features 하위로 이동 완료 후 삭제 필요 */
export type Project = {
  id?: number;
  name?: string;
  domainType?: ProjectDomainType;
  description?: string;
  constructionDate?: string;
  completedDate?: string;
  state?: boolean | null;
  owner?: string;
  snapshotIds?: number[];
  coordinate?: string;
  permissionInfo?: Permission;
  latestThumbnailUrl?: string;
  thumbnailImg?: string;
  latestOrthophotoBoundingBox?: { minx: string; maxx: string; miny: string; maxy: string };
  isExpired?: boolean;
  zoneIds?: number[];
  mapProvider?: MapProvider;
  lengthUnit?: ProjectUnit;
  areaUnit?: ProjectUnit;
  volumeUnit?: ProjectUnit;
  isMapRotation?: boolean;
  otp?: string;
  data?: ProjectDataUnit;
  ancestorFolderId?: number;
};

/** @deprecated features 하위로 이동 완료 후 삭제 필요 */
export type MapProvider = 'BING' | 'VWORLD' | 'GOOGLE';

/** @deprecated features 하위로 이동 완료 후 삭제 필요 */
export interface ProjectUnits {
  count: number;
  next: string;
  previous: string;
  results: ProjectUnit[];
}

/** @deprecated features 하위로 이동 완료 후 삭제 필요 */
export interface ProjectUnit {
  id: number;
  name: string;
  coefficient: number;
  type: 'LENGTH' | 'AREA' | 'VOLUME';
  default?: boolean;
  selected?: boolean;
}

/** @deprecated features 하위로 이동 완료 후 삭제 필요 */
export interface ProjectDataUnit {
  aiToolsFeatureFlag: boolean;
  aiToolsLandDisplacementFeatureFlag: boolean;
  aiToolsOdPipelineId: number;
  aiToolsSuperResolutionFeatureFlag: boolean;
  aiToolsNdmiFeatureFlag: boolean;
  aiToolsSegmentationPipelineTemplateId: number;
  isEarthworkDisabled: boolean;
  aiToolsCdFeatureFlag: boolean;
  aiToolsCdPipelineId: number;
  dtmActivateObjectDetectionFlag: boolean;
  logoutByDistance: number;
  scheduleAvailableUserList: string[];
}
