import { apiAxios } from 'shared/common/api/common';
import { convertKeysToSnakeCase } from 'shared/common/utils';
import { Position } from 'shared/query/annotation/types';
import { HazardArea, HazardEventHistoryParams } from 'shared/query/hazardArea/types';

export default {
  async read({ projectId, hazardAreaId }: { projectId: number; hazardAreaId: number }) {
    const response = await apiAxios.get<HazardArea>(
      `/api/v3/projects/${projectId}/hazard-areas/${hazardAreaId}`,
    );
    return response?.data;
  },
  async list({ projectId }: { projectId: number }) {
    const response = await apiAxios.get<{ results: HazardArea[] }>(
      `/api/v3/projects/${projectId}/hazard-areas`,
    );
    return response?.data?.results;
  },
  async create({ zoneId, data }: { zoneId: number; data: Partial<HazardArea> }) {
    const response = await apiAxios.post<Partial<HazardArea>>(
      `/api/v3/zones/${zoneId}/hazard-areas`,
      data,
    );
    return response?.data;
  },
  async update({
    zoneId,
    hazardAreaId,
    data,
  }: {
    zoneId: number;
    hazardAreaId: number;
    data: Partial<HazardArea>;
  }) {
    const response = await apiAxios.patch<Partial<HazardArea>>(
      `/api/v3/zones/${zoneId}/hazard-areas/${hazardAreaId}`,
      data,
    );
    return response?.data;
  },
  async updatePositions({
    zoneId,
    hazardAreaId,
    positionId,
    data,
  }: {
    zoneId: number;
    hazardAreaId: number;
    positionId: number;
    data: Position;
  }) {
    const response = await apiAxios.patch<Position>(
      `/api/v3/hazard-areas/${hazardAreaId}/positions/${positionId}`,
      data,
    );
    return response?.data;
  },
  async delete({ zoneId, hazardAreaId }: { zoneId: number; hazardAreaId: number }) {
    const response = await apiAxios.delete(`/api/v3/zones/${zoneId}/hazard-areas/${hazardAreaId}`);
    return response?.data;
  },
  async eventHistory({ projectId, data }: { projectId: number; data: HazardEventHistoryParams }) {
    const response = await apiAxios.get(`/api/v2/projects/${projectId}/laborers/event/annotation`, {
      params: { ...convertKeysToSnakeCase(data) },
    });
    return response?.data;
  },
};
