import { Laborer, LaborerTimeline } from 'shared/query/laborer/types';
import { LaborerBaseDate, LaborerSlider, VehicleSlider } from 'stores/data/types';
import { Vehicle, VehicleTimeline } from 'shared/query/vehicle/types';

export const SECOND = 1000;
export const HOUR = 60 * 60 * 1000;
export const DAY = 24 * 60 * 60 * 1000;

export function convertTimeToMs(time: string): number {
  return new Date(time).getTime();
}

export function convertMsToTime(milliseconds: number) {
  const seconds = Math.floor((milliseconds / 1000) % 60);
  const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
  const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 60);

  const secondsText = seconds < 10 ? '0' + seconds : seconds;
  const minutesText = minutes < 10 ? '0' + minutes : minutes;
  const hoursText = hours < 10 ? '0' + hours : hours;

  return `${hoursText}:${minutesText}:${secondsText}`;
}

export function getTimelineLaborers(timeline: LaborerTimeline[], slider: LaborerSlider): Laborer[] {
  if (timeline.length < 1) {
    return [];
  }

  const baseDate = new Date(timeline[0].baseTime);
  baseDate.setHours(0, 0, 0, 0);

  const laborers = timeline.find((value) => {
    const timeMs = convertTimeToMs(value.baseTime);
    const start = timeMs - baseDate.getTime();
    const end = start + SECOND * 10;

    return slider >= start && slider < end;
  })?.laborers;

  return laborers || [];
}

export function getTimelineVehicles(timeline: VehicleTimeline[], slider: VehicleSlider): Vehicle[] {
  if (timeline.length < 1) {
    return [];
  }

  const baseDate = new Date(timeline[0].baseTime);
  baseDate.setHours(0, 0, 0, 0);

  const laborers = timeline.find((value) => {
    const timeMs = convertTimeToMs(value.baseTime);
    const start = timeMs - baseDate.getTime();
    const end = start + SECOND * 10;

    return slider >= start && slider < end;
  })?.laborers;

  return laborers || [];
}

export function getTimelineDate(baseDate: LaborerBaseDate, slider: LaborerSlider): Date {
  const date = new Date(baseDate);
  const time = convertMsToTime(slider);
  const [hour, minute, second] = time.split(':');

  date.setHours(Number(hour));
  date.setMinutes(Number(minute));
  date.setSeconds(Number(second));

  return date;
}
