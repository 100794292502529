import { useMutation, useQueryClient } from 'react-query';
import api from './api';
import { keys } from './queryKeys';

export default function useProjectWithOrganizationMutation() {
  const queryClient = useQueryClient();

  const createFolderMutation = useMutation(
    (payload: { organizationId: number; name: string; parentFolderId?: number }) =>
      api.organization.createFolder(payload),
    {
      onSuccess: () => queryClient.invalidateQueries(keys.allProjectWithOrganization()),
      onError: () => {},
    },
  );

  const updateFolderMutation = useMutation(
    (payload: { id: number; name: string }) => api.organization.updateFolder(payload),
    {
      onSuccess: () => queryClient.invalidateQueries(keys.allProjectWithOrganization()),
      onError: () => {},
    },
  );

  const ungroupFolderMutation = useMutation(
    (payload: { folderId: number }) => api.organization.ungroupFolder(payload),
    {
      onSuccess: () => queryClient.invalidateQueries(keys.allProjectWithOrganization()),
    },
  );

  const moveFolderMutation = useMutation(
    (payload: {
      destinationFolderId: number;
      descendantFolderIdList: number[];
      currentFolderId: number;
      descendantIdList: number[];
    }) => api.organization.move(payload),
    {
      onSuccess: () => queryClient.invalidateQueries(keys.allProjectWithOrganization()),
    },
  );

  const deleteFolderMutation = useMutation(
    (payload: { folderId: number }) => api.organization.deleteFolder(payload),
    {
      onSuccess: () => queryClient.invalidateQueries(keys.allProjectWithOrganization()),
    },
  );

  return {
    createFolderMutation,
    updateFolderMutation,
    ungroupFolderMutation,
    moveFolderMutation,
    deleteFolderMutation,
  };
}
