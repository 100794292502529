import { apiAxios } from 'shared/common/api/common';

export default {
  async list({ projectId }: { projectId: number }) {
    const response = await apiAxios.get(`/api/projects/${projectId}/participants`);
    return response?.data;
  },
  async delete({
    projectId,
    data,
  }: {
    projectId: number;
    data: { invites: number[]; projectUsers: number[] };
  }) {
    const response = await apiAxios.delete(`/api/projects/${projectId}/participants`, { data });
    return response?.data;
  },
  async invite({
    projectId,
    data,
  }: {
    projectId: number;
    data: { projectUsers: { email: string; permission: string }[] };
  }) {
    const response = await apiAxios.post(`/api/projects/${projectId}/participants`, data);
    return response?.data;
  },
  async resendInvitation({ projectId, inviteeId }: { projectId: number; inviteeId: number }) {
    const response = await apiAxios.post(
      `/api/projects/${projectId}/invites/${inviteeId}/send_mail`,
    );
    return response?.data;
  },
};
