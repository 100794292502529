import { DEFAULT_QUERY_STALE_TIME } from 'shared/common/policies/request';
import { useQuery } from 'react-query';
import api from './api';
import { keys } from './queryKeys';

export default function usePermissionQuery(projectId: number) {
  return useQuery(keys.detail(projectId), () => api.read({ projectId }), {
    enabled: !!projectId,
    staleTime: DEFAULT_QUERY_STALE_TIME,
  });
}
