import { ProjectUser } from 'stores/data/types';

// 사용자 권한 관련
// 2020-11-19 https://www.notion.so/Cloud-2eeb8db8b0ab405cbd3513f4534f3e6d
// 노션 링크에 해당하는 내용으로 개발됨.

/**
 *  다른 유저를 수정하거나 삭제할 수 있는 권한
 * */
export const canManipulateOtherUser = (projectUser: ProjectUser) => {
  return projectUser?.permissionChangeOptions?.length > 0;
};
