import api from 'shared/query/resource/api';
import { Dem } from 'shared/query/resource/types';
import { useQuery } from 'react-query';

/** @todo
 * Feature 베이스로 모두 변경되고 나면 DEM Feature에서 가져오는 것으로 변경해야함
 */
export default function useConversionCompletedDemListQuery({ snapshotId }) {
  return useQuery<Dem[]>(
    ['dem', 'list', snapshotId],
    () => api.listBySnapshotId({ snapshotId, type: 'DEM' }),
    {
      enabled: true && Boolean(snapshotId),
      select: (data) => data?.filter((v) => v.conversionData?.progressStatus === 'DONE'),
    },
  );
}
