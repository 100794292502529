export const ELEVATION_GRAPH_COLOR = {
  STANDARD: '#FF8431',
  REFER_PLAN_LEVEL: '#B650FF',
  REFER_SNAPSHOT: '#63E063',
  REFER_STRATUM1: '#FFFF00',
  REFER_STRATUM2: '#FFC34E',
  REFER_STRATUM3: '#18FFFF',
  REFER_STRATUM4: '#FF4FFF',
  REFER_STRATUM5: '#9D95FF',
  REFER_STRATUM6: '#FF5733',
  REFER_STRATUM7: '#33FF57',
  REFER_STRATUM8: '#33A1FF',
  REFER_STRATUM9: '#FF33A1',
  REFER_STRATUM10: '#A1FF33',
};
