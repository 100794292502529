import {
  DEFAULT_POLLING_INTERVAL,
  DRAWING_POLLING_LONG_INTERVAL,
} from 'shared/common/policies/request';
import { useResourceListByZoneIdQuery, useResourceListQuery } from 'shared/query';
import { Drawing, isConversion, isEnhanceQuality } from 'shared/query/drawing/types';
import { Permission } from 'shared/query/permission/types';
import { RelationResource } from 'shared/query/relation/types';
import { useState } from 'react';
import { QueryStatus, useMutation, useQueryClient } from 'react-query';
import { DrawingPositioning } from '../../../query/drawing/types';
import { keys } from '../queryKeys';
import { snapshotResourceCompare, zoneResourceCompare } from '../sortOrder';
import { isPositioning, isVector } from '../typeGuards';
import api from './api';

export const DRAWING_TYPE_LIST = [
  'DRAWING_RASTER',
  'DRAWING_POSITIONING',
  'DRAWING_VECTOR',
] as const;
export function useAllTypeDrawingListQuery(
  snapshotId: number,
  permission: Permission,
  enabled = false,
) {
  const resources = DRAWING_TYPE_LIST.map((x) =>
    useDrawingListBySnapshotIdQuery(
      { snapshotId, permission, type: x },
      { enabled: Boolean(enabled) },
    ),
  );

  return resources?.reduce(
    (acc, cur) => ({
      status: getStatus(acc.status, cur.status),
      drawings: [...acc?.drawings, ...(cur?.resources ?? [])]?.sort(
        snapshotResourceCompare,
      ) as Drawing[],
    }),
    { status: 'idle' as QueryStatus, drawings: [] as Drawing[] },
  );
}

type useResourceListQueryParams = Parameters<typeof useResourceListQuery>;
function useDrawingListBySnapshotIdQuery(
  { snapshotId, permission, type }: useResourceListQueryParams[0],
  options?: useResourceListQueryParams[1],
) {
  const [refetchInterval, setRefetchInterval] = useState<number | false>(false);
  return useResourceListQuery(
    {
      snapshotId,
      permission,
      type,
    },
    {
      refetchInterval,
      onSuccess: (data) => {
        const listNeededToRefetch = (data as Drawing[])?.filter(
          (x) => isConversion(x) || isEnhanceQuality(x),
        );
        if (listNeededToRefetch?.length > 0) {
          setRefetchInterval(
            (listNeededToRefetch as Drawing[])?.find((x) => isVector(x) || isPositioning(x))
              ? DEFAULT_POLLING_INTERVAL
              : DRAWING_POLLING_LONG_INTERVAL,
          );
        } else {
          setRefetchInterval(false);
        }
      },
      ...options,
    },
  );
}

export function useAllTypeDrawingListByZoneIdQuery(
  zoneId: number,
  snapshotId: number,
  enabled = false,
) {
  const resources = DRAWING_TYPE_LIST.map((x) =>
    useResourceListByZoneIdQuery({ zoneId, snapshotId, type: x }, { enabled: Boolean(enabled) }),
  );

  return resources?.reduce(
    (acc, cur) => ({
      status: getStatus(acc.status, cur.status),
      drawings: [...acc?.drawings, ...(cur?.resources ?? [])].sort(
        zoneResourceCompare,
      ) as RelationResource[],
    }),
    { status: 'idle' as QueryStatus, drawings: [] as RelationResource[] },
  );
}

export function useDrawingMutation() {
  const queryClient = useQueryClient();

  /**
   * DRAWING_RASTER용 변환 중단 API
   */
  const abortDrawing = useMutation(
    ({ snapshotId, drawingId }: { snapshotId: number; drawingId: number }) =>
      api.abort({ snapshotId, drawingId }),
    {
      onSuccess: (_, v) =>
        queryClient.invalidateQueries(keys.listBySnapshotId(v?.snapshotId, 'DRAWING_RASTER')),
    },
  );
  /**
   * DRAWING_RASTER용 변환 재시작 API
   */
  const retryDrawing = useMutation(
    ({ snapshotId, drawingId }: { snapshotId: number; drawingId: number }) =>
      api.retry({ snapshotId, drawingId }),
    {
      onSuccess: (_, v) =>
        queryClient.invalidateQueries(keys.listBySnapshotId(v?.snapshotId, 'DRAWING_RASTER')),
    },
  );

  /**
   * DRAWING_POSITIONING용 data 수정 API
   */
  const updatePositioning = useMutation(
    ({
      drawingId,
      conversionDataId,
      data,
    }: {
      drawingId: number;
      conversionDataId: number;
      data: Partial<DrawingPositioning['conversionData']['data']>;
    }) => api.updatePositioning({ drawingId, conversionDataId, data }),
    { onSuccess: (_, v) => queryClient.invalidateQueries(keys.detail(v?.drawingId)) },
  );
  return { abortDrawing, retryDrawing, updatePositioning };
}

const getStatus = (acc: QueryStatus, cur: QueryStatus): QueryStatus => {
  if (acc === 'error' || cur === 'error') {
    return 'error';
  }
  if (acc === 'loading' || cur === 'loading') {
    return 'loading';
  }
  if (acc === 'idle' && cur === 'idle') {
    return 'idle';
  }
  return 'success';
};
