import axios, { AxiosError, CancelToken } from 'axios';
import { useMatchParams } from 'features/common/hooks';
import { requestUploadFile, s3DirectFileUpload } from 'shared/query/fileUpload/s3Upload';
import { S3FileUploadType } from 'shared/query/fileUpload/types';
import { annotationKeys, earthworkKeys, resourceKeys } from 'shared/query/queryKeys';
import { TempFile } from 'shared/query/resource/types';
import { useMutation, useQueryClient } from 'react-query';
import api from './api';
import { keys } from './queryKeys';
import { DrawingVector, DrawingVectorResource } from './types';

interface CreationProps {
  snapshotId: number;
  tempFile: TempFile;
  cancelToken: CancelToken;
  onSuccess?: (t) => void;
  onError?: (t: TempFile, errorCode?: string) => void;
  onCancel?: (t) => void;
}

export default function useDrawingVectorMutation() {
  const queryClient = useQueryClient();
  const { zoneId } = useMatchParams();
  const createMutation = useMutation<
    DrawingVectorResource,
    AxiosError<{ errorCode: string; message: string }>,
    CreationProps
  >(
    async ({ snapshotId, tempFile, cancelToken }: CreationProps) => {
      const { data: dataForUploads } = await requestUploadFile(tempFile);

      const { fields } = dataForUploads as S3FileUploadType;
      await s3DirectFileUpload(dataForUploads, tempFile?.file, cancelToken);

      const resource = await api.create({
        snapshotId,
        data: {
          snapshotId,
          type: tempFile?.type,
          name: tempFile?.name,
          filename: fields?.key || fields?.Key,
          cancelToken,
        },
      });
      return resource;
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(keys.all());
        queryClient.invalidateQueries(resourceKeys.all());
        queryClient.invalidateQueries(
          annotationKeys.listBySnapshotId('polygons', variables.snapshotId),
        );
        queryClient.invalidateQueries(keys.resourceList(zoneId));
        queryClient.invalidateQueries(keys.summaries(variables.snapshotId));
        queryClient.invalidateQueries(earthworkKeys.quantityEstimation.list(zoneId));
        variables?.onSuccess({ ...data, ...variables.tempFile });
      },
      onError: (error, variables) => {
        if (axios.isCancel(error)) {
          variables?.onCancel && variables.onCancel(variables.tempFile);
        } else {
          variables?.onError(variables.tempFile, error?.response?.data?.errorCode);
        }
      },
    },
  );

  const updateMutation = useMutation(
    ({ drawingVectorId, data }: { drawingVectorId: number; data: Partial<DrawingVector> }) =>
      api.update({ drawingVectorId, data }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(keys.all());
      },
    },
  );

  const deleteMutation = useMutation<
    DrawingVectorResource,
    AxiosError<{ errorCode: string; message: string }>,
    { snapshotId: number; id: number }
  >(
    async ({ snapshotId, id }) => {
      const resource = await api.delete({ snapshotId, id });
      return resource;
    },
    {
      onSuccess: (res, v) => {
        queryClient.invalidateQueries(keys.resourceList(zoneId));
        queryClient.invalidateQueries(keys.summaries(v.snapshotId));
        queryClient.invalidateQueries(resourceKeys.all());
        queryClient.invalidateQueries(earthworkKeys.quantityEstimation.list(zoneId));
      },
    },
  );

  const updateCompletionMutation = useMutation(
    ({
      drawingVectorId,
      bulkPolygonId,
      data,
    }: {
      drawingVectorId: number;
      bulkPolygonId: number;
      data: { name?: string; completionDate?: string };
    }) => api.updateInfo({ drawingVectorId, bulkPolygonId, data }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(keys.all());
      },
    },
  );

  return { createMutation, deleteMutation, updateMutation, updateCompletionMutation };
}
