import { AxiosError } from 'axios';
import { DEFAULT_QUERY_STALE_TIME } from 'shared/common/policies/request';
import { useQuery, UseQueryOptions } from 'react-query';
import api from './api';
import { keys } from './queryKeys';
import { Coordinate, StaticFile, StaticFileType } from './types';

export function useStaticFileQuery(
  { type }: { type: StaticFileType },
  options?: UseQueryOptions<StaticFile, AxiosError<Error>>,
) {
  const { enabled = true, ...rest } = options || {};
  return useQuery<StaticFile>(keys.detail(type), () => api.readFile({ type }), {
    enabled: Boolean(enabled),
    staleTime: DEFAULT_QUERY_STALE_TIME,
    onError: () => {},
    ...rest,
  });
}

export function useListProj4DefinitionQuery(
  options?: UseQueryOptions<Coordinate[], AxiosError<Error>>,
) {
  const { enabled = true, ...rest } = options || {};
  return useQuery<Coordinate[]>(keys.proj4(), () => api.listProj4Definition(), {
    enabled: Boolean(enabled),
    staleTime: DEFAULT_QUERY_STALE_TIME,
    ...rest,
  });
}

export function useListCoordinatesQuery(
  { search = '', limit = '' }: { search?: string; limit?: string },
  options?: UseQueryOptions<Coordinate[], AxiosError<Error>>,
) {
  const { enabled = true, ...rest } = options || {};
  return useQuery<Coordinate[]>(
    keys.coordinates(search, limit),
    () => api.listCoordinates({ search, limit }),
    {
      enabled: Boolean(enabled),
      staleTime: DEFAULT_QUERY_STALE_TIME,
      ...rest,
    },
  );
}
