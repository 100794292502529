import React, { lazy } from 'react';
const MobileRedirectPage = lazy(
  () =>
    import(/* webpackChunkName: "mobileRedirectPage" */ 'features/redirect/MobileRedirectPage'),
);

import { guard } from 'shared/common/policies/redirect';

function Guard() {
  return <MobileRedirectPage {...guard} />;
}

export default Guard;
