import {
  FileResourceEnum,
  MediaListSearchParams,
  PhotoBoxMediaResponseEntity,
  PhotoBoxOrderByEnum,
  PhotoBoxSortDate,
} from 'shared/query/photoBox/types';
import { nls } from 'shared/locale/language';

export enum PhotoBoxMediaEnum {
  MEDIA = 'MEDIA',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}
export enum PhotoBoxAlbumEnum {
  ALBUM = 'album',
  DETAIL = 'detail',
}
export const PHOTO_BOX_SNACKBAR_DURATION = 5000;
export const uploadFileLimitCount = 1000;
export const uploadMemoLimitCount = 40;
export const albumNameLimitCount = 20;
export const extraAutocompleteInputLimitCount = 20;
export const mediaFileLimit = 50;
export const selectedItemLimit = 30;
export const initialDetailMediaFile: PhotoBoxMediaResponseEntity = {
  id: 0,
  projectId: 0,
  zoneId: 0,
  resource: {
    file: '',
    fileName: '',
    type: FileResourceEnum.IMAGE,
    size: 0,
  },
  type: PhotoBoxMediaEnum.IMAGE,
  latitude: 0,
  longitude: 0,
  coordinate: '',
  memo: '',
  mediaMeta: {
    videoDuration: 0,
  },
  file: null,
  resourceThumbnail: {
    file: '',
  },
  created: '',
  createdByUser: {
    id: 0,
    name: '',
    companyName: '',
    job: '',
  },
  albumList: [],
  extraList: [],
};

/* 포토박스 - 현장 상황 */
export const photoBoxSecondarySideMenuListLimitCount = 100;

/* 검색 파라미터 초기값 */
export const initialSearchParams: MediaListSearchParams = {
  zoneId: null,
  extra: null,
  memo: null,
  startShootingDate: null,
  endShootingDate: null,
  createdByUser: null,
  orderBy: PhotoBoxOrderByEnum.SHOOTING_DATE_DESC,
};

/* 정렬 목록 */
export const sortDateList: PhotoBoxSortDate[] = [
  {
    value: PhotoBoxOrderByEnum.SHOOTING_DATE_DESC,
    name: nls.photoBoxSearchParamsOrderByDescLabel(),
  },
  {
    value: PhotoBoxOrderByEnum.SHOOTING_DATE_ASC,
    name: nls.photoBoxSearchParamsOrderByAscLabel(),
  },
];
