import { apiAxios } from 'shared/common/api/common';

export default {
  async readVworldKey({ token }: { token?: string }) {
    const response = await apiAxios.get<{ authKey: string }>('/auth/v3/key/mapapi', {
      params: { token },
    });
    return response.data?.authKey;
  },
};
