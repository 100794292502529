import FolderTreeItem from 'features/styleComponents/folder/FolderTreeItem';
import React from 'react';

export type FolderTreeItemType = {
  current: {
    id: number;
    name: string;
  };
  childrenFolderList?: FolderTreeItemType[];
};

interface Props {
  data: FolderTreeItemType;
  currentFolderId: number;
  selectedFolderId?: number;
  setSelectedFolderId?: (id: number) => void;
  setSelectedFolderName?: (name: string) => void;
  disabledFolderIdList?: number[];
  rootFolderName?: string;
}

const ROOT_FOLDER_LEVEL = 1;
export default function FolderTreeView({
  data,
  currentFolderId,
  selectedFolderId,
  disabledFolderIdList,
  setSelectedFolderId,
  setSelectedFolderName,
  rootFolderName = null,
}: Props) {
  if (!data) return null;
  const renderTree = (nodes: FolderTreeItemType, level: number) => (
    <FolderTreeItem
      key={nodes.current.id}
      nodeId={nodes.current.id}
      name={level === ROOT_FOLDER_LEVEL && rootFolderName ? rootFolderName : nodes.current.name}
      level={level}
      isRoot={level === ROOT_FOLDER_LEVEL}
      isCurrent={nodes.current.id === currentFolderId}
      selected={nodes.current.id === selectedFolderId}
      disabled={disabledFolderIdList?.includes(nodes.current.id)}
      onSelect={(id, name) => {
        setSelectedFolderId?.(id);
        setSelectedFolderName?.(name);
      }}
    >
      {Array.isArray(nodes.childrenFolderList)
        ? nodes.childrenFolderList.map((node) => renderTree(node, level + 1))
        : null}
    </FolderTreeItem>
  );

  return <>{renderTree(data, ROOT_FOLDER_LEVEL)}</>;
}
