import React, { lazy } from 'react';
const GreenShortcutPage = lazy(
  () =>
    import(
      /* webpackChunkName: "GreenShortcutPage" */ 'features/green/components/shortcut/GreenShortcutPage'
    ),
);

function GreenShortcut() {
  return <GreenShortcutPage />;
}

export default GreenShortcut;
