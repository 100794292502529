import { openAlert } from 'shared/component/modal/AlertModal';

interface FeedbackService {
  /**
   * 확인 버튼 1가지로 구성된 모달을 출력한다.
   * @example
   *  feedback.alert('이미 메이사에 방문 하셨습니다.')
   *  .then(() => {
   *    // 사용자가 확인을 누른 뒤 수행할 내용
   *  });
   *
   */
  alert(message: string): Promise<boolean>;
}

export const feedback: FeedbackService = {
  alert: openAlert,
};
