import React, { lazy } from 'react';
const UserInfoPage = lazy(
  () => import(/* webpackChunkName: "userInfo" */ 'features/user/userInfoPage/UserInfo'),
);

function UserInfo() {
  return <UserInfoPage />;
}

export default UserInfo;
