import MuiTooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { others } from 'shared/styles/colors/others';
import { hexToRgba } from 'shared/styles/colors/utils';

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: others.TOOLTIP_BACKGROUND,
    color: theme.palette.primary.contrastText,
    borderRadius: '0.25rem',
    fontSize: '0.875rem',
    fontWeight: 500,
    padding: '0.25rem 0.5rem',
  },
  arrow: {
    color: others.TOOLTIP_BACKGROUND,
  },
}))(MuiTooltip);

export default StyledTooltip;

export function BigArrowTooltip(props: TooltipProps) {
  const classes = makeStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.primary.contrastText,
      borderRadius: '1rem',
      maxWidth: 'none',
      boxShadow: theme.shadows[24],
    },
    arrow: {
      width: '2em',
      height: '1.42em',
      marginTop: '-1.42em !important',
      color: theme.palette.background.paper,
    },
  }))();

  return <StyledTooltip classes={classes} {...props} />;
}

export function ElevationTooltip(props: TooltipProps & { $isError?: boolean }) {
  const { $isError, ...rest } = props;
  const classes = makeStyles((theme) => ({
    tooltip: {
      color: $isError ? theme.palette.error.light : theme.palette.warning.main,
      marginBottom: '1.125rem',
    },
  }))(StyledTooltip);

  return <StyledTooltip classes={classes} {...rest} />;
}

export function TooltipWithMargin(props: TooltipProps & { margin?: string }) {
  const { margin, ...rest } = props;
  const classes = makeStyles(() => ({
    tooltip: { margin },
  }))(StyledTooltip);

  return <StyledTooltip classes={classes} {...rest} />;
}

export function WhiteTooltip(props: TooltipProps) {
  return <TooltipInWhite {...props} />;
}

const TooltipInWhite = withStyles((theme) => ({
  tooltip: {
    backgroundColor: hexToRgba(theme.palette.background.paper, 0.9),
    color: theme.palette.text.primary,
    borderRadius: '0.25rem',
    fontSize: '0.875rem',
    fontWeight: 500,
    padding: '0.25rem 0.5rem',
    margin: '0 0.5rem',
  },
}))(MuiTooltip);
