import ArrowBack from '@material-ui/icons/ArrowBack';
import Close from '@material-ui/icons/Close';
import TextButton from 'features/common/button/TextButton';
import { nls } from 'shared/locale/language';
import React from 'react';
import styled from 'styled-components';
import theme from 'shared/styles/mui/theme';
import { useHistory } from 'react-router';
import routes from 'pages/routes';
import { useMatchParams } from 'features/common/hooks';

interface Props {
  backButtonLabel: string;
}
export default function Header({ backButtonLabel }: Props) {
  const { projectId, zoneId, snapshotId, issueId } = useMatchParams();
  const history = useHistory();
  const goBack = () => {
    history.replace(routes.issue.polygon.index.of({ projectId, zoneId, snapshotId, issueId }));
  };

  return (
    <Toolbar>
      <StyledTextButton onClick={goBack} startIcon={<ArrowBack />}>
        {backButtonLabel}
      </StyledTextButton>
      <StyledTextButton onClick={goBack} startIcon={<Close />}>
        {nls.close()}
      </StyledTextButton>
    </Toolbar>
  );
}
const Toolbar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: calc(100% - 4rem);
  background-color: ${theme.palette.text.primary};
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5rem;
`;

const StyledTextButton = styled(TextButton).attrs({ size: 'large' })`
  color: ${theme.palette.primary.contrastText};
`;
