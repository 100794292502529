import GA from 'shared/ga';
import * as category from '../category';

import { TWO_D_EARTHWORK_FILE_SET } from 'shared/ga/category';

export const TWO_D_EARTHWORK_FILE_SET_BTN = `${TWO_D_EARTHWORK_FILE_SET}Btn`;
export const TWO_D_EARTHWORK_FILE_SET_FOLDER_ADD_BTN = `${TWO_D_EARTHWORK_FILE_SET}FolderAddBtn`;
export const TWO_D_EARTHWORK_FILE_SET_UPLOAD_BTN = `${TWO_D_EARTHWORK_FILE_SET}UploadBtn`;
export const TWO_D_EARTHWORK_FILE_SET_SHARE_MAIL_BTN = `${TWO_D_EARTHWORK_FILE_SET}ShareMailBtn`;
export const TWO_D_EARTHWORK_FILE_SET_SHARE_MAIL_POPUP_SHARE_BTN = `${TWO_D_EARTHWORK_FILE_SET}ShareMailPopupShareBtn`;
export const TWO_D_EARTHWORK_FILE_SET_MORE_SHARE_MAIL_MENU = `${TWO_D_EARTHWORK_FILE_SET}MoreShareMailMenu`;

export const sendEventTwoDEarthworkFileSetFolderAddBtn = () => {
  GA.event(category.TWO_D_EARTHWORK_FILE_SET, TWO_D_EARTHWORK_FILE_SET_FOLDER_ADD_BTN);
};
export const sendEventTwoDEarthworkFileSetUploadBtn = () => {
  GA.event(category.TWO_D_EARTHWORK_FILE_SET, TWO_D_EARTHWORK_FILE_SET_UPLOAD_BTN);
};
export const sendEventTwoDEarthworkFileSetShareMailBtn = () => {
  GA.event(category.TWO_D_EARTHWORK_FILE_SET, TWO_D_EARTHWORK_FILE_SET_SHARE_MAIL_BTN);
};
export const sendEventTwoDEarthworkFileSetShareMailPopupShareBtn = () => {
  GA.event(category.TWO_D_EARTHWORK_FILE_SET, TWO_D_EARTHWORK_FILE_SET_SHARE_MAIL_POPUP_SHARE_BTN);
};
export const sendEventTwoDEarthworkFileSetMoreShareMailMenu = () => {
  GA.event(category.TWO_D_EARTHWORK_FILE_SET, TWO_D_EARTHWORK_FILE_SET_MORE_SHARE_MAIL_MENU);
};
