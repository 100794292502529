import { Typography } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import React, { Fragment } from 'react';
import { nls } from 'shared/locale/language';
import theme from 'shared/styles/mui/theme';
import styled from 'styled-components';
import { ProjectPathNavigationItemType } from '../types';
import ProjectPathNavigationItem from './ProjectPathNavigationItem';
import ProjectPathNavigationMenu from './ProjectPathNavigationMenu';

interface Props {
  showOnlyRoot: boolean;
  rootPathItem: ProjectPathNavigationItemType;
  hiddenFolderList: ProjectPathNavigationItemType[];
  visibleFolderList: ProjectPathNavigationItemType[];
}

export default function ProjectPathNavigation({
  showOnlyRoot,
  rootPathItem,
  hiddenFolderList,
  visibleFolderList,
}: Props) {
  return (
    <Container>
      {rootPathItem ? (
        <ProjectPathNavigationItem folderItem={rootPathItem} />
      ) : (
        <Typography variant="h4" color="textPrimary">
          {nls.zoneProjectList()}
        </Typography>
      )}
      {!showOnlyRoot && (
        <>
          {hiddenFolderList.length > 0 && (
            <>
              <DepthIndicator />
              <ProjectPathNavigationMenu folderItemList={hiddenFolderList} />
            </>
          )}
          {visibleFolderList.map((folderItem) => (
            <Fragment key={folderItem.name}>
              <DepthIndicator />
              <ProjectPathNavigationItem key={folderItem.name} folderItem={folderItem} />
            </Fragment>
          ))}
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0rem 0.5rem;
  gap: 0.25rem;
`;

const DepthIndicator = styled(ChevronRight)`
  color: ${theme.palette.text.secondary};
  margin: 0 1rem;
  &.MuiSvgIcon-root {
    width: 2.5rem;
    height: 2.5rem;
  }
`;
