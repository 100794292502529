import * as H from 'history';
import { nls } from 'shared/locale/language';
import React, { useEffect, useRef } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import GlobalDialog from './GlobalDialog';
import useGlobalDialog from './hooks/useGlobalDialog';

interface Props {
  /** 컨펌창 띄울 지 여부 */
  shouldConfirm: boolean;
  title?: string;
  message?: string;
  doBeforeExiting?: (location?: H.Location, action?: H.Action) => void;
}

// 새로고침, 닫기 등 이탈 상황을 막기 위한 이벤트 리스너
const beforeUnloadListener = (e) => {
  e.preventDefault();
  e.returnValue = ''; // 레거시 브라우저 호환용
  return ''; // 원하는 동작을 위해서는 문자열 반환 값이 필요함
};

const ConfirmWithoutSaving = ({ shouldConfirm, title, message, doBeforeExiting }: Props) => {
  const confirmedNavigation = useRef<boolean>(false);

  const history = useHistory();
  const { showConfirm, closeDialog } = useGlobalDialog();

  useEffect(() => {
    if (shouldConfirm) {
      window.addEventListener('beforeunload', beforeUnloadListener);
    }
    return () => window.removeEventListener('beforeunload', beforeUnloadListener);
  }, [shouldConfirm]);

  function goBack(location: H.Location, action: H.Action) {
    if (!location.pathname) return history.goBack();
    const search = location.search ?? '';
    switch (action) {
      case 'PUSH':
        return history.push(location.pathname + search);
      case 'REPLACE':
      default:
        return history.replace(location.pathname + search);
    }
  }

  // url 변경시 발생(브라우저 뒤로 버튼, 뒤로 버튼, GNB 클릭시)
  function handleAttemptNavigation(location: H.Location, action: H.Action) {
    // 체크해야할 필요가 없거나 페이지 나가시겠습니까 ? 컨펌창에서 확인 클릭했을 경우 다른 곳으로 이동 가능
    if (!shouldConfirm || confirmedNavigation.current) {
      doBeforeExiting?.(location, action);
      closeDialog();
      confirmedNavigation.current = false;
      return true;
    }

    showConfirm({
      title: title ?? nls.titleExitWithoutSaving(),
      content: message ?? nls.exitWithoutSaving(),
      primaryButtonProps: {
        title: nls.confirm(),
        onClick: () => {
          confirmedNavigation.current = true;
          goBack(location, action);
        },
      },
    });

    return false;
  }

  return (
    <>
      <Prompt when={shouldConfirm} message={handleAttemptNavigation} />
      <GlobalDialog />
    </>
  );
};

export default React.memo(ConfirmWithoutSaving);
