import Close from '@material-ui/icons/Close';
import Delete from '@material-ui/icons/Delete';
import PersonAdd from '@material-ui/icons/PersonAdd';
import { canManipulateOtherUser } from 'shared/common/policies/permission';
import ConfirmWithoutSaving from 'features/common/ConfirmWithoutSaving';
import { ContainedButton } from 'features/common/button/ContainedButton';
import TextButton from 'features/common/button/TextButton';
import { useDefaultEventBlocker, useMatchParams } from 'features/common/hooks';
import useGlobalDialog from 'features/common/hooks/useGlobalDialog';
import PUHeader from 'features/common/pu/Header';
import { nls } from 'shared/locale/language';
import { usePermissionQuery, useProjectQuery } from 'shared/query';
import {
  useParticipantListQuery,
  useParticipantMutation,
} from 'shared/query/participant/useParticipantQuery';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import ProjectPageLayout from '../ProjectPageLayout';
import InvitationDialog from './modal/InvitationDialog';
import MemberTable from './table/MemberTable';

export default function ProjectMemberPage() {
  useDefaultEventBlocker(window, 'contextmenu');
  const { projectId } = useMatchParams();
  const history = useHistory();
  const { deleteParticipant } = useParticipantMutation();
  const { showAlert, showConfirm } = useGlobalDialog();

  const project = useProjectQuery(projectId)?.data ?? {};
  const { data: permission } = usePermissionQuery(projectId);
  const { data: participants } = useParticipantListQuery(projectId);
  const projectUsers = participants?.projectUsers ?? [];
  const invitees = participants?.invites ?? [];

  const [invitationDialogOpen, setInvitationDialogOpen] = useState(false);
  const [checked, setChecked] = useState({} as { [id: number]: boolean });
  const [allChecked, setAllChecked] = useState(false);
  const [anyChecked, setAnyChecked] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [deletionResponseDisplayed, setDeletionResponseDisplayed] = useState(false);
  const deletionStatus = deleteParticipant.status;

  useEffect(() => {
    if (permission && !permission?.userGet) {
      showAlert({
        content: nls.noPermissionMsgContent(),
        primaryButtonProps: { onClick: () => history.goBack() },
      });
    }
  }, [permission]);

  useEffect(() => {
    initializeChecked();
  }, [invitees, projectUsers]);

  useEffect(() => {
    !deleteMode && initializeChecked();
  }, [deleteMode]);

  useEffect(() => {
    const items = Object.values(checked);
    const allItemsChecked = items.length > 0 && items.every((x) => x);
    setAllChecked(allItemsChecked);
    setAnyChecked(Object.values(checked).some((x) => x));
  }, [checked]);

  useEffect(() => {
    if (!deletionResponseDisplayed && deletionStatus === 'error') {
      showAlert({ content: nls.failedToDeleteUser() });
      setDeletionResponseDisplayed(true);
    }
  }, [deletionStatus]);

  function initializeChecked() {
    const newChecked = {};
    [...invitees, ...projectUsers]
      .filter((x) => canManipulateOtherUser(x))
      .forEach((x) => {
        newChecked[x.id] = false;
      });
    setChecked(newChecked);
  }

  function onInviteClick() {
    setInvitationDialogOpen(true);
  }

  function onDeleteClick() {
    if (!deleteMode) {
      setDeleteMode(true);
      return;
    }

    if (Object.values(checked).every((x) => !x)) {
      showAlert({ content: nls.noMemberSelected() });
      return;
    }

    const count = Object.values(checked).filter((x) => x).length;
    showConfirm({
      title: nls.deleteUserTitle(),
      content: nls.deleteUser(count),
      primaryButtonProps: {
        title: nls.delete(),
        onClick: onDeleteConfirm,
        color: 'secondary',
      },
    });
  }
  function onExitClick() {
    if (anyChecked) {
      showConfirm({
        title: nls.titleExitWithoutSaving(),
        content: nls.exitWithoutSaving(),
        primaryButtonProps: {
          title: nls.confirm(),
          onClick: () => setDeleteMode(false),
        },
      });
      return;
    }
    setDeleteMode(false);
  }
  function onDeleteConfirm() {
    deleteParticipant.mutate({
      projectId,
      data: {
        projectUsers: projectUsers.map((x) => x.id).filter((x) => checked[x]),
        invites: invitees.map((x) => x.id).filter((x) => checked[x]),
      },
    });
    setDeletionResponseDisplayed(false);
  }
  function onAllCheckedChange(x: boolean) {
    setChecked((prev) => {
      const newChecked = {};
      Object.keys(prev).forEach((id) => {
        newChecked[id] = x;
      });
      return newChecked;
    });
  }

  const hasSendMailPermission = permission && permission.userPost;
  const showDeleteButton = permission && permission.userDelete;
  const showExitButton = deleteMode && showDeleteButton;
  const showInviteButton = !deleteMode && permission && permission.userPost;
  const DeleteButtonComponent = deleteMode ? HeaderContainedButton : HeaderTextButton;
  return (
    <ProjectPageLayout project={project}>
      <StyledWrapper>
        <PUHeader title={nls.participantsInfo()} divider={false}>
          <div>
            {showDeleteButton && (
              <DeleteButtonComponent startIcon={<Delete />} warning onClick={onDeleteClick}>
                {nls.deleteUserTitle()}
              </DeleteButtonComponent>
            )}
            {showExitButton && (
              <HeaderTextButton startIcon={<Close />} color="default" onClick={onExitClick}>
                {nls.cancelDeleteMode()}
              </HeaderTextButton>
            )}
            {showInviteButton && (
              <HeaderContainedButton startIcon={<PersonAdd />} onClick={onInviteClick}>
                {nls.inviteParticipant()}
              </HeaderContainedButton>
            )}
          </div>
        </PUHeader>
        <MemberTable
          invites={invitees}
          projectUsers={projectUsers}
          deleting={deleteMode}
          allChecked={allChecked}
          onAllCheckedChange={onAllCheckedChange}
          checked={checked}
          onCheckedChange={(id, x) => setChecked((prev) => ({ ...prev, [id]: x }))}
          hasSendMailPermission={hasSendMailPermission}
        />
      </StyledWrapper>
      <InvitationDialog
        open={invitationDialogOpen}
        onClose={() => setInvitationDialogOpen(false)}
        invitees={invitees}
        projectUsers={projectUsers}
      />
      <ConfirmWithoutSaving shouldConfirm={anyChecked} />
    </ProjectPageLayout>
  );
}

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const HeaderContainedButton = styled(ContainedButton).attrs({ size: 'large' })`
  button + & {
    margin-left: 0.5rem;
  }
`;
const HeaderTextButton = styled(TextButton).attrs({ size: 'large' })`
  button + & {
    margin-left: 0.5rem;
  }
`;
