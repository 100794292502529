import TextField from '@material-ui/core/TextField';
import PersonAdd from '@material-ui/icons/PersonAdd';
import { memberPermission } from 'shared/common/types';
import { TextButtonWithBackground } from 'features/common/button/TextButton';
import Select from 'features/common/select/Select';
import { nls } from 'shared/locale/language';
import React from 'react';
import styled from 'styled-components';
import { others } from 'shared/styles/colors/others';
import theme from 'shared/styles/mui/theme';

interface Props {
  gradeOptions: typeof memberPermission.OWNER[];
  values: {
    email: string;
    grade: string;
  };
  errors: {
    email: string;
    grade: string;
  };
  onValueChange: (key: 'email' | 'grade', value: string) => void;
  onAddClick: () => void;
  disableAdd: boolean;
}

export default function Inputs({
  gradeOptions,
  values,
  errors,
  onAddClick,
  onValueChange,
  disableAdd,
}: Props) {
  return (
    <InputContainer>
      <StyledTextField
        label={nls.email()}
        error={Boolean(errors.email)}
        helperText={errors.email}
        value={values.email}
        onChange={(e) => onValueChange('email', e.target.value.toLowerCase())}
      />
      <StyledSelect
        placeholder={nls.grade()}
        options={gradeOptions.map((x) => ({ name: x.title, value: x.value }))}
        value={values.grade}
        onChange={(e) => onValueChange('grade', e.target.value as string)}
        error={errors.grade}
      />
      <InviteButton startIcon={<PersonAdd />} onClick={onAddClick} disabled={disableAdd}>
        {nls.invite()}
      </InviteButton>
    </InputContainer>
  );
}

const InputContainer = styled.div`
  display: flex;
  margin-top: 2.5rem;
  align-items: flex-start;

  & > :not(:last-child) {
    margin-right: 0.5rem;
  }
`;

const StyledTextField = styled(TextField).attrs({ variant: 'outlined', size: 'small' })`
  flex: 1;

  label  {
    color: ${theme.palette.text.secondary};
  }

  & .MuiOutlinedInput-root:not(.Mui-focused):not(.Mui-error) .MuiOutlinedInput-notchedOutline {
    border-color: ${others.BORDER};
  }

  .MuiOutlinedInput-input {
    padding: 1.15625rem 0.875rem;
    padding-top: 0.65625rem;
    padding-bottom: 0.65625rem;
  }
`;

const StyledSelect = styled(Select)`
  flex: 0.5;
  min-width: 14.4rem;
  height: 100%;

  .MuiInputBase-root .MuiSelect-root {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }
`;

const InviteButton = styled(TextButtonWithBackground).attrs({ color: 'primary', size: 'large' })`
  margin: 1px 0;
`;
