import React, { lazy } from 'react';
const SuperResolutionComparePage = lazy(
  () =>
    import(
      /* webpackChunkName: "CompareSuperResolutionPage" */ 'features/snapshot/comparePage/superResolution/SuperResolutionComparePage'
    ),
);

function SuperResolutionCompareTwo(props) {
  return <SuperResolutionComparePage type="two" {...props} />;
}

export default SuperResolutionCompareTwo;
