import { memberPermission } from 'shared/common/types';
import ConfirmWithoutSaving from 'features/common/ConfirmWithoutSaving';
import { useMatchParams } from 'features/common/hooks';
import useGlobalDialog from 'features/common/hooks/useGlobalDialog';
import WidePopup from 'features/common/modal/WidePopup';
import { PLACEHOLDER_VALUE } from 'features/common/select/Select';
import { nls } from 'shared/locale/language';
import { useParticipantMutation } from 'shared/query/participant/useParticipantQuery';
import React, { useEffect, useState } from 'react';
import { ProjectUser } from 'stores/data/types';
import styled from 'styled-components';
import Footer from './Footer';
import GradeList from './GradeList';
import Inputs from './Inputs';
import InviteeQueue from './InviteeQueue';
import { validateEmail, validateGrade } from './validators';

interface Props {
  open: boolean;
  onClose: () => void;
  invitees: ProjectUser[];
  projectUsers: ProjectUser[];
}

const gradesToDisplay = [memberPermission.MANAGER, memberPermission.USER, memberPermission.GUEST];

export default function InvitationDialog({ open, onClose, invitees, projectUsers }: Props) {
  const { projectId } = useMatchParams();
  const { inviteParticipant } = useParticipantMutation();
  const { showConfirm } = useGlobalDialog();

  const [values, setValues] = useState({ email: '', grade: PLACEHOLDER_VALUE });
  const [errors, setErrors] = useState({ email: '', grade: '' });
  const [tempInvitees, setTempInvitees] = useState<typeof values[]>([]);
  const [dirty, setDirty] = useState(false);
  const { status } = inviteParticipant;

  useEffect(() => {
    !open && clear();
  }, [open]);

  useEffect(() => {
    const anyInputTyped = Object.values(values).some((x) => x && x !== PLACEHOLDER_VALUE);
    const anyInviteeAdded = tempInvitees.length > 0;
    setDirty(anyInputTyped || anyInviteeAdded);
  }, [values, tempInvitees]);

  function onValueChange(key: keyof typeof values, value: string) {
    setValues((prev) => ({
      ...prev,
      [key]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [key]: '',
    }));
  }

  function onAddClick() {
    const newErrors = {
      email: validateEmail(values.email, {
        tentativeInvitees: tempInvitees,
        invitees,
        projectUsers,
      }),
      grade: validateGrade(values.grade),
    };
    if (newErrors.email || newErrors.grade) {
      setErrors(newErrors);
      return;
    }
    setTempInvitees((prev) => [...prev, values]);
    setValues({ email: '', grade: PLACEHOLDER_VALUE });
  }

  function onGradeChange(email: string, grade: string) {
    setTempInvitees((prev) => prev.map((x) => (x.email === email ? { ...x, grade } : x)));
  }
  function onDeleteClick(email: string) {
    setTempInvitees((prev) => prev.filter((x) => x.email !== email));
  }

  function onInviteClick() {
    inviteParticipant.mutate(
      {
        projectId,
        data: {
          projectUsers:
            tempInvitees?.map((x) => ({
              email: x.email,
              permission: x.grade,
            })) || [],
        },
      },
      {
        onSuccess: () => {
          clear();
        },
      },
    );
  }
  function onCloseClick() {
    if (dirty) {
      showConfirm({
        title: nls.titleExitWithoutSaving(),
        content: nls.exitWithoutSaving(),
        primaryButtonProps: {
          title: nls.confirm(),
          onClick: onClose,
        },
      });
      return;
    }
    onClose();
  }

  function clear() {
    setValues({ email: '', grade: PLACEHOLDER_VALUE });
    setErrors({ email: '', grade: '' });
    setTempInvitees([]);
    setDirty(false);
  }

  const disableSend = tempInvitees.length === 0;
  const disableButtons = status === 'loading';
  const styleOptions = { divider: false };
  return (
    <WidePopup
      isShowing={open}
      closeWidePopup={onCloseClick}
      disableClose={disableButtons}
      title={nls.inviteParticipant()}
      styleOptions={styleOptions}
    >
      <Content>
        <GradeList grades={gradesToDisplay} />
        <Inputs
          gradeOptions={gradesToDisplay}
          values={values}
          errors={errors}
          onValueChange={onValueChange}
          onAddClick={onAddClick}
          disableAdd={disableButtons}
        />
        <InviteeQueue
          data={tempInvitees}
          onGradeChange={onGradeChange}
          onDeleteClick={onDeleteClick}
          gradeOptions={gradesToDisplay}
          loading={status === 'loading'}
        />
        <Footer
          status={status}
          onInviteClick={onInviteClick}
          disableSend={disableSend}
          loading={disableButtons}
        />
        <ConfirmWithoutSaving shouldConfirm={status === 'loading' || dirty} />
      </Content>
    </WidePopup>
  );
}

const Content = styled.div`
  padding-bottom: 3rem;
  padding-left: 4rem;
  padding-right: 4rem;
`;
