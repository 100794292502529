import React, { lazy } from 'react';
const ProjectLogListPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ProjectLogListPage" */
      'features/green/maintenanceLogPage/ProjectLogListPage'
    ),
);

function ProjectLogManipulateList() {
  return <ProjectLogListPage />;
}

export default ProjectLogManipulateList;
