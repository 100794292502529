import axios, { AxiosError, CancelToken } from 'axios';
import { DEFAULT_QUERY_STALE_TIME } from 'shared/common/policies/request';
import { Error } from 'shared/common/types';
import { requestUploadFile, s3DirectFileUpload } from 'shared/query/fileUpload/s3Upload';
import { S3FileUploadType } from 'shared/query/fileUpload/types';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import api from './api';
import { keys } from './queryKeys';
import { BirdsEyeView } from './types';

export function useBirdsEyeViewListQuery(
  { zoneId }: Parameters<typeof api.list>[0],
  options?: UseQueryOptions<BirdsEyeView[], AxiosError<Error>>,
) {
  const { enabled = true, ...rest } = options || {};
  return useQuery(keys.list(zoneId), () => api.list({ zoneId }), {
    staleTime: DEFAULT_QUERY_STALE_TIME,
    enabled: Boolean(zoneId && enabled),
    ...rest,
  });
}

export function useBirdsEyeViewQuery(
  { zoneId, id }: Parameters<typeof api.read>[0],
  options?: UseQueryOptions<BirdsEyeView, AxiosError<Error>>,
) {
  const { enabled = true, ...rest } = options || {};
  return useQuery(keys.detail(zoneId, id), () => api.read({ zoneId, id }), {
    staleTime: DEFAULT_QUERY_STALE_TIME,
    enabled: Boolean(zoneId && id && enabled),
    ...rest,
  });
}

export function useBirdsEyeViewMutation({ zoneId }: { zoneId: number }) {
  const queryClient = useQueryClient();

  const createBirdsEyeView = useMutation(
    async ({ data }: { data: BirdsEyeView }) => {
      const file = data?.file;
      const { data: dataForUploads } = await requestUploadFile({
        name: file?.name,
        size: file?.size,
      });
      const { fields } = dataForUploads as S3FileUploadType;
      await s3DirectFileUpload(dataForUploads, file);
      const payload = {
        type: 'BIRDS_EYE_VIEW' as const,
        name: file?.name,
        filename: fields?.key || fields?.Key,
        takeDate: data?.takeDate,
        description: data?.description ?? '',
      };
      const resource = await api.create({ zoneId, data: payload });
      return resource;
    },
    {
      onSuccess: () => queryClient.invalidateQueries(keys.list(zoneId)),
    },
  );

  const updateBirdsEyeView = useMutation(
    ({ id, data }: Omit<Parameters<typeof api.update>[0], 'zoneId'>) =>
      api.update({ zoneId, id, data }),
    {
      onSuccess: () => queryClient.invalidateQueries(keys.list(zoneId)),
    },
  );

  const deleteBirdsEyeView = useMutation(
    ({ id }: Omit<Parameters<typeof api.delete>[0], 'zoneId'>) => api.delete({ zoneId, id }),
    { onSuccess: () => queryClient.invalidateQueries(keys.list(zoneId)) },
  );

  return { createBirdsEyeView, updateBirdsEyeView, deleteBirdsEyeView };
}
