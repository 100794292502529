import React, { lazy } from 'react';
const PhotoBoxMediaPage = lazy(
  () =>
    import(
      /* webpackChunkName: "PhotoBoxMediaPage" */
      'features/photoBox/photoBoxPage/PhotoBoxMediaPage'
    ),
);

function PhotoBoxMedia() {
  return <PhotoBoxMediaPage />;
}

export default PhotoBoxMedia;
