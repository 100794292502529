import { ProjectPagination, ProjectPaginationParams } from 'features/genaral/project/types';
import { useQuery } from 'react-query';
import { DEFAULT_QUERY_STALE_TIME } from 'shared/common/policies/request';
import api from './api';
import { keys } from './queryKeys';

export default function useProjectListQuery({
  searchKeyword,
  order,
  page,
}: ProjectPaginationParams) {
  return useQuery<ProjectPagination>(
    keys.list(searchKeyword, order, page),
    () =>
      api.list({
        searchKeyword,
        order,
        page,
      }),
    {
      staleTime: DEFAULT_QUERY_STALE_TIME,
      select: (data) => ({
        result: {
          projects: data.result.projects.filter((v) => v?.permissionInfo?.projectGet),
        },
        meta: data.meta,
      }),
    },
  );
}
