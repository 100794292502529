import React, { lazy } from 'react';
const ProjectVehicleHistoryPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ProjectVehicleHistoryPage" */ 'features/project/vehicleHistoryPage/ProjectVehicleHistoryPage'
    ),
);

function VehicleHistory() {
  return <ProjectVehicleHistoryPage />;
}

export default VehicleHistory;
