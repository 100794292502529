import { apiAxios } from 'shared/common/api/common';
import { PermissionType } from 'shared/common/types';

export default {
  async update({
    projectId,
    inviteeId,
    data,
  }: {
    projectId: number;
    inviteeId: number;
    data: { permission: PermissionType };
  }) {
    const response = await apiAxios.patch(`/api/projects/${projectId}/invites/${inviteeId}`, data);
    return response?.data;
  },
};
