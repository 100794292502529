import React, { lazy } from 'react';
const BuildingDetailsPage = lazy(
  () =>
    import(/* webpackChunkName: "BuildingDetailsPage" */ 'features/building/BuildingDetailsPage'),
);

function BuildingDetailList() {
  return <BuildingDetailsPage />;
}

export default BuildingDetailList;
