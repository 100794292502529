import { useMatchParams } from 'features/common/hooks';
import { latLngBounds } from 'leaflet';
import { useZoneListQuery } from 'shared/query';
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

function MapEventHandlers() {
  const map = useMap();
  const { projectId } = useMatchParams();
  const { data } = useZoneListQuery({ projectId });

  const waitingOrthophoto = !data || data?.every((zone) => !zone.latestSnapshot);
  if (waitingOrthophoto) return null;

  const lastOrthophotoIds = data.map((z) => z.latestSnapshot?.id || 0);
  const recentOrthophotoId = Math.max(...lastOrthophotoIds);
  const recentOrthophotoData = data.find((z) => z.latestSnapshot?.id === recentOrthophotoId);

  const boundBox = recentOrthophotoData.latestSnapshot.conversionData.meta.tileMap.boundingBox;

  useEffect(() => {
    if (!data) return;
    map.flyToBounds(
      latLngBounds([+boundBox?.maxy, +boundBox?.maxx], [+boundBox?.miny, +boundBox?.minx])?.pad(2),
      { animate: false },
    );
  }, [data]);

  return null;
}

export default MapEventHandlers;
