import React, { lazy } from 'react';
const MiniMapSettingPage = lazy(
  () =>
    import(/* webpackChunkName: "MiniMapSettingPage" */ 'features/building/MiniMapSettingPage'),
);

function MiniMapSetting() {
  return <MiniMapSettingPage />;
}

export default MiniMapSetting;
