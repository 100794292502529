import React, { FC, useEffect, useState } from 'react';
import {
  DialogActions,
  DialogButton,
  DialogContent,
  DialogContentText,
  DialogWrapper,
} from 'features/styleComponents/dialog/common';
import { nls } from 'shared/locale/language';

export const AlertModal: FC = () => {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState<string>('');

  function handleClose(result: boolean) {
    const resolver = resolverQueue.pop();
    if (resolverQueue.length === 0) {
      setOpen(false);

      if (resolver) {
        const text = extractText(content);

        if (text) {
          delete alreadyMessage[text];
        }

        resolver(result);
      }
    }
  }

  useEffect(() => {
    function handleOpen(alert: string) {
      setOpen(true);
      setContent(alert);
    }

    modalOpener = handleOpen;

    return () => {
      modalOpener = null;
    };
  }, [setOpen]);

  return (
    <DialogWrapper open={open} onClose={handleClose}>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <DialogButton color="primary" onClick={() => handleClose(false)}>
          {nls.confirm()}
        </DialogButton>
      </DialogActions>
    </DialogWrapper>
  );
};

function isString(val: unknown): val is string {
  return Object.prototype.toString.call(val) === '[object String]';
}
let modalOpener: ((alert: string) => void) | null = null;
/**
 * Todo 기존 GlobalDialog 를 대신 할 경우 큐 로직을 공통으로 분리 해야 합니다.
 */
const resolverQueue: Array<(result: boolean) => void> = [];
const alreadyMessage: Record<string, boolean> = {};

function extractText(alert: string) {
  let text = '';

  if (isString(alert)) {
    text = alert;
  }

  return text;
}

/**
 * Todo openAlert 비슷한 기능들이 생성되면 한 파일에서 관리 한다.
 * @param alert
 */
export function openAlert(alert: string) {
  return new Promise<boolean>((resolve) => {
    const text = extractText(alert);
    if (text && alreadyMessage[text]) {
      resolve(true);

      return;
    }
    resolverQueue.push(resolve);
    if (modalOpener) {
      if (isString(alert)) {
        alreadyMessage[alert] = true;
        modalOpener(alert);
      }
    }
  });
}
