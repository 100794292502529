import { Divider, IconButton } from '@material-ui/core';
import Close from '@mui/icons-material/Close';
import { nls } from 'shared/locale/language';
import React from 'react';
import styled from 'styled-components';
import theme from 'shared/styles/mui/theme';
import {
  DialogActions,
  DialogButton,
  DialogContent,
  DialogTitle,
  DialogTitleText,
  DialogWrapper,
} from './common';
import { DialogProps } from './types';

export default function ListPopup({
  title,
  primaryButtonProps,
  secondaryButtonProps,
  children,
  ...rest
}: Omit<DialogProps, 'type'>) {
  const {
    title: primaryButtonTitle,
    color: primaryButtonColor,
    ...restPrimaryButtonProps
  } = primaryButtonProps || {};
  const {
    title: secondaryButtonTitle,
    color: secondaryButtonColor,
    ...restSecondaryButtonProps
  } = secondaryButtonProps || {};
  return (
    <DialogWrapper {...rest}>
      <DialogTitle>
        <DialogTitleText>{title}</DialogTitleText>
        <CloseIconButton onClick={rest.onClose}>
          <Close />
        </CloseIconButton>
      </DialogTitle>
      <StyledDivider />
      <DialogListContent>{children}</DialogListContent>
      <StyledDivider />
      <DialogActions>
        <DialogButton color={secondaryButtonColor || 'inherit'} {...restSecondaryButtonProps}>
          {secondaryButtonTitle || nls.cancel()}
        </DialogButton>
        <DialogButton color={primaryButtonColor || 'primary'} {...restPrimaryButtonProps}>
          {primaryButtonTitle || nls.confirm()}
        </DialogButton>
      </DialogActions>
    </DialogWrapper>
  );
}

const CloseIconButton = styled(IconButton)`
  position: absolute;
  right: 1rem;
  top: 0;

  &:focus {
    outline: none;
  }
`;

const StyledDivider = styled(Divider)`
  width: 100%;
  background-color: ${theme.palette.grey[200]};
`;

const DialogListContent = styled(DialogContent)`
  height: 11rem;
  max-height: 11rem;
  overflow-y: auto;
`;
