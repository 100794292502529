import { LocalShipping as VehicleIcon, WatchLater } from '@material-ui/icons';
import InventoryIcon from '@mui/icons-material/Inventory';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import SettingsIcon from '@mui/icons-material/Settings';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { Menu, MenuItem, Typography } from '@mui/material';
import { ContainedButton } from 'features/common/button/ContainedButton';
import { getNewChip } from 'features/common/Chip';
import routes from 'pages/routes';
import React from 'react';
import { useHistory } from 'react-router';
import GA from 'shared/ga';
import {
  PROJECT_FILE_ARCHIVE_MENU,
  PROJECT_MEMBER_SETTING,
  PROJECT_SETTING,
  PROJECT_VEHICLE_HISTORY_MENU,
  PROJECT_WORKER_HISTORY_MENU,
} from 'shared/ga/actions';
import { PROJECT } from 'shared/ga/category';
import { nls } from 'shared/locale/language';
import { info } from 'shared/styles/colors/info';
import { others } from 'shared/styles/colors/others';
import theme from 'shared/styles/mui/theme';
import styled from 'styled-components';

interface Props {
  id: number;
  name: string;
  thumbnailImg: string;
  numberOfSnapshots: number;
  latestSnapshotDate: string;
  description: string;
  selected?: boolean;
  done?: boolean;
  onClick?: () => void;
}
export default function ProjectItem({
  id,
  name,
  thumbnailImg,
  numberOfSnapshots,
  latestSnapshotDate,
  description,
  selected,
  onClick,
  done,
}: Props) {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    e.stopPropagation();
    if (!anchorEl) {
      onClick();
    }
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onProjectInfoMenuItemClick = (e) => {
    e.stopPropagation();
    handleClose();
    history.push(routes.project.info.of({ projectId: id }));
    GA.event(PROJECT, PROJECT_SETTING, 'ProjectList');
  };

  const onParticipantsInfoMenuItemClick = (e) => {
    e.stopPropagation();
    handleClose();
    history.push(routes.project.member.info.of({ projectId: id }));
    GA.event(PROJECT, PROJECT_MEMBER_SETTING, 'ProjectList');
  };

  const onFileArchiveInfoMenuItemClick = (e) => {
    e.stopPropagation();
    handleClose();
    history.push(routes.project.archive.of({ projectId: id }));
    GA.event(PROJECT, PROJECT_FILE_ARCHIVE_MENU, 'ProjectList');
  };

  const onLaborerHistoryMenuItemClick = (e) => {
    e.stopPropagation();
    handleClose();
    history.push(routes.project.laborerHistory.of({ projectId: id }));
    GA.event(PROJECT, PROJECT_WORKER_HISTORY_MENU, 'ProjectList');
  };

  const onVehicleHistoryMenuItemClick = (e) => {
    e.stopPropagation();
    handleClose();
    history.push(routes.project.vehicleHistory.of({ projectId: id }));
    GA.event(PROJECT, PROJECT_VEHICLE_HISTORY_MENU, 'ProjectList');
  };

  /** scale up 관련 로직 */
  // NOTE: 파일 경로에 orthophoto/가 들어가면 썸네일 확대하지 않도록 적용, 추후에 대부분이 조감도로 변경되면 로직 없애기
  // const needToZoom = data?.thumbnailImg?.indexOf('orthophoto/') > -1;
  return (
    <Container selected={selected} onClick={handleClick}>
      {thumbnailImg ? (
        <Thumbnail $scaleUp={false}>
          <img src={thumbnailImg} alt="project-thumbnail" />
        </Thumbnail>
      ) : (
        <Placeholder className="project-thumbnail">
          <PlaceholderIcon />
        </Placeholder>
      )}
      <ItemBody>
        <Typography variant="h6" sx={{ marginBottom: '0.5rem' }}>
          {name}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary" sx={{ marginBottom: '0.25rem' }}>
          {`${nls.projectCardSnapshotNumber()} ${numberOfSnapshots}`}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary" sx={{ marginBottom: '0.75rem' }}>
          {`${nls.projectCardLatestSnapshot()} ${latestSnapshotDate}`}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {`${description || ''}`}
        </Typography>
        <div>
          {done && <DoneChip>{nls.done()}</DoneChip>}
          <MenuIconButton onClick={handleMenuClick}>
            <SettingsIcon />
            {nls.projectSettingButton()}
          </MenuIconButton>
        </div>
      </ItemBody>
      <Menu
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <StyledMenuItem onClick={onProjectInfoMenuItemClick}>
          <SettingsIcon />
          <Typography variant="body1">{nls.projectInfo()}</Typography>
        </StyledMenuItem>
        <StyledMenuItem onClick={onParticipantsInfoMenuItemClick}>
          <SupervisorAccountIcon />
          <Typography variant="body1">{nls.participantsInfo()}</Typography>
        </StyledMenuItem>
        <StyledMenuItem onClick={onFileArchiveInfoMenuItemClick}>
          <InventoryIcon />
          <Typography variant="body1">{nls.fileStorage()}</Typography>
        </StyledMenuItem>
        <StyledMenuItem onClick={onLaborerHistoryMenuItemClick}>
          <WatchLater />
          <Typography variant="body1">{nls.laborerHistory()}</Typography>
          <span className="new" />
        </StyledMenuItem>
        <StyledMenuItem onClick={onVehicleHistoryMenuItemClick}>
          <VehicleIcon />
          <Typography variant="body1">{nls.vehicleHistory()}</Typography>
          <span className="new" />
        </StyledMenuItem>
      </Menu>
    </Container>
  );
}

const Container = styled.div<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 15.625rem;
  padding: 0.75rem;
  gap: 1rem;
  border-radius: 0.5rem;
  border: 1px solid ${others.BORDER};
  background: ${theme.palette.background.paper};
  box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  :hover:not(:has(button:hover)) {
    border-radius: 0.5rem;
    border: 1px solid ${others.BORDER};
    background: ${theme.palette.grey[300]};
  }
  ${({ selected }) =>
    selected &&
    `
    border-radius: 0.5rem;
    border: 1px solid ${theme.palette.primary.main};
    background: ${info.LIGHT_BG};
    :hover:not(:has(button:hover)) {
      border-radius: 0.5rem;
      border: 1px solid ${theme.palette.primary.main};
      background: ${info.LIGHT_BG};
    }
  `}
`;

const Thumbnail = styled.div<{ $scaleUp: boolean }>`
  width: 14.125rem;
  height: 14.125rem;
  border-radius: 0.3125rem;
  background-color: ${theme.palette.action.disabledBackground};
  overflow: hidden;
  img {
    width: 14.125rem;
    height: 14.125rem;
    min-width: 14.125rem;
    min-height: 14.125rem;
    object-fit: cover;
    backface-visibility: hidden;
    ${({ $scaleUp }) => $scaleUp && 'transform: scale(1.3);'};
  }
`;

const Placeholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14.125rem;
  height: 14.125rem;
  border-radius: 0.3125rem;
  background-color: ${theme.palette.action.disabledBackground};
  overflow: hidden;
`;

const ItemBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;

  > div:last-child {
    display: flex;
    margin-top: auto;
  }
`;

const DoneChip = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.3125rem;
  height: 1.375rem;
  border-radius: 0.25rem;
  border: 1px solid ${theme.palette.text.disabled};
  background: ${theme.palette.grey[600]};
  color: ${theme.palette.background.paper};
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  margin-top: auto;
`;

const MenuIconButton = styled(ContainedButton).attrs({ color: 'default', fullWidth: false })`
  padding: 0.375rem 1rem;

  &:focus {
    outline: none;
  }

  &.MuiButtonBase-root {
    margin-left: auto;
  }

  svg {
    color: ${theme.palette.text.secondary};
    margin-right: 0.5rem;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  .MuiSvgIcon-root {
    color: ${theme.palette.text.secondary};
  }
  ${getNewChip()}
`;

const PlaceholderIcon = styled(PhotoLibraryIcon)`
  &.MuiSvgIcon-root {
    color: ${theme.palette.action.disabledBackground};
    width: 6.6rem;
    height: 6.6rem;
  }
`;
