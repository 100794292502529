import { apiAxios } from 'shared/common/api/common';
import {
  Project,
  ProjectPagination,
  ProjectPaginationParams,
  ProjectUnit,
  ProjectWithOrganization,
} from '../types';

export default {
  async create({ data }: { data: Partial<Project> }) {
    const response = await apiAxios.post(`/api/v2/projects`, data);
    return response.data;
  },
  async read({ projectId }: { projectId: number }) {
    const response = await apiAxios.get(`/api/v2/projects/${projectId}`);
    return response.data;
  },
  async update({ projectId, data }: { projectId: number; data: Partial<Project> }) {
    const response = await apiAxios.patch(`/api/v2/projects/${projectId}`, data);
    return response.data;
  },
  async delete({ projectId }: { projectId: number }) {
    const response = await apiAxios.delete(`/api/v2/projects/${projectId}`);
    return response.data;
  },
  /** @todo limit 없애려면 값을 무엇으로 넣어야하는지 백엔드에 물어보고 수정 */
  async list({ searchKeyword, order = 'CONSTRUCT_DATE_DESC', page }: ProjectPaginationParams) {
    const response = await apiAxios.get<ProjectPagination>('/api/v4/projects', {
      params: {
        limit: 500,
        name: searchKeyword,
        order,
        page,
      },
    });
    return response.data;
  },
  async units({ projectId }: { projectId: number }) {
    const response = await apiAxios.get<{ results: ProjectUnit[] }>(
      `/api/v3/projects/${projectId}/units`,
    );
    return response.data?.results;
  },
  async unitCreate({ projectId, data }: { projectId: number; data: any }) {
    const response = await apiAxios.post(`/api/v3/projects/${projectId}/units`, data);
    return response.data;
  },
  async unitDelete({ projectId, unitId }: { projectId: number; unitId: number }) {
    const response = await apiAxios.delete(`/api/v3/projects/${projectId}/units/${unitId}`);
    return response.data;
  },

  organization: {
    async list({ ancestorFolderId, organizationId }) {
      const response = await apiAxios.get<{ result: ProjectWithOrganization }>('/api/v4/project', {
        params: {
          ancestor_folder_id: ancestorFolderId,
          organization_id: organizationId,
        },
      });
      return response.data.result;
    },
    async createFolder(data: { organizationId: number; name: string; parentFolderId?: number }) {
      const response = await apiAxios.post(`/api/v4/folder/organization`, data);
      return response.data;
    },
    async updateFolder(data: { id: number; name: string }) {
      const response = await apiAxios.put(`/api/v4/folder/${data.id}/organization`, data);
      return response.data;
    },
    async ungroupFolder({ folderId }: { folderId: number }) {
      const response = await apiAxios.post(`/api/v4/folder/${folderId}/organization/ungroup`);
      return response.data;
    },
    async deleteFolder({ folderId }: { folderId: number }) {
      const response = await apiAxios.delete(`/api/v4/folder/${folderId}/organization`);
      return response.data;
    },
    async readHierarchy() {
      const response = await apiAxios.get('/api/v4/folder/organization/project/hierarchy');
      return response.data.result;
    },
    async move(data: {
      destinationFolderId: number;
      descendantFolderIdList: number[];
      currentFolderId: number;
      descendantIdList: number[];
    }) {
      const response = await apiAxios.put('/api/v4/folder/organization/structure', data);
      return response.data;
    },
  },
};
