import React, { lazy } from 'react';
const ZoneListPage = lazy(
  () => import(/* webpackChunkName: "zoneList" */ 'features/zone/listPage/ZoneListPage'),
);

function ZoneList() {
  return <ZoneListPage />;
}

export default ZoneList;
