import SvgIcon from '@material-ui/core/SvgIcon';
import { DirectionsBoat, DriveEta, Flight, LocalShipping } from '@material-ui/icons';
import CompareIcon from '@material-ui/icons/Compare';
import EcoIcon from '@material-ui/icons/Eco';
import FlipToFrontIcon from '@material-ui/icons/FlipToFront';
import GrainIcon from '@material-ui/icons/Grain';
import ImageIcon from '@material-ui/icons/Image';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import AerialImageIcon from '@material-ui/icons/PhotoCamera';
import PlaceIcon from '@material-ui/icons/Place';
import StreetviewIcon from '@material-ui/icons/Streetview';
import TerrainIcon from '@material-ui/icons/Terrain';
import DataObjectIcon from '@mui/icons-material/DataObject';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import { ReactComponent as AreaIcon } from 'assets/images/icon_area_small.svg';
import { ReactComponent as BimIcon } from 'assets/images/icon_bim_small.svg';
import { ReactComponent as ChainIcon } from 'assets/images/icon_chain_custom.svg';
import { ReactComponent as DistanceIcon } from 'assets/images/icon_distance_small.svg';
import { ReactComponent as GreenIcon } from 'assets/images/icon_green.svg';
import { ReactComponent as HeightIcon } from 'assets/images/icon_height_small.svg';
import { ReactComponent as ThermalIcon } from 'assets/images/icon_thermostat.svg';
import { ReactComponent as IconCctvCustom } from 'assets/images/iconCctvCustom.svg';
import { ReactComponent as DrawingVectorsIcon } from 'assets/images/iconDrawingPolygonCustom.svg';
import { ReactComponent as GcpIcon } from 'assets/images/iconGcpCustom.svg';
import { ReactComponent as HazardAreaIcon } from 'assets/images/iconHazardArea.svg';
import { ReactComponent as IssueIcon } from 'assets/images/iconIssueCustom.svg';
import { ReactComponent as ObjectDetectionIcon } from 'assets/images/iconObjectDetection.svg';
import { ReactComponent as PanoramaIcon } from 'assets/images/iconPanoramaCustom.svg';
import { ReactComponent as PileIcon } from 'assets/images/iconPileCustom.svg';
import { ReactComponent as PolygonVectorIcon } from 'assets/images/iconPolygonCustom.svg';
import { nls } from 'shared/locale/language';
import { Permission } from 'shared/query/permission/types';
import React from 'react';

/** @todo 도면 면적: polygonVector로 정리 필요.
 *  현재는 polygonVectors로 되어있음.
 */
export type LayerType =
  | 'heights'
  | 'etc'
  | 'issue'
  | 'bulkPolygon'
  | 'thermal'
  | 'green'
  | 'polygons'
  | 'drawingVector'
  | 'polylines'
  | 'points'
  | 'chains'
  | 'drawings'
  | 'planLevel'
  | 'cctv'
  | 'orthophoto'
  | 'dsm'
  | 'dem'
  | 'ndvi'
  | 'ndmi'
  | 'laborer'
  | 'stratums'
  | 'gcps'
  | 'aerialImages'
  | 'cadastral'
  | 'hazardAreas'
  | 'panorama'
  | 'objectDetection'
  | 'plane'
  | 'ship'
  | 'smallVehicle'
  | 'largeVehicle'
  | 'uncategorized'
  | 'changeDetection'
  | 'pile'
  | 'superResolution'
  | 'vehicle'
  | 'pointCloud'
  | 'bim'
  | 'mesh'
  | 'photoBox'
  | 'photoBoxImage'
  | 'photoBoxVideo';
export type LayerIcons = { [layerType in LayerType]?: JSX.Element };
export type LayerNames = { [layerType in LayerType]?: string };
export type LayerAddPermissions = {
  [layerType in Exclude<LayerType, 'cadastral'>]?: keyof Permission;
};

export const layerIcons: LayerIcons = {
  orthophoto: <ImageIcon />,
  dsm: <TerrainIcon />,
  dem: <TerrainIcon />,
  points: <PlaceIcon />,
  polylines: <SvgIcon component={DistanceIcon} />,
  polygons: <SvgIcon component={AreaIcon} />,
  drawings: <FlipToFrontIcon />,
  planLevel: <TerrainIcon />,
  gcps: <SvgIcon component={GcpIcon} />,
  cadastral: <StreetviewIcon />,
  hazardAreas: <SvgIcon component={HazardAreaIcon} />,
  laborer: <PersonPinIcon />,
  pointCloud: <GrainIcon />,
  bim: <SvgIcon component={BimIcon} />,
  heights: <SvgIcon component={HeightIcon} />,
  mesh: <SvgIcon component={BimIcon} />,
  chains: <SvgIcon component={ChainIcon} />,
  stratums: <TerrainIcon />,
  etc: <InsertDriveFile />,
  aerialImages: <AerialImageIcon />,
  cctv: <SvgIcon component={IconCctvCustom} />,
  issue: <SvgIcon component={IssueIcon} viewBox="0 0 20 20" />,
  panorama: <SvgIcon component={PanoramaIcon} viewBox="0 0 24 24" />,
  ndvi: <EcoIcon />,
  ndmi: <WaterDropIcon />,
  drawingVector: <SvgIcon component={DrawingVectorsIcon} fontSize="small" />,
  bulkPolygon: <SvgIcon component={PolygonVectorIcon} fontSize="small" viewBox="0 0 20 20" />,
  thermal: <SvgIcon component={ThermalIcon} viewBox="0 0 20 20" fontSize="small" />,
  green: <SvgIcon component={GreenIcon} viewBox="0 0 20 20" fontSize="small" />,
  objectDetection: <SvgIcon component={ObjectDetectionIcon} width="20" height="20" />,
  plane: <Flight />,
  ship: <DirectionsBoat />,
  smallVehicle: <DriveEta />,
  largeVehicle: <LocalShipping />,
  uncategorized: <DataObjectIcon />,
  changeDetection: <CompareIcon />,
  superResolution: <ImageIcon />,
  vehicle: <SvgIcon component={LocalShipping} />,
  pile: <SvgIcon component={PileIcon} viewBox="0 0 20 20" />,
  photoBox: <PermMediaIcon />,
  photoBoxImage: <ImageIcon />,
  photoBoxVideo: <VideoLibraryIcon />,
};

export const layerNames: LayerNames = {
  orthophoto: nls.layerOrthophotoTitle(),
  dsm: nls.layerDSMTitle(),
  dem: nls.demAccordionTitle(),
  points: nls.layerMarkerTitle(),
  polylines: nls.layerLineTitle(),
  polygons: nls.layerAreaTitle(),
  drawings: nls.layerDrawingTitle(),
  planLevel: nls.layerPlanLevelTitle(),
  gcps: nls.layerGCPTitle(),
  cadastral: nls.layerCadastralTitle(),
  hazardAreas: nls.layerHazardAreaTitle(),
  laborer: nls.layerLaborerTitle(),
  vehicle: nls.layerVehicleTitle(),
  pointCloud: nls.layerPointCloudTitle(),
  bim: nls.layerBimTitle(),
  heights: nls.layerHeightTitle(),
  mesh: nls.layerMeshTitle(),
  chains: nls.layerChainTitle(),
  stratums: nls.layerStratumTitle(),
  etc: nls.layerEtcTitle(),
  aerialImages: nls.layerAerialImageTitle(),
  issue: nls.layerIssueTitle(),
  cctv: nls.layerCctvTitle(),
  panorama: nls.layerPanoramaTitle(),
  ndvi: nls.layerNdviTitle(),
  ndmi: nls.layerNdmiTitle(),
  drawingVector: nls.layerPolygonVector(),
  thermal: nls.layerThermalTitle(),
  green: nls.layerGreenTitle(),
  objectDetection: nls.toolsAIObjectDetectionAccordionTitle(),
  plane: nls.toolsAIObjectDetectionGvienClassPlane(),
  ship: nls.toolsAIObjectDetectionGvienClassShip(),
  smallVehicle: nls.toolsAIObjectDetectionGvienClassSmallVehicle(),
  largeVehicle: nls.toolsAIObjectDetectionGvienClassLargeVehicle(),
  uncategorized: nls.toolsAIObjectDetectionGvienClassUncategorized(),
  changeDetection: nls.toolsAIChangeDetectionAccordionTitle(),
  pile: nls.basicPile(),
  superResolution: nls.superResolutionAccordionTitle(),
  photoBox: nls.photoBox(),
};

export const layerAddPermission: LayerAddPermissions = {
  orthophoto: 'downloadSnapshotFiles', // 날짜 귀속 데이터
  dsm: 'downloadSnapshotFiles', // 날짜 귀속 데이터
  dem: 'downloadSnapshotFiles', // 날짜 귀속 데이터
  pointCloud: 'downloadSnapshotFiles', // 날짜 귀속 데이터
  gcps: 'downloadSnapshotFiles', // 날짜 귀속 데이터
  mesh: 'downloadSnapshotFiles', // 날짜 귀속 데이터
  points: 'annotationPost',
  polylines: 'annotationPost',
  polygons: 'annotationPost',
  chains: 'annotationPost',
  heights: 'annotationPost',
  drawings: 'snapshotfilePost', // 비교데이터
  planLevel: 'snapshotfilePost', // 비교데이터
  bim: 'snapshotfilePost', // 비교데이터
  etc: 'etcResourceGet',
  cctv: null,
  hazardAreas: 'annotationPost',
  issue: 'issuePost',
  panorama: 'snapshotPost',
  ndvi: 'downloadSnapshotFiles', // TODO: 확인 필요
  superResolution: 'downloadSnapshotFiles',
  ndmi: 'downloadSnapshotFiles', // TODO: 확인 필요
  bulkPolygon: 'annotationPost',
};
