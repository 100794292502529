import GA from 'shared/ga';
import * as category from '../category';

export const TWO_D_LINK_DRAWING_LINK_DEL_MENU = `${category.TWO_D_LINK}DrawingLinkDelMenu`;
export const TWO_D_LINK_DRAWING_LINK_DOWN_MENU = `${category.TWO_D_LINK}DrawingLinkDownMenu`;
export const TWO_D_LINK_DRAWING_LINK_TGL = `${category.TWO_D_LINK}DrawingLinkTgl`;
export const TWO_D_LINK_EARTH_LEVEL_LINK_DEL_MENU = `${category.TWO_D_LINK}EarthLevelLinkDelMenu`;
export const TWO_D_LINK_EARTH_LEVEL_LINK_DOWN_MENU = `${category.TWO_D_LINK}EarthLevelLinkDownMenu`;
export const TWO_D_LINK_EARTH_LEVEL_LINK_TGL = `${category.TWO_D_LINK}EarthLevelLinkTgl`;
export const TWO_D_LINK_ETC_DEL_MENU = `${category.TWO_D_LINK}ETCDelMenu`;
export const TWO_D_LINK_ETC_DOWN_MENU = `${category.TWO_D_LINK}ETCDownMenu`;
export const TWO_D_LINK_PLAN_LEVEL_LINK_DEL_MENU = `${category.TWO_D_LINK}PlanLevelLinkDelMenu`;
export const TWO_D_LINK_PLAN_LEVEL_LINK_DOWN_MENU = `${category.TWO_D_LINK}PlanLevelLinkDownMenu`;
export const TWO_D_LINK_PLAN_LEVEL_LINK_TGL = `${category.TWO_D_LINK}PlanLevelLinkTgl`;
export const TWO_D_LINK_POINT_LINK_TGL = `${category.TWO_D_LINK}PointLinkTgl`;
export const TWO_D_LINK_POLYGON_LINK_TGL = `${category.TWO_D_LINK}PolygonLinkTgl`;
export const TWO_D_LINK_POLYLINE_LINK_TGL = `${category.TWO_D_LINK}PolylineLinkTgl`;
export const TWO_D_LINK_EARTHWORK_XING_LINE_LINK_TGL = `${category.TWO_D_LINK}EarthworkXingLineLinkTgl`;
export const TWO_D_LINK_EARTHWORK_XING_LINE_DEL_MENU = `${category.TWO_D_LINK}EarthworkXingLineDelMenu`;
export const TWO_D_LINK_EARTHWORK_XING_LINE_DOWN_MENU = `${category.TWO_D_LINK}EarthworkXingLineDownMenu`;

const TWO_D_LINK_PLAN_LEVEL_APPLY_DATE = `${category.TWO_D_LINK}PlanLevelApplyDate`;
const TWO_D_LINK_PLAN_LEVEL_PANEL_COMPARE_WITH_DSM_TGL = `${category.TWO_D_LINK}PlanLevelPanelCompareWithDSMTgl`;
const TWO_D_LINK_EARTH_LEVEL_PANEL_DEL_BTN = `${category.TWO_D_LINK}EarthLevelPanelDelBtn`;
const TWO_D_LINK_EARTH_LEVEL_PANEL_DOWN_BTN = `${category.TWO_D_LINK}EarthLevelPanelDownBtn`;

export const sendTwoDLinkPlanLevelApplyDate = () =>
  GA.event(category.TWO_D_LINK, TWO_D_LINK_PLAN_LEVEL_APPLY_DATE);
export const sendTwoDLinkPlanLevelPanelCompareWithDSMTgl = () =>
  GA.event(category.TWO_D_LINK, TWO_D_LINK_PLAN_LEVEL_PANEL_COMPARE_WITH_DSM_TGL);
export const sendTwoDLinkEarthLevelPanelDelBtn = () =>
  GA.event(category.TWO_D_LINK, TWO_D_LINK_EARTH_LEVEL_PANEL_DEL_BTN);
export const sendTwoDLinkEarthLevelPanelDownBtn = () =>
  GA.event(category.TWO_D_LINK, TWO_D_LINK_EARTH_LEVEL_PANEL_DOWN_BTN);
