import { apiAxios } from 'shared/common/api/common';

export default {
  create(snapshotId: number) {
    return apiAxios.post(`/api/v3/snapshots/${snapshotId}/watchtoken`);
  },
  read(token: string) {
    return apiAxios.get(`/api/v3/watchtoken/${token}`, { params: { noToken: true } });
  },
  delete(token: string) {
    return apiAxios.delete(`/api/v3/watchtoken/${token}`, { params: { noToken: true } });
  },
};
