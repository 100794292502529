import { TextField, Typography } from '@material-ui/core';
import DatePicker from 'features/common/DatePicker';
import { formatISO } from 'date-fns';
import { nls } from 'shared/locale/language';
import React, { useState } from 'react';
import Confirm from 'features/styleComponents/dialog/Confirm';
import styled from 'styled-components';
import theme from 'shared/styles/mui/theme';

interface Props {
  open: boolean;
  onConfirm: (d: { title: string; description: string; effectiveDate: string }) => void;
  onCancel: () => void;
}
export default function SaveConfirm({ open, onConfirm, onCancel }: Props) {
  const [effectiveDate, setEffectiveDate] = useState<Date | null>(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const primaryButtonProps = {
    title: nls.save(),
    onClick: () => {
      onConfirm({
        title,
        description,
        effectiveDate: formatISO(effectiveDate, { representation: 'date' }),
      });
    },
    disabled: !effectiveDate || !title,
  };

  const secondaryButtonProps = { onClick: onCancel };

  return (
    <Confirm
      open={open}
      title={nls.earthworkAreaPlanVesionSaveDialogTitle()}
      primaryButtonProps={primaryButtonProps}
      secondaryButtonProps={secondaryButtonProps}
    >
      <Content>
        <Section>
          <Label>{nls.basicDateOfApplication()}</Label>
          <DatePicker
            value={effectiveDate}
            onChange={setEffectiveDate}
            placeholder={nls.earthworkAreaPlanVersionSelectDatePlaceholder()}
            width="100%"
            okLabel={nls.basicApply()}
            cancelLabel={''}
          />
        </Section>
        <Section>
          <Label>{nls.basicTitle()}</Label>
          <TextField
            variant="outlined"
            placeholder="ver.2"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            inputProps={{ maxLength: 20 }}
          />
        </Section>
        <Section>
          <Typography variant="subtitle1">{nls.basicDescription()}</Typography>
          <TextField
            variant="outlined"
            placeholder={nls.drawingVectorEditorDescriptionPlaceholderMessage()}
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            multiline
            inputProps={{ maxLength: 50 }}
            rows={2}
          />
        </Section>
      </Content>
    </Confirm>
  );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 0.75rem 2rem;
`;

const Label = styled(Typography).attrs({
  variant: 'subtitle1',
  component: 'span',
})`
  margin-right: 0.5rem;
  &::after {
    content: ' *';
    color: ${theme.palette.error.main};
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
`;
