import { DEFAULT_QUERY_STALE_TIME } from 'shared/common/policies/request';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import api from './api';
import { keys } from './queryKeys';

export function useParticipantListQuery(projectId: number) {
  return useQuery(keys.list(projectId), () => api.list({ projectId }), {
    staleTime: DEFAULT_QUERY_STALE_TIME,
  });
}

export function useParticipantMutation() {
  const queryClient = useQueryClient();
  const deleteParticipant = useMutation(
    (payload: { projectId: number; data: { invites: number[]; projectUsers: number[] } }) =>
      api.delete(payload),
    {
      onSuccess: (
        _,
        variables: { projectId: number; data: { invites: number[]; projectUsers: number[] } },
      ) => queryClient.invalidateQueries(keys.list(variables.projectId)),
    },
  );
  const inviteParticipant = useMutation(
    (payload: {
      projectId: number;
      data: { projectUsers: { email: string; permission: string }[] };
    }) => api.invite(payload),
    {
      onSuccess: (
        _,
        variables: {
          projectId: number;
          data: { projectUsers: { email: string; permission: string }[] };
        },
      ) => queryClient.invalidateQueries(keys.list(variables.projectId)),
    },
  );
  const resendInvitation = useMutation((payload: any) => api.resendInvitation(payload));

  return { deleteParticipant, inviteParticipant, resendInvitation };
}
