import { ProjectWithOrganization } from 'features/genaral/project/types';
import { useQuery } from 'react-query';
import api from './api';
import { keys } from './queryKeys';

export default function useProjectWithOrganizationHierarchyQuery({ enabled }) {
  return useQuery<ProjectWithOrganization>(
    keys.projectWithOrganizationHierarchy(),
    () => api.organization.readHierarchy(),
    { enabled },
  );
}
