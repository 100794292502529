import useDrawingVectorEdit from 'features/snapshot/drawingVectorEditorPage/useDrawingVectorEdit';
import { LatLng } from 'leaflet';
import React, { useEffect, useMemo, useRef } from 'react';
import { LayerGroup, useMap } from 'react-leaflet';
import { getBoundsCenter } from './common/utils';
import CreatingPolygon from './CreatingPolygon';
import Polygon from './Polygon';

export default function DrawingVectorEditorLayer() {
  const map = useMap();
  const isFirst = useRef(true);
  const {
    bulkPolygons,
    selectedBulkPolygonTempId,
    setSelectedBulkPolygonTempId,
    createBulkPolygon,
    updateBulkPolygon,
    createMode,
    setCreateMode,
  } = useDrawingVectorEdit();

  const allPositionsForSnap = useMemo(
    () =>
      (bulkPolygons?.reduce((acc, x) => acc.concat(x.positionList), []) || [])?.map(
        (x) => ({ lat: x.latitude, lng: x.longitude } as LatLng),
      ),
    [bulkPolygons],
  );

  useEffect(() => {
    if (bulkPolygons?.length && isFirst.current) {
      isFirst.current = false;
      const center = getBoundsCenter(
        bulkPolygons?.reduce((acc, x) => acc.concat(x.positionList), []),
      );
      map.setView(center, map.getZoom());
    }
  }, [bulkPolygons]);

  return (
    <LayerGroup key="drawing-vector-editor-layer">
      {createMode && (
        <CreatingPolygon
          onCancel={() => setCreateMode(false)}
          onSubmit={(p: LatLng[]) => createBulkPolygon(p)}
        />
      )}
      {bulkPolygons?.map((x) => (
        <Polygon
          key={x.tempId}
          color={x.color}
          positions={x.positionList?.map(
            ({ latitude, longitude }) =>
              ({
                lat: latitude,
                lng: longitude,
              } as LatLng),
          )}
          onClick={() => setSelectedBulkPolygonTempId(x.tempId)}
          onUpdate={(p: LatLng[]) => updateBulkPolygon(x.tempId, p)}
          tooltipMessage={x?.name}
          focused={selectedBulkPolygonTempId === x.tempId}
          snapPositions={allPositionsForSnap}
        />
      ))}
    </LayerGroup>
  );
}
