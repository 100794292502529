import { useDefaultEventBlocker, useMatchParams } from 'features/common/hooks';
import useGlobalDialog from 'features/common/hooks/useGlobalDialog';
import { projectListStore } from 'features/genaral/project/store/projectListStore';
import { useAtomValue } from 'jotai';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { nls } from 'shared/locale/language';
import { usePermissionQuery, useProjectMutation, useProjectQuery } from 'shared/query';
import ProjectFormPage from './ProjectFormPage';

export default function ProjectFormPageContainer() {
  const history = useHistory();
  const { projectId } = useMatchParams();
  const { showAlert } = useGlobalDialog();
  const { data: permission } = usePermissionQuery(projectId);
  const creating = !isFinite(projectId);
  const project = (!creating && useProjectQuery(projectId)?.data) ?? {};
  const { createProject, updateProject } = useProjectMutation();
  const currentProjectFolderId = useAtomValue(projectListStore.currentProjectFolderId);

  useDefaultEventBlocker(window, 'contextmenu');

  useEffect(() => {
    if (!creating && permission && !permission?.projectPut) {
      showAlert({
        content: nls.noPermissionMsgContent(),
        primaryButtonProps: { onClick: () => history.goBack() },
      });
    }
  }, [permission]);

  const onSubmit = ({ payload, onCreated, onUpdated, onUpdateError }) => {
    if (creating) {
      createProject.mutate(
        {
          data: currentProjectFolderId
            ? { ...payload, ancestorFolderId: currentProjectFolderId }
            : payload,
        },
        { onSuccess: onCreated },
      );
    } else {
      updateProject.mutate(
        { projectId, data: payload },
        { onSuccess: onUpdated, onError: onUpdateError },
      );
    }
  };
  return <ProjectFormPage existing={project} onSubmit={onSubmit} />;
}
