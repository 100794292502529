import MuiDialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import styled, { css } from 'styled-components';
import theme from 'shared/styles/mui/theme';

interface Props {
  isShowing: boolean;
  closeWidePopup: () => void;
  title: string;
  styleOptions?: {
    width?: string;
    divider?: boolean;
  };
  disableClose?: boolean;
  children: React.ReactNode;
}

/**
 * figma에서 와이드 팝업으로 정의하였기 때문에 통일함
 * https://www.figma.com/file/PP9uWWFob8FJNr7H21Hkbx/%F0%9F%96%A5-Platform-3.0?node-id=2391%3A8250
 */
export default function WidePopup(props: Props) {
  const { isShowing, title, closeWidePopup, disableClose = false, styleOptions, children } = props;

  const width = styleOptions?.width ?? '52.5rem';
  const divider = styleOptions?.divider ?? true;

  return (
    <StyledDialog open={isShowing} $width={width} onClose={closeWidePopup}>
      <DialogHeader disableTypography $divider={divider}>
        <Typography variant="h4">{title}</Typography>
        <CloseIconButton
          data-cy="modal-close"
          edge="end"
          onClick={closeWidePopup}
          disabled={disableClose}
        >
          <CloseIcon />
        </CloseIconButton>
      </DialogHeader>
      {children}
    </StyledDialog>
  );
}

const DialogHeader = styled(DialogTitle)<{ $divider?: boolean }>`
  padding: 1.5625rem 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ $divider }) =>
    $divider &&
    css`
      border-bottom: 1px solid ${theme.palette.divider};
    `}
`;

const CloseIconButton = styled(IconButton)`
  &:focus {
    outline: none;
  }
`;

const StyledDialog = styled(MuiDialog)<{ $width: string }>`
  .MuiDialog-paperScrollPaper {
    max-width: ${({ $width }) => $width};
    width: ${({ $width }) => $width};
    padding-top: 1.4375rem;
    border-radius: 1rem;
  }
`;
