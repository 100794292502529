import React, { lazy } from 'react';
const SnapshotDetailsContainer = lazy(
  () =>
    import(
      /* webpackChunkName: "SnapshotDetailsContainer" */ 'features/snapshot/detailsPage/SnapshotDetailsContainer'
    ),
);

function ChainList() {
  return <SnapshotDetailsContainer />;
}

export default ChainList;
