import React, { lazy } from 'react';
const JoinPage = lazy(
  () => import(/* webpackChunkName: "joinPage" */ 'features/user/joinPage/JoinPage'),
);

function Join() {
  return <JoinPage />;
}

export default Join;
