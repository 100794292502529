import { ANNOTATION_POLYGON_MAX_VERTEX } from 'shared/common/policies/2d';
import L, { DomEvent, LatLng } from 'leaflet';
import { nls } from 'shared/locale/language';
import React, { useEffect, useRef, useState } from 'react';
import { Polygon as ReactLeafletPolygon, Tooltip } from 'react-leaflet';
import { useRouteMatch } from 'react-router';
import routes from 'pages/routes';
import AnnotationEditingTools from './common/AnnotationEditingTools';

interface Props {
  color: string;
  positions: LatLng[];
  focused?: boolean;
  onClick?: () => void;
  onUpdate?: (positions: LatLng[], onError?) => void;
  disabledTooltip?: boolean;
  disabledEdit?: boolean;
  tooltipMessage?: string;
  snapPositions?: LatLng[];
}

export default function Polygon({
  positions,
  color,
  focused,
  onClick,
  onUpdate,
  disabledTooltip,
  disabledEdit,
  tooltipMessage,
  snapPositions,
}: Props) {
  if (!positions) return null;
  const ref = useRef<L.Polygon>(null);

  const match = useRouteMatch();
  const isPolygonVectorEdit = match.path === routes.polygonAnnotation.edit.path;
  const editMode = focused && !disabledEdit;

  const [editingPositions, setEditingPositions] = useState<LatLng[]>([...positions]);
  const disabledAddPoint = editingPositions.length >= ANNOTATION_POLYGON_MAX_VERTEX;

  useEffect(() => {
    if (!ref?.current) return;
    onClickCancel();
  }, [focused]);

  useEffect(() => {
    if (!ref?.current) return;
    ref.current.setStyle({
      color,
    });
  }, [color]);

  function handleClick(e) {
    DomEvent.stopPropagation(e);
    onClick?.();
  }

  function onClickCancel() {
    setEditingPositions([...positions]);
  }

  function onClickSave(onError?) {
    onUpdate(editingPositions, onError);
  }

  const showTooltip = !disabledTooltip && (!focused || disabledEdit);

  const polygonSnackbarMessage = {
    default: '',
    dirty: disabledAddPoint
      ? nls.polygonMaxVertexErrorMessage()
      : nls.annotationEditSnackbarMessage(),
  };
  const polygonVectorSnackbarMessage = {
    default: nls.editingPolygonVectorMessage(),
    dirty: disabledAddPoint
      ? nls.polygonMaxVertexErrorMessage()
      : nls.editingPolygonVectorMessage(),
  };

  const snackbarMessage =
    match.path === routes.polygonAnnotation.edit.path
      ? polygonVectorSnackbarMessage
      : polygonSnackbarMessage;

  const showSnackbar = isPolygonVectorEdit ? editMode : false;

  return (
    <>
      <ReactLeafletPolygon
        ref={ref}
        positions={editingPositions}
        eventHandlers={{
          click: handleClick,
          add: () => {
            ref.current.setStyle({
              color,
            });
          },
        }}
      >
        {showTooltip && (
          <Tooltip permanent key={tooltipMessage} interactive>
            {tooltipMessage}
          </Tooltip>
        )}
      </ReactLeafletPolygon>
      {editMode && (
        <AnnotationEditingTools
          color={color}
          originalPositions={positions}
          positions={editingPositions}
          setPositions={setEditingPositions}
          getObjectLatLngs={() => ref.current.getLatLngs()?.[0] as LatLng[]}
          setObjectLatLngs={(latLngs) => {
            ref.current.setLatLngs([latLngs]);
          }}
          setObjectStyle={(style) => {
            ref.current.setStyle(style);
          }}
          snapPositions={snapPositions}
          snackbarMessage={snackbarMessage}
          showSnackbar={showSnackbar}
          onCancel={onClickCancel}
          onSave={onClickSave}
          disabledAddPoint={disabledAddPoint}
        />
      )}
    </>
  );
}
