import React, { lazy } from 'react';
const CompareSnapshotsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "compareSnapshotsPage" */ 'features/snapshot/comparePage/CompareSnapshotsPage'
    ),
);

function CompareTwo(props) {
  return <CompareSnapshotsPage type="two" {...props} />;
}

export default CompareTwo;
