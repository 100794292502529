import React, { lazy } from 'react';
const CompareSnapshotsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "compareSnapshotsPage" */ 'features/snapshot/comparePage/CompareSnapshotsPage'
    ),
);

function CompareFour(props) {
  return <CompareSnapshotsPage type="four" {...props} />;
}

export default CompareFour;
