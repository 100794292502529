import { nls } from 'shared/locale/language';
import { Project } from 'stores/data/types';

export type SortOrder = {
  id: string;
  readableLabel: string;
  apiLabel: string;
  compare(x: Project, y: Project): number;
};

export const sortOrderTypes = {
  constructionDateDesc: {
    id: 'constructionDateDesc',
    readableLabel: nls.sortOrderConstructionDateDesc(),
    apiLabel: 'CONSTRUCT_DATE_DESC',
    compare: (x: Project, y: Project) => -x.constructionDate.localeCompare(y.constructionDate),
  },
  constructionDateAsc: {
    id: 'constructionDateAsc',
    readableLabel: nls.sortOrderConstructionDateAsc(),
    apiLabel: 'CONSTRUCT_DATE_ASC',
    compare: (x: Project, y: Project) => +x.constructionDate.localeCompare(y.constructionDate),
  },
  nameAsc: {
    id: 'nameAsc',
    readableLabel: nls.sortOrderNameAsc(),
    apiLabel: 'NAME_ASC',
    compare: (x: Project, y: Project) => +x.name.localeCompare(y.name),
  },
  nameDesc: {
    id: 'nameDesc',
    readableLabel: nls.sortOrderNameDesc(),
    apiLabel: 'NAME_DESC',
    compare: (x: Project, y: Project) => -x.name.localeCompare(y.name),
  },
};
