import GA from 'shared/ga';
import { getCheckboxLabel } from 'shared/ga/label';
import { IssueType } from 'shared/query/issue/types';
import * as category from '../category';

export const TWO_D_CURRENT_CADASTER_COMP_DONE_VIEW_CBOX = `${category.TWO_D_CURRENT}CadasterCompDoneViewCbox`;
export const TWO_D_CURRENT_CADASTER_COMP_PENDING_VIEW_CBOX = `${category.TWO_D_CURRENT}CadasterCompPendingViewCbox`;
export const TWO_D_CURRENT_CADASTER_COMP_REQUIRED_VIEW_CBOX = `${category.TWO_D_CURRENT}CadasterCompRequiredViewCbox`;
export const TWO_D_CURRENT_CADASTER_COMP_STATUS_MENU = `${category.TWO_D_CURRENT}CadasterCompStatusMenu`;
export const TWO_D_CURRENT_CADASTER_LIST = `${category.TWO_D_CURRENT}CadasterList`;
export const TWO_D_CURRENT_CADASTER_VIEW_ALL_CBOX = `${category.TWO_D_CURRENT}CadasterViewAllCbox`;

export const TWO_D_CURRENT_MANAGER_LOCA_VIEW_CBOX = `${category.TWO_D_CURRENT}ManagerLocaViewCbox`;
export const TWO_D_CURRENT_WORKER_LOCA_VIEW_CBOX = `${category.TWO_D_CURRENT}WorkerLocaViewCbox`;
export const TWO_D_CURRENT_WORKER_LOCA_BOUNDARY_TGL = `${category.TWO_D_CURRENT}WorkerLocaBoundaryTgl`;
export const TWO_D_CURRENT_VEHICLE_LOCA_VIEW_CBOX = `${category.TWO_D_CURRENT}VehicleLocaViewCbox`;
export const TWO_D_CURRENT_VEHICLE_LOCA_DETAIL_BTN = `${category.TWO_D_CURRENT}VehicleLocaDetailBtn`;

export const TWO_D_CURRENT_CCTV_VIEW_CBOX = `${category.TWO_D_CURRENT}CCTVViewCbox`;
export const TWO_D_CURRENT_CCTV_LIST_ITEM = `${category.TWO_D_CURRENT}CCTVListItem`;
export const TWO_D_CURRENT_CCTV_MARKER = `${category.TWO_D_CURRENT}CCTVMarker`;
export const TWO_D_CURRENT_CCTV_FULL_SCREEN_BTN = `${category.TWO_D_CURRENT}CCTVFullScreenBtn`;
export const TWO_D_CURRENT_CCTV_ANOTHER_CCTV = `${category.TWO_D_CURRENT}CCTVAnotherCctv`;

export const TWO_D_CURRENT_HAZARD_AREA_VIEW_ALL_CBOX = `${category.TWO_D_CURRENT}HazardAreaViewAllCbox`;
export const TWO_D_CURRENT_HAZARD_AREA_VIEW_CBOX = `${category.TWO_D_CURRENT}HazardAreaViewCbox`;
export const TWO_D_CURRENT_HAZARD_AREA_HISTORY_BTN = `${category.TWO_D_CURRENT}HazardAreaHistoryBtn`;
export const TWO_D_CURRENT_HAZARD_AREA_HISTORY_DATE = `${category.TWO_D_CURRENT}HazardAreaHistoryDate`;
export const TWO_D_CURRENT_HAZARD_AREA_HISTORY_SEL = `${category.TWO_D_CURRENT}HazardAreaHistorySel`;
export const TWO_D_CURRENT_HAZARD_AREA_HISTORY_ALL = `${category.TWO_D_CURRENT}HazardAreaHistoryAll`;
export const TWO_D_CURRENT_HAZARD_AREA_HISTORY_HAZARD = `${category.TWO_D_CURRENT}HazardAreaHistoryHazard`;
export const TWO_D_CURRENT_HAZARD_AREA_HISTORY_SAFETY = `${category.TWO_D_CURRENT}HazardAreaHistorySafety`;
export const TWO_D_CURRENT_HAZARD_AREA_HISTORY_RUN_OUT = `${category.TWO_D_CURRENT}HazardAreaHistoryRunOut`;
export const TWO_D_CURRENT_HAZARD_AREA_HISTORY_CREATE = `${category.TWO_D_CURRENT}HazardAreaHistoryCreate`;

export const TWO_D_CURRENT_ISSUE_VIEW_ALL_CBOX = `${category.TWO_D_CURRENT}IssueViewAllCbox`;
export const TWO_D_CURRENT_ISSUE_LIST_ITEM = `${category.TWO_D_CURRENT}IssueListItem`;
export const TWO_D_CURRENT_ISSUE_ADD_BTN = `${category.TWO_D_CURRENT}IssueAddBtn`;
export const TWO_D_CURRENT_ISSUE_ADD_MAIN_IMAGE = `${category.TWO_D_CURRENT}IssueAddMainImage`;
export const TWO_D_CURRENT_ISSUE_SAVE = `${category.TWO_D_CURRENT}IssueSave`;
export const TWO_D_CURRENT_ISSUE_EDIT_MENU = `${category.TWO_D_CURRENT}IssueEditMenu`;
export const TWO_D_CURRENT_ISSUE_EDIT_MENU_ADD_MAIN_IMAGE = `${category.TWO_D_CURRENT}IssueEditMenuAddMainImage`;
export const TWO_D_CURRENT_ISSUE_EDIT_MENU_SAVE = `${category.TWO_D_CURRENT}IssueEditMenuSave`;
export const TWO_D_CURRENT_ISSUE_EDIT_MENU_DEL_BTN = `${category.TWO_D_CURRENT}IssueEditMenuDelBtn`;
export const TWO_D_CURRENT_ISSUE_NDVI_CAL_BTN = `${category.TWO_D_CURRENT}IssueNdviCalBtn`;
export const TWO_D_CURRENT_ISSUE_NDVI_COMPARE_BTN = `${category.TWO_D_CURRENT}IssueNdviCompareBtn`;
export const TWO_D_CURRENT_ISSUE_SHARE_BTN = `${category.TWO_D_CURRENT}2DCurrentIssueShareBtn`;
export const TWO_D_CURRENT_ISSUE_SHARE_KAKAO_BTN = `${category.TWO_D_CURRENT}2DCurrentIssueShareKakaoBtn`;
export const TWO_D_CURRENT_ISSUE_SHARE_COPYLINK_BTN = `${category.TWO_D_CURRENT}2DCurrentIssueShareCopylinkBtn`;

export const TWO_D_CURRENT_PANORAMA_CBOX = `${category.TWO_D_CURRENT}PanoramaCbox`;
const TWO_D_CURRENT_PANORAMA_FULL_SCREEN_BTN = `${category.TWO_D_CURRENT}PanoramaFullScreenBtn`;
const TWO_D_CURRENT_PANORAMA_DOWN_BTN_IN_PLAYER = `${category.TWO_D_CURRENT}PanoramaDownBtnInPlayer`;
const TWO_D_CURRENT_PANORAMA_MOVE_BTN_IN_PLAYER = `${category.TWO_D_CURRENT}PanoramaMoveBtnInPlayer`;

export const sendEventForTwoDCurrent = (action: string, label?: string) => {
  GA.event(category.TWO_D_CURRENT, action, label);
};
export const sendTwoDCurrentPanoramaDownBtnInPlayer = () => {
  sendEventForTwoDCurrent(TWO_D_CURRENT_PANORAMA_DOWN_BTN_IN_PLAYER);
};
export const sendTwoDCurrentPanoramaMoveBtnInPlayer = () => {
  sendEventForTwoDCurrent(TWO_D_CURRENT_PANORAMA_MOVE_BTN_IN_PLAYER);
};
export const sendTwoDCurrentPanoramaFullScreenBtn = () => {
  sendEventForTwoDCurrent(TWO_D_CURRENT_PANORAMA_FULL_SCREEN_BTN);
};
export const sendTwoDCurrentIssueListItem = () => {
  sendEventForTwoDCurrent(TWO_D_CURRENT_ISSUE_LIST_ITEM);
};

export const sendCadastralCheckboxClick = (id: string | number, visible: boolean) => {
  switch (id) {
    case 'required':
      return sendEventForTwoDCurrent(
        TWO_D_CURRENT_CADASTER_COMP_REQUIRED_VIEW_CBOX,
        getCheckboxLabel(visible),
      );
    case 'pending':
      return sendEventForTwoDCurrent(
        TWO_D_CURRENT_CADASTER_COMP_PENDING_VIEW_CBOX,
        getCheckboxLabel(visible),
      );
    case 'done':
      return sendEventForTwoDCurrent(
        TWO_D_CURRENT_CADASTER_COMP_DONE_VIEW_CBOX,
        getCheckboxLabel(visible),
      );
    default:
  }
};

export const sendTwodCurrentIssueNdviCalBtn = () => {
  sendEventForTwoDCurrent(TWO_D_CURRENT_ISSUE_NDVI_CAL_BTN);
};
// 아직 기능 안 만든 상태 2023-03-24
export const sendTwodCurrentIssueNdviCompareBtn = () => {
  sendEventForTwoDCurrent(TWO_D_CURRENT_ISSUE_NDVI_COMPARE_BTN);
};

export const sendTwodCurrentISsueAddBtn = (type: IssueType) => {
  sendEventForTwoDCurrent(TWO_D_CURRENT_ISSUE_ADD_BTN, type);
};
