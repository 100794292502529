import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Delete from '@material-ui/icons/Delete';
import GetApp from '@material-ui/icons/GetApp';
import { IconButton } from 'features/common/button/IconButton';
import { BaseChip } from 'features/common/Chip';
import Tooltip from 'features/common/tooltip/Tooltip';
import { nls } from 'shared/locale/language';
import { ArchiveFileTableRow } from 'shared/query/fileArchive/types';
import React from 'react';
import styled, { css } from 'styled-components';
import palette from 'shared/styles/mui/palette';

interface HeaderProps {
  downloadDisabled?: boolean;
  deleteDisabled?: boolean;
}

interface Props {
  data: ArchiveFileTableRow;
}

export function HeaderRow({ downloadDisabled = false, deleteDisabled = false }: HeaderProps) {
  return (
    <StyledTableRow>
      <FixedTableCell $header $width="15rem" $paddingX="1rem">
        <HeaderCellContent>{nls.fileArchiveTableHeaderZone()}</HeaderCellContent>
      </FixedTableCell>
      <StyledTableCell $header>
        <HeaderCellContent>{nls.fileArchiveTableHeaderFileName()}</HeaderCellContent>
      </StyledTableCell>
      <FixedTableCell $header $width="11.25rem" $paddingX="1rem">
        <HeaderCellContent>{nls.fileArchiveTableHeaderType()}</HeaderCellContent>
      </FixedTableCell>
      <FixedTableCell $header $width="7.25rem" $paddingX="1rem">
        <HeaderCellContent>{nls.fileArchiveTableHeaderDate()}</HeaderCellContent>
      </FixedTableCell>
      <FixedTableCell $header $width="5.25rem" $disabled={downloadDisabled} $textAlign="center">
        <HeaderCellContent>{nls.fileArchiveTableHeaderDownload()}</HeaderCellContent>
      </FixedTableCell>
      <FixedTableCell $header $width="3.5rem" $disabled={deleteDisabled} $textAlign="center">
        <HeaderCellContent>{nls.fileArchiveTableHeaderDelete()}</HeaderCellContent>
      </FixedTableCell>
    </StyledTableRow>
  );
}

const popperProps = { style: { marginTop: '-0.875rem', marginLeft: '2rem' } };
export function Row({ data }: Props) {
  return (
    <StyledTableRow key={data.id}>
      <FixedTableCell $width="15rem" $paddingX="1rem">
        <CellContent>
          <BaseChip label={data.zoneName} />
        </CellContent>
      </FixedTableCell>
      <StyledTableCell>
        <Tooltip title={data.name} PopperProps={popperProps} placement="bottom-start">
          <CellContent $iconColor={data?.color}>
            {data.icon}
            {data.name}
          </CellContent>
        </Tooltip>
      </StyledTableCell>
      <FixedTableCell $width="11.25rem" $paddingX="1rem">
        <CellContent>{data.displayType}</CellContent>
      </FixedTableCell>
      <FixedTableCell $width="7.25rem" $paddingX="1rem">
        {data.date}
      </FixedTableCell>
      <FixedTableCell $width="5.25rem" $textAlign="center">
        <CellContent>
          {data?.download && (
            <StyledIconButton onClick={data.download}>
              <GetApp />
            </StyledIconButton>
          )}
        </CellContent>
      </FixedTableCell>
      <FixedTableCell $width="3.5rem" $textAlign="center">
        {data?.delete && (
          <StyledIconButton onClick={data.delete}>
            <Delete />
          </StyledIconButton>
        )}
      </FixedTableCell>
    </StyledTableRow>
  );
}

const StyledTableRow = styled(TableRow)``;
const StyledTableCell = styled(TableCell)<{ $header?: boolean; $disabled?: boolean }>`
  max-width: 27.5rem;
  height: ${({ $header }) => ($header ? '3.375rem' : '3.875rem')};
  padding: 0 1rem;
  overflow: hidden;
  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${palette.text.disabled};
    `}
`;
const FixedTableCell = styled(StyledTableCell)<{
  $width: string;
  $textAlign?: string;
  $paddingX?: string;
}>`
  width: ${({ $width }) => $width};
  min-width: ${({ $width }) => $width};
  max-width: ${({ $width }) => $width};
  ${({ $textAlign }) =>
    $textAlign &&
    css`
      text-align: ${$textAlign};
    `}
  padding: ${({ $paddingX }) => ($paddingX ? `0 ${$paddingX}` : '0')};
`;
const HeaderCellContent = styled(Typography).attrs({ variant: 'subtitle2', component: 'span' })`
  color: inherit;
`;
const CellContent = styled(Typography).attrs({ variant: 'body2', component: 'div' })<{
  $iconColor?: string;
}>`
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  & svg {
    margin-right: 0.5rem;
    color: ${({ $iconColor }) => $iconColor || palette.text.secondary};
    path {
      fill: ${({ $iconColor }) => $iconColor || palette.text.secondary};
      fill-opacity: 1;
    }
  }
`;
const StyledIconButton = styled(IconButton)`
  svg {
    margin: 0;
  }
`;
