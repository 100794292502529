import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import theme from 'shared/styles/mui/theme';
import { Organization } from '../types';

interface Props {
  data: Organization[];
  value: number;
  onChange: (value: number) => void;
}

export default function ProjectListOrganizationSelect({ data, value, onChange }: Props) {
  if (!data?.length) return null;

  const handleChange = (event: SelectChangeEvent) => {
    const v = +event.target.value;
    onChange(v);
  };

  return (
    <Select
      value={value?.toString() ?? ''}
      onChange={handleChange}
      sx={{
        maxWidth: '13.75rem',
        maxHeight: '2.5rem',
        backgroundColor: theme.palette.background.paper,
        marginBottom: '1.5rem',
      }}
    >
      {data?.map((item) => (
        <MenuItem key={item.id} value={item.id}>
          {item.description}
        </MenuItem>
      ))}
    </Select>
  );
}
