import { apiAxios } from 'shared/common/api/common';
import { ArchiveFileResponse, FileArchiveProps } from './types';

export default {
  async list({ projectId, page, size = 8, search }: FileArchiveProps) {
    const response = await apiAxios.get<ArchiveFileResponse>(
      `/api/v3/projects/${projectId}/file-archive`,
      { params: { page, size, search } },
    );
    return response?.data;
  },
};
