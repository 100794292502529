import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputBase from '@material-ui/core/InputBase';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Typography from '@material-ui/core/Typography';
import SwapHoriz from '@material-ui/icons/SwapHoriz';
import { DEFAULT_UNIT } from 'shared/common/policies/project';
import { TextButtonWithBackground } from 'features/common/button/TextButton';
import Select from 'features/common/select/Select';
import GA from 'shared/ga';
import {
  PROJECT_VOLUME_UNIT_ADD_BTN,
  PROJECT_VOLUME_UNIT_DEL_BTN,
} from 'shared/ga/actions/project';
import { PROJECT } from 'shared/ga/category';
import { nls } from 'shared/locale/language';
import { useProjectUnitListQuery, useProjectUnitMutation } from 'shared/query';
import { ProjectUnit } from 'shared/query/project/types';
import { projectKeys } from 'shared/query/queryKeys';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';
import { error } from 'shared/styles/colors/error';
import { others } from 'shared/styles/colors/others';
import theme from 'shared/styles/mui/theme';

const cubicMeterUnit = DEFAULT_UNIT.VOLUME.NAME;
const defaultVolumeUnitId = DEFAULT_UNIT.VOLUME.ID;

interface Props {
  projectId?: number;
  existingVolumeUnitId?: number;
  updateVolumeUnitId: any;
}
export default function FormUnitMeasurement({
  projectId,
  existingVolumeUnitId,
  updateVolumeUnitId,
}: Props) {
  const queryClient = useQueryClient();
  const [addMode, setAddMode] = useState<boolean>(false);
  const [createdUnit, setCreatedUnit] = useState<ProjectUnit | null>(null);
  const [unit, setUnit] = useState<ProjectUnit | null>(null);
  const [units, setUnits] = useState<ProjectUnit[]>([]);
  const [measureNumberA, setMeasureNumberA] = useState<number>(1);
  const [measureNumberB, setMeasureNumberB] = useState<number>();
  const [measureUnitA, setMeasureUnitA] = useState<string>(cubicMeterUnit);
  const [measureUnitB, setMeasureUnitB] = useState<string>('');
  const [converted, setConverted] = useState<boolean>(false);
  const [previewConverted, setPreviewConverted] = useState<boolean>(false);
  const { createProjectUnit, deleteProjectUnit } = useProjectUnitMutation(projectId);
  const { data: allUnits } = useProjectUnitListQuery(projectId, {
    enabled: Boolean(projectId),
  });

  useEffect(() => {
    if (!allUnits || allUnits?.length === 0 || !existingVolumeUnitId) return;
    const filteredUnits = allUnits.filter((unit) => unit.type === 'VOLUME');
    const existingUnit = filteredUnits.find((unit) => unit.id === existingVolumeUnitId);
    const defaultUnit = filteredUnits.find((unit) => unit.id === defaultVolumeUnitId);

    setUnits(filteredUnits);
    setUnit(createdUnit || existingUnit || defaultUnit);
  }, [allUnits, existingVolumeUnitId, createdUnit]);

  const handleSwapButtonClick = () => {
    const reciprocalA = 1 / measureNumberA;
    const reciprocalB = 1 / measureNumberB;
    setMeasureNumberA(reciprocalA);
    setMeasureUnitA(measureUnitB);
    setMeasureNumberB(reciprocalB);
    setMeasureUnitB(measureUnitA);
    setConverted((prev) => !prev);
  };

  const resetAll = () => {
    setAddMode(false);
    setMeasureNumberA(1);
    setMeasureNumberB(undefined);
    setMeasureUnitA(cubicMeterUnit);
    setMeasureUnitB('');
    setConverted(false);
    setPreviewConverted(false);
    updateVolumeUnitId(3);
  };

  const createUnit = () => {
    const unitName = converted ? measureUnitA : measureUnitB;
    const unitCoefficient = converted ? 1 / measureNumberB : measureNumberB;

    createProjectUnit.mutate(
      { projectId, data: { name: unitName, coefficient: unitCoefficient, type: 'VOLUME' } },
      {
        onSuccess: (createdUnit) => {
          setCreatedUnit({ ...createdUnit, default: false });
          updateVolumeUnitId(createdUnit.id);
          queryClient.invalidateQueries(projectKeys.unitList(projectId));
        },
      },
    );
    resetAll();
    GA.event(PROJECT, PROJECT_VOLUME_UNIT_ADD_BTN);
  };

  const deleteUnit = () => {
    deleteProjectUnit.mutate(
      { projectId, unitId: unit?.id },
      {
        onSuccess: () => {
          setCreatedUnit(null);
          updateVolumeUnitId(defaultVolumeUnitId);
          queryClient.invalidateQueries(projectKeys.unitList(projectId));
        },
      },
    );
    resetAll();
    GA.event(PROJECT, PROJECT_VOLUME_UNIT_DEL_BTN);
  };

  const EndAdornment = (
    <InputAdornment position="end">
      <Typography color="primary">{measureUnitB || nls.projectFormUnit()}</Typography>
    </InputAdornment>
  );

  const selectOptions = units
    .map((unit) => ({ name: unit.name, value: unit.id.toString() }))
    .concat({
      name: nls.projectFormUnitAdd(),
      value: 'add',
    });

  const handleSelectChange = (e: any) => {
    if (e.target.value === 'add') {
      setAddMode(true);
    } else {
      setAddMode(false);
      const selectedUnit = units.find((unit) => unit.id === parseInt(e.target.value));
      setUnit(selectedUnit);
      updateVolumeUnitId(selectedUnit.id);
    }
    setPreviewConverted(false);
  };

  return (
    <FormGroup>
      <FormLabel>{nls.measureVolume()}</FormLabel>
      {units.length > 0 ? (
        <FormContent>
          <FormInner>
            <StyledSelect
              value={addMode ? 'add' : unit?.id.toString()}
              options={selectOptions}
              onChange={handleSelectChange}
            />
            {addMode ? (
              <UnitInput
                value={converted ? measureUnitA : measureUnitB}
                onChange={(e: any) => {
                  converted ? setMeasureUnitA(e.target.value) : setMeasureUnitB(e.target.value);
                }}
                placeholder={nls.projectFormUnitHint()}
              />
            ) : (
              <UnitPreviewWrapper>
                <UnitPreviewBox>
                  <UnitPreviewBoxItem>
                    <span>
                      {[
                        previewConverted ? 1 / measureNumberA : measureNumberA,
                        previewConverted ? unit.name : cubicMeterUnit,
                      ].join(' ')}
                    </span>
                    <span>=</span>
                  </UnitPreviewBoxItem>
                  <UnitPreviewBoxItem>
                    <span>
                      {[
                        previewConverted ? 1 / unit.coefficient : unit?.coefficient,
                        previewConverted ? cubicMeterUnit : unit?.name,
                      ].join(' ')}
                    </span>
                  </UnitPreviewBoxItem>
                  <SwapButton onClick={() => setPreviewConverted((prev) => !prev)}>
                    <SwapHoriz />
                  </SwapButton>
                </UnitPreviewBox>
                {unit && unit.default === false && (
                  <UnitActionButton warning onClick={deleteUnit}>
                    {nls.delete()}
                  </UnitActionButton>
                )}
              </UnitPreviewWrapper>
            )}
          </FormInner>
          {addMode && (
            <UnitPreviewWrapper>
              <UnitPreviewBox>
                <UnitPreviewBoxItem>
                  <span>{[measureNumberA, measureUnitA].join(' ')}</span>
                  <span>=</span>
                </UnitPreviewBoxItem>
                <CoefficientInput
                  value={measureNumberB || ''}
                  onChange={(e: any) => setMeasureNumberB(e.target.value)}
                  inputProps={{ style: { textAlign: 'right' } }}
                  endAdornment={EndAdornment}
                  placeholder={nls.projectFormUnitValue()}
                />
                <SwapButton
                  onClick={handleSwapButtonClick}
                  disabled={!measureNumberB || !measureUnitB}
                >
                  <SwapHoriz />
                </SwapButton>
              </UnitPreviewBox>
              <UnitActionButton
                color="primary"
                onClick={() => createUnit()}
                disabled={!measureNumberB || !measureUnitB}
              >
                {nls.add()}
              </UnitActionButton>
            </UnitPreviewWrapper>
          )}
        </FormContent>
      ) : (
        <FormContent>
          <FormInner>
            <StyledSelect value="default" options={[{ name: cubicMeterUnit, value: 'default' }]} />
            <UnitPreviewWrapper>
              <UnitPreviewBox>
                <UnitPreviewBoxItem>
                  <span>{`1 ${cubicMeterUnit}`}</span>
                  <span>=</span>
                </UnitPreviewBoxItem>
                <UnitPreviewBoxItem>
                  <span>{`1 ${cubicMeterUnit}`}</span>
                </UnitPreviewBoxItem>
              </UnitPreviewBox>
            </UnitPreviewWrapper>
          </FormInner>
        </FormContent>
      )}
    </FormGroup>
  );
}

const FormGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 3rem;
`;

const FormLabel = styled(Typography).attrs({
  variant: 'subtitle1',
  component: 'span',
})`
  width: 5rem;
  margin-top: 0.75rem;
  margin-right: 1rem;
  color: ${theme.palette.text.secondary};
  &::after {
    content: ' *';
    color: ${error.MAIN};
  }
`;

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FormInner = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 1rem;
`;

const StyledSelect = styled(Select)`
  width: 17.5rem;
`;

const UnitInput = styled(OutlinedInput)`
  width: 17.5rem;
  height: 3.5rem;
  fieldset {
    border-color: ${others.BORDER};
  }
`;

const UnitPreviewWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.5rem;
  gap: 1rem;
`;

const UnitPreviewBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0 1.5rem;
  gap: 1rem;
  background-color: ${theme.palette.grey[100]};
  border-radius: 0.5rem;
`;

const UnitPreviewBoxItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  color: ${theme.palette.text.secondary};
`;

const UnitActionButton = styled(TextButtonWithBackground)`
  height: 100%;

  .MuiButton-label {
    white-space: nowrap;
  }
`;

const SwapButton = styled(IconButton)`
  padding: 0.5rem;
`;

const CoefficientInput = styled(InputBase)`
  flex: 1 1;
  height: 2.5rem;
  padding: 0 0.625rem;
  background-color: ${theme.palette.background.paper};
  border-radius: 0.25rem;
`;
