import {
  getRefreshToken,
  getValidateInTime,
  modifyJwtToken,
  needToRefreshToken,
  removeUserInfoToStorage,
  setRequestTime,
} from 'shared/common/utils';
import { nls } from 'shared/locale/language';
import { useUserMutation } from 'shared/query/user/useUserQuery';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import routes from 'pages/routes';
import useGlobalDialog from './useGlobalDialog';

export function useRefreshToken() {
  const history = useHistory();
  const { showAlert } = useGlobalDialog();
  const [intervalId, setIntervalId] = useState(null);
  const { refreshMutation } = useUserMutation();

  useEffect(() => {
    // 첫 진입시 호출 되도록 설정
    // 첫 진입시에는 토큰이 만료되어 다른 API들이 호출되지 않을 수 있어서 완료 후 새로고침하는 로직을 추가.
    // 이후에는 만료가 되기 전이므로 새로고침 로직을 추가하지 않음.
    refresh(() => window.location.reload());
    // validateIn의 시간 단위는 seconds여서 1000을 곱함
    // validateIn 1/10마다 기준을 충족했는지 체크함
    const intervalTime = (+(getValidateInTime() || 6000) * 1000) / 10;
    const id = setInterval(() => refresh(), intervalTime);
    setIntervalId(id);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const refreshFailCb = (errorCode) => {
    removeUserInfoToStorage();
    if (!errorCode) {
      return;
    }
    showAlert({
      content: nls(errorCode),
      primaryButtonProps: {
        onClick: () => history.push(routes.login.path, { from: history.location }),
      },
    });
  };

  const refresh = (successCb?: () => void) => {
    const notRequiredLoginPages = [
      routes.login.path,
      routes.readonly.path,
      routes.user.join.path,
      routes.user.findPw.path,
    ];
    const notRequiredLogin = !notRequiredLoginPages.includes(history.location.pathname);

    if (notRequiredLogin && needToRefreshToken()) {
      const refreshToken = getRefreshToken();
      if (refreshToken) {
        refreshMutation.mutate(refreshToken, {
          onSuccess: (response) => {
            successCb?.();
            const reqTime = new Date().getTime();
            setRequestTime(reqTime);
            modifyJwtToken(response);
          },
          onError: (error: any) => {
            refreshFailCb(error?.errorCode);
          },
        });
      }
    }
  };

  return null;
}
