import { apiAxios } from 'shared/common/api/common';
import { DrawingPositioning } from 'shared/query/drawing/types';

export default {
  async abort({ snapshotId, drawingId }: { snapshotId: number; drawingId: number }) {
    const response = await apiAxios.post(
      `/api/v3/snapshots/${snapshotId}/drawings/${drawingId}/abort`,
    );
    return response?.data;
  },
  async retry({ snapshotId, drawingId }: { snapshotId: number; drawingId: number }) {
    const response = await apiAxios.post(
      `/api/v3/snapshots/${snapshotId}/drawings/${drawingId}/retry`,
    );
    return response?.data;
  },
  async updatePositioning({
    drawingId,
    conversionDataId,
    data,
  }: {
    drawingId: number;
    conversionDataId: number;
    data: Partial<DrawingPositioning['conversionData']['data']>;
  }) {
    const response = await apiAxios.patch<{ data: DrawingPositioning['conversionData']['data'] }>(
      `/api/v3/drawings/${drawingId}/positioning/${conversionDataId}`,
      { data },
    );
    return response?.data;
  },
};
