import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import palette from './palette';
import typography from './typography';
import { others } from 'shared/styles/colors/others';

const theme = createMuiTheme({
  palette,
  typography,
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    drawer: 1100, // side menu
    appBar: 1200, // header, gnb
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: 8,
      },
    },
    MuiSelect: {
      outlined: {
        borderColor: others.BORDER,
      },
      filled: {
        padding: '0.5rem 1.38rem',
        backgroundColor: palette.grey[200],
        borderRadius: '0.25rem',
      },
      select: {
        borderColor: others.BORDER,
        padding: '0.594rem 1.5rem 0.594rem 0.875rem',
        lineHeight: 1.75,
        backgroundColor: palette.common.white,
        '&:focus': {
          backgroundColor: 'inherit',
        },
      },
      icon: {
        marginRight: 6,
        marginTop: 2,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: `1px solid ${palette.divider}`,
      },
    },
  },
});

export default theme;
