import { useDefaultEventBlocker, useMatchParams } from 'features/common/hooks';
import useGlobalDialog from 'features/common/hooks/useGlobalDialog';
import { nls } from 'shared/locale/language';
import { usePermissionQuery, useProjectQuery } from 'shared/query';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import ProjectInfoPage from './ProjectInfoPage';

export default function ProjectInfoPageContainer() {
  const history = useHistory();
  const { showAlert } = useGlobalDialog();
  const { projectId: id } = useMatchParams();

  useDefaultEventBlocker(window, 'contextmenu');
  const project = useProjectQuery(id)?.data ?? {};

  const { data: permission } = usePermissionQuery(id);
  useEffect(() => {
    if (permission && !permission?.projectGet) {
      showAlert({
        content: nls.noPermissionMsgContent(),
        primaryButtonProps: { onClick: () => history.goBack() },
      });
    }
  }, [permission]);

  return <ProjectInfoPage data={project} permission={permission} />;
}
