import { createTheme } from '@mui/material';
import { DEFAULT_FONT_FAMILY } from 'features/common/style/TextStyles';
import palette from 'shared/styles/mui/palette';
import { others } from 'shared/styles/colors/others';

export const globalTheme = createTheme({
  palette,
  typography: {
    fontFamily: DEFAULT_FONT_FAMILY,
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          '& > .MuiOutlinedInput-notchedOutline': {
            borderColor: others.BORDER,
          },
          '.MuiInputBase-sizeSmall': {
            height: '2.5rem',
          },
        },
        outlined: {
          borderColor: others.BORDER,
        },
        filled: {
          padding: '0.5rem 1.38rem',
          backgroundColor: palette.grey[200],
          borderRadius: '0.25rem',
        },
        select: {
          borderColor: others.BORDER,
        },
      },
    },
  },
});
