import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Cancel from '@material-ui/icons/Cancel';
import { memberPermission } from 'shared/common/types';
import Select from 'features/common/select/Select';
import { nls } from 'shared/locale/language';
import React from 'react';
import styled, { css } from 'styled-components';
import { scrollStyle } from 'shared/styles/colors/scrollbar';
import theme from 'shared/styles/mui/theme';

interface Props {
  data: { email: string; grade: string }[];
  onGradeChange: (email: string, grade: string) => void;
  onDeleteClick: (email: string) => void;
  gradeOptions: typeof memberPermission.MANAGER[];
  loading: boolean;
}

export default function InviteeQueue({
  data,
  onGradeChange,
  onDeleteClick,
  gradeOptions,
  loading,
}: Props) {
  const empty = !data?.length;
  if (empty) {
    return <EmptyViewLayout>{nls.noInviteesMessage()}</EmptyViewLayout>;
  }

  const list = data.map((x) => {
    return (
      <ListItem key={x.email}>
        <Avatar />
        <Email>{x.email}</Email>
        <SelectWrapper>
          <Select
            value={data.find((y) => y.email === x.email).grade}
            onChange={(e) => onGradeChange(x.email, e.target.value as string)}
            options={gradeOptions.map((y) => ({
              value: memberPermission[y.value].value,
              name: memberPermission[y.value].title,
            }))}
          />
        </SelectWrapper>
        <StyledIconButton onClick={() => onDeleteClick(x.email)} $loading={loading}>
          <Cancel />
        </StyledIconButton>
      </ListItem>
    );
  });

  return <ListViewLayout>{list}</ListViewLayout>;
}

const layoutStyle = css`
  height: 13.75rem;
  margin-top: 1.875rem;
  margin-bottom: 2.5rem;
`;

const EmptyViewLayout = styled(Typography).attrs({ variant: 'body1' })`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.palette.text.disabled};
  ${layoutStyle}
`;

const ListViewLayout = styled.div`
  border-radius: 0.25rem;
  border: 1px solid ${theme.palette.divider};
  ${layoutStyle}
  ${scrollStyle};
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 0.25rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  border-bottom: 1px solid ${theme.palette.divider};

  /* 마지막 행에도 border-bottom을 줄 경우, 레이아웃의 border-bottom과 겹쳐 보이게 됨 */
  /* 그렇다고 마지막 행의 border-bottom을 없애면 참여자가 적어서 스크롤 없을 때의 표시가 의도와 다르게 됨 */
  /* 따라서 마지막 행의 경우만 border-bottom 대신 box-shadow를 사용하여 경계선 나타내도록 함 */
  &:last-child {
    border-bottom: none;
    box-shadow: 0 1px ${theme.palette.divider};
  }
`;

const Email = styled(Typography).attrs({ variant: 'body2', color: 'textPrimary' })`
  flex-grow: 1;
  margin-left: 1rem;
`;

const SelectWrapper = styled.div`
  width: 18rem;

  .MuiInputBase-root {
    width: 10rem;
  }

  .MuiSelect-root {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }
`;

const StyledIconButton = styled(IconButton)<{ $loading: boolean }>`
  &:focus {
    outline: none;
  }

  visibility: ${({ $loading }) => ($loading ? 'hidden' : 'visible')};
`;
