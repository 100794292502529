import { apiAxios } from 'shared/common/api/common';
import { AerialImage } from 'shared/query/aerialImage/types';
import { ImageArchive } from 'shared/query/resource/types';

export default {
  async create(payload: { snapshotId: number; filename: string; name: string }) {
    const { snapshotId, ...rest } = payload;
    const response = await apiAxios.post<AerialImage>(
      `/api/v3/snapshots/${snapshotId}/aerialimages`,
      rest,
      { timeout: 60_000 },
    );
    return response?.data;
  },
  async delete(payload: { snapshotId: number; aerialImageId: number }) {
    const { snapshotId, aerialImageId } = payload;
    await apiAxios.delete(`/api/v3/snapshots/${snapshotId}/aerialimages/${aerialImageId}`);
  },
  async list(snapshotId: number) {
    const response = await apiAxios.get<{ results: AerialImage[] }>(
      `/api/v3/snapshots/${snapshotId}/aerialimages`,
    );
    return response?.data?.results;
  },
  async archive(snapshotId: number) {
    const response = await apiAxios.get<ImageArchive>(
      `/api/v3/snapshots/${snapshotId}/aerialimages/archive`,
    );
    return response?.data;
  },
};
