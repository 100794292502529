import {
  Drawing,
  DrawingPositioning,
  DrawingRaster,
  DrawingVector,
} from 'shared/query/drawing/types';
import { PlanLevel } from 'shared/query/planLevel/types';
import { Stratum } from 'shared/query/stratum/types';
import { Bim, Dsm, EngineMeshGlb, Etc, Ndvi, Orthophoto, Resource } from './types';

export const isOrthophoto = (x: Resource): x is Orthophoto =>
  x.type === 'ENGINE_ORTHOPHOTO' || x.type === 'ORTHOPHOTO';
export const isDSM = (x: Resource): x is Dsm => x.type === 'ENGINE_DSM' || x.type === 'DSM';
export const isPlanLevel = (x: Resource): x is PlanLevel => x.type === 'PLAN_LEVEL';
export const isStratum = (x: Resource): x is Stratum => x.type === 'STRATUM';
export const isDrawing = (x: Resource): x is Drawing =>
  x.type === 'DRAWING_POSITIONING' || x.type === 'DRAWING_RASTER' || x.type === 'DRAWING_VECTOR';
export const isEtc = (x: Resource): x is Etc => x.type === 'ETC';
export const isBim = (x: Resource): x is Bim => x.type === 'BIM';
export const isEngineMeshGlb = (x: Resource): x is EngineMeshGlb => x.type === 'ENGINE_MESH_GLB';

export const isRaster = (d: Drawing): d is DrawingRaster => d?.type === 'DRAWING_RASTER';
export const isVector = (d: Drawing): d is DrawingVector => d?.type === 'DRAWING_VECTOR';
export const isPositioning = (d: Drawing): d is DrawingPositioning =>
  d?.type === 'DRAWING_POSITIONING';

export const isWhole = (x: Ndvi): boolean => {
  return (
    (x?.type === 'NDVI' || x?.type === 'ENGINE_NDVI') && x.data?.vegetationIndexAreaType === 'WHOLE'
  );
};
