import FormControl from '@material-ui/core/FormControl';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import React from 'react';
import styled, { css } from 'styled-components';
import { others } from 'shared/styles/colors/others';
import theme from 'shared/styles/mui/theme';
import { Label } from '../Label';

export const GNBLayout = styled.div`
  user-select: none;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  min-height: 100%;
  flex-direction: row;
  flex: 1;
`;

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  margin-left: 4rem;

  .content {
    width: 51.75rem;
    margin: 0 auto;
  }
  &.userInfo {
    margin-top: 3rem;
  }
`;

export const FormWrapper = styled.form`
  padding: 2.9375rem 0 2.5rem;

  .relative {
    position: relative;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 2.5rem 0 1.25rem;

  button + button {
    margin-left: 1rem;
  }
`;

export const FormControlEx = styled(FormControl)`
  flex-direction: row;
  align-items: center;
  flex: 1;

  &:not(:first-of-type) {
    min-height: 3.5rem;
    margin-top: 3rem;
  }

  div {
    display: flex;
    flex: 1;
  }
`;

export const InputControl = ({ required = false, label, name = '', children }) => {
  return (
    <FormControlEx>
      <Label $isRequired={required} htmlFor={name}>
        {label}
      </Label>
      {children}
    </FormControlEx>
  );
};

export interface InputComponentProps {
  theme: Theme;
  $active?: boolean;
  $error?: boolean;
  $disabled?: boolean;
}

export function getInputComponentBorderColor({ $active, $error, $disabled }: InputComponentProps) {
  if ($error) {
    return css`
      border-color: ${theme.palette.error.main};
    `;
  }
  if ($disabled) {
    return css`
      border-color: ${others.BORDER};
    `;
  }
  if ($active) {
    return css`
      border-color: ${theme.palette.primary.main};
    `;
  }
  return css`
    border-color: ${others.BORDER};

    &:focus {
      border-color: ${theme.palette.primary.main};
    }
  `;
}

// margin + padding + border 값이 같지 않으면 엘리먼트 내부 내용이 움찔움찔 움직이게 됨
// https://stackoverflow.com/questions/8270380/when-focusing-on-an-input-field-with-a-3px-border-all-the-other-input-fields-ke
export function getInputComponentBorderWidth({ $active, $error }: InputComponentProps) {
  if ($error) {
    return css`
      border-width: 1px;
      margin: 1px;

      &:hover {
        border-width: 2px;
        margin: 0px;
      }
    `;
  }
  if ($active) {
    return css`
      border-width: 2px;
      margin: 0px;
    `;
  }
  return css`
    border-width: 1px;
    margin: 1px;

    &:focus {
      border-width: 2px;
      margin: 0px;
    }
  `;
}
