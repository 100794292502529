import Dialog from 'features/styleComponents/dialog';
import FolderTreeView, { FolderTreeItemType } from 'features/styleComponents/folder/FolderTreeView';
import React, { useState } from 'react';
import { nls } from 'shared/locale/language';

interface Props {
  open: boolean;
  close: () => void;
  data: FolderTreeItemType;
  currentFolderId: number;
  selectedFolderIdList: number[];
  submit: (folderId: number, name?: string) => void;
}

export default function ProjectFolderMoveModal({
  open,
  close,
  data,
  currentFolderId,
  selectedFolderIdList,
  submit,
}: Props) {
  const [selectedFolderId, setSelectedFolderId] = useState<number | null>(null);
  const [selectedFolderName, setSelectedFolderName] = useState<string | null>(null);

  return (
    <Dialog
      type="list"
      title={nls.fileSettingFolderMoveDialogTitle()}
      open={open}
      primaryButtonProps={{
        title: nls.fileSettingFolderMoveConfirmButton(),
        disabled: selectedFolderId === null,
        onClick: () => {
          if (selectedFolderId) {
            submit(selectedFolderId, selectedFolderName);
            setSelectedFolderId(null);
            close();
          }
        },
      }}
      secondaryButtonProps={{ onClick: close }}
      onClose={close}
    >
      <FolderTreeView
        data={data}
        currentFolderId={currentFolderId}
        selectedFolderId={selectedFolderId}
        setSelectedFolderId={setSelectedFolderId}
        setSelectedFolderName={setSelectedFolderName}
        disabledFolderIdList={selectedFolderIdList}
        rootFolderName={nls.zoneProjectList()}
      />
    </Dialog>
  );
}
