import {
  MediaListParams,
  PhotoBoxAlbumDetailListParams,
  PhotoBoxMediaExistShootingDateParams,
} from 'shared/query/photoBox/types';

export const keys = {
  getMediaList: ({
    lastMediaId,
    limit,
    projectId,
    type,
    zoneId,
    extra,
    memo,
    startShootingDate,
    endShootingDate,
    createdByUser,
    orderBy,
  }: MediaListParams) => [
    'photoBox',
    'media',
    'list',
    lastMediaId,
    limit,
    projectId,
    type,
    zoneId,
    extra,
    memo,
    startShootingDate,
    endShootingDate,
    createdByUser,
    orderBy,
  ],
  getAlbumList: (projectId: number) => ['photoBox', 'album', 'list', projectId],
  getAlbumDetailList: ({ albumId, lastMediaId, limit }: PhotoBoxAlbumDetailListParams) => [
    'photoBox',
    'albumDetail',
    'list',
    albumId,
    lastMediaId,
    limit,
  ],
  getMediaExistShootingDate: ({
    projectId,
    zoneId,
    targetShootingDate,
  }: PhotoBoxMediaExistShootingDateParams) => [
    'photoBox',
    'media',
    'existDate',
    projectId,
    zoneId,
    targetShootingDate,
  ],
  getExtraList: (projectId: number) => ['photoBox', 'extra', 'list', projectId],
  getDetailMedia: (mediaId: number) => ['photoBox', 'media', 'detail', mediaId],
};
