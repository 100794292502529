import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import HelpOutline from '@material-ui/icons/HelpOutline';
import { BigArrowTooltip } from 'features/common/tooltip/Tooltip';
import { nls } from 'shared/locale/language';
import React, { useState } from 'react';
import { ProjectUser } from 'stores/data/types';
import styled from 'styled-components';
import { scrollStyle } from 'shared/styles/colors/scrollbar';
import theme from 'shared/styles/mui/theme';
import MemberRow from './MemberRow';
import StyledTableRow from './StyledTableRow';
import TooltipContent from './TooltipContent';

interface Props {
  invites: ProjectUser[];
  projectUsers: ProjectUser[];
  deleting: boolean;
  allChecked: boolean;
  checked: { [id: number]: boolean };
  onAllCheckedChange: (open: boolean) => void;
  onCheckedChange: (id: number, checked: boolean) => void;
  hasSendMailPermission: boolean;
}

export default function MemberTable({
  invites,
  projectUsers,
  deleting,
  allChecked,
  checked,
  onAllCheckedChange,
  onCheckedChange,
  hasSendMailPermission,
}: Props) {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const showCheckBoxes = deleting;
  return (
    <StyledTableContainer>
      <Table stickyHeader>
        <TableHead>
          <StyledTableRow $header>
            <TableCell>
              <StyledCheckbox
                $visible={showCheckBoxes}
                checked={allChecked}
                onChange={(e) => onAllCheckedChange(e.target.checked)}
              />
            </TableCell>
            <TableCell>{nls.company()}</TableCell>
            <TableCell>{nls.name()}</TableCell>
            <TableCell>{nls.jobTitle()}</TableCell>
            <TableCell>
              {nls.grade()}
              <BigArrowTooltip
                title={<TooltipContent />}
                arrow
                disableHoverListener
                open={tooltipOpen}
                onClose={() => setTooltipOpen(false)}
              >
                <GradeHelpIconButton onClick={() => setTooltipOpen(true)}>
                  <HelpOutline />
                </GradeHelpIconButton>
              </BigArrowTooltip>
            </TableCell>
            <TableCell>{nls.status()}</TableCell>
            <TableCell />
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {invites.map((x) => (
            <MemberRow
              key={x.id}
              data={x}
              deleting={deleting}
              invitee
              checked={checked[x.id] || false}
              onCheckedChange={onCheckedChange}
              hasSendMailPermission={hasSendMailPermission}
            />
          ))}
          {projectUsers.map((x) => (
            <MemberRow
              key={x.id}
              data={x}
              deleting={deleting}
              invitee={false}
              checked={checked[x.id] || false}
              onCheckedChange={onCheckedChange}
            />
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
}

const StyledTableContainer = styled(TableContainer)`
  margin-top: 2.375rem;
  border: 1px solid ${theme.palette.divider};
  border-radius: 0.25rem;
  height: 43rem;
  background: ${theme.palette.background.paper};
  ${scrollStyle};
`;

const StyledCheckbox = styled(Checkbox)<{ $visible: boolean }>`
  visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};
`;

const GradeHelpIconButton = styled(IconButton)`
  color: ${theme.palette.text.disabled};
  padding: 0.6rem;
  margin-left: 0.19rem;

  &:focus {
    outline: none;
  }

  svg {
    font-size: 1rem;
  }
`;
