import { CircularProgress, Typography } from '@material-ui/core';
import React from 'react';
import theme from 'shared/styles/mui/theme';
import styled from 'styled-components';
import { ProjectPathNavigationItemType } from '../types';

interface Props {
  folderItem: ProjectPathNavigationItemType;
}

export default function ProjectPathNavigationItem({ folderItem }: Props) {
  /** 현재 오픈되어있는 폴더일 때만 로딩 인디케이터 표시 */
  const loading = folderItem?.isCurrent && folderItem?.isLoading;
  return (
    <Container>
      <FolderItem $isCurrent={folderItem?.isCurrent} onClick={folderItem?.onClick}>
        {folderItem?.name}
      </FolderItem>
      {loading && <CircularProgress size={40} />}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

const FolderItem = styled(Typography).attrs({ variant: 'h4' })<{ $isCurrent?: boolean }>`
  color: ${({ $isCurrent }) =>
    $isCurrent ? theme.palette.text.primary : theme.palette.text.secondary};
  cursor: pointer;
  :hover {
    text-decoration: ${({ $isCurrent }) => ($isCurrent ? 'none' : 'underline')};
  }
`;
