import {
  AddPhotoBoxMediaFileToAlbumParams,
  CreatePhotoBoxAlbumParams,
  DeletePhotoBoxMediaFileFromAlbumParams,
  FileResourceEnum,
  MediaListParams,
  PhotoBoxAlbumDetailListParams,
  PhotoBoxExtraListResultEntity,
  PhotoBoxMediaExistShootingDateParams,
  PhotoBoxMediaListResult,
  UpdateMediaEntity,
  UpdatePhotoBoxAlbumParams,
} from 'shared/query/photoBox/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { keys } from 'shared/query/photoBox/queryKeys';
import api from 'shared/query/photoBox/api';
import { PhotoBoxMediaEnum } from 'features/photoBox/constants/common.const';
import { requestUploadFile, s3DirectFileUpload } from 'shared/query/fileUpload/s3Upload';
import { S3FileUploadType } from 'shared/query/fileUpload/types';
import { TempFile } from 'shared/query/resource/types';
import axios, { AxiosError, CancelToken } from 'axios';

/* 미디어 관리 */
export function usePhotoBoxMediaListQuery({
  params,
  enabled,
}: {
  params: MediaListParams;
  enabled?: boolean;
}) {
  const convertType = params.type === PhotoBoxMediaEnum.MEDIA ? null : params.type;
  const convertParams = { ...params, type: convertType };
  return useQuery(keys.getMediaList(params), () => api.getMediaList(convertParams), { enabled });
}
type CreationProps = {
  projectId: number;
  zoneId: number;
  mediaFile: TempFile;
  memo: string;
  albumIdList: number[];
  extraList?: PhotoBoxExtraListResultEntity[];
  onSuccess?: (tempFile) => void;
  onError?: (tempFile: TempFile, errorCode?: string) => void;
  onCancel?: (tempFile) => void;
  cancelToken?: CancelToken;
};
export function usePhotoBoxMediaMutation() {
  const singleUploadMediaFile = useMutation<
    PhotoBoxMediaListResult,
    AxiosError<{ errorCode: string; message: string }>,
    CreationProps
  >(
    async ({ projectId, zoneId, mediaFile, memo, albumIdList, extraList, cancelToken }) => {
      const { data: dataForUpload } = await requestUploadFile({
        name: mediaFile.name,
        size: mediaFile.size,
      });
      const { fields } = dataForUpload as S3FileUploadType;
      await s3DirectFileUpload(dataForUpload, mediaFile.file, cancelToken);
      return api.uploadMediaFile({
        mediaList: [
          {
            projectId,
            zoneId,
            resource: {
              file: fields.key,
              fileName: mediaFile.name,
              type: mediaFile.file.type.includes('image')
                ? FileResourceEnum.IMAGE
                : FileResourceEnum.VIDEO,
              size: mediaFile.size,
            },
            type: mediaFile.additional?.data?.fileType,
            latitude: mediaFile.additional?.data?.latitude,
            longitude: mediaFile.additional?.data?.longitude,
            memo,
            shootingDate: mediaFile.additional?.data?.shootingDate,
            mediaMeta: {
              videoDuration: mediaFile.additional?.data?.videoDuration,
            },
            albumIdList,
            extraList,
          },
        ],
        cancelToken,
      });
    },
    {
      onError: (error, variables) => {
        if (axios.isCancel(error)) {
          variables?.onCancel && variables.onCancel(variables.mediaFile);
        } else {
          variables?.onError(variables.mediaFile, error?.response?.data?.errorCode);
        }
      },
    },
  );
  const updateMediaFiles = useMutation(
    ({
      mediaList,
    }: Pick<MediaListParams, 'projectId' | 'type'> & { mediaList: UpdateMediaEntity[] }) => {
      return api.updateMediaFile({ mediaList });
    },
  );
  const deleteMediaFiles = useMutation((mediaList: number[]) => {
    return api.deleteMediaFile(String(mediaList));
  });
  return {
    singleUploadMediaFile,
    updateMediaFiles,
    deleteMediaFiles,
  };
}
export function usePhotoBoxDetailMediaQuery(mediaId: number) {
  return useQuery(keys.getDetailMedia(mediaId), () => api.getPhotoBoxDetailMedia(mediaId));
}

/* 앨범 관리 */
export function usePhotoBoxAlbumListQuery(projectId: number, enabled?: boolean) {
  return useQuery(keys.getAlbumList(projectId), () => api.getAlbumList(projectId), { enabled });
}
export function usePhotoBoxAlbumMutation(projectId: number) {
  const queryClient = useQueryClient();
  const createAlbum = useMutation(({ params }: { params: CreatePhotoBoxAlbumParams }) => {
    return api.createAlbum(params);
  });
  const updateAlbum = useMutation(
    ({ params }: { params: UpdatePhotoBoxAlbumParams }) => {
      return api.updateAlbum(params);
    },
    { onSuccess: () => queryClient.invalidateQueries(keys.getAlbumList(projectId)) },
  );
  const deleteAlbum = useMutation(
    (albumId: number[]) => {
      return api.deleteAlbum(albumId.toString());
    },
    { onSuccess: () => queryClient.invalidateQueries(keys.getAlbumList(projectId)) },
  );
  return { createAlbum, updateAlbum, deleteAlbum };
}

/* 앨범에 속한 미디어 관리 */
export function usePhotoBoxAlbumDetailListQuery({
  params,
}: {
  params: PhotoBoxAlbumDetailListParams;
}) {
  return useQuery(keys.getAlbumDetailList(params), () => api.getAlbumDetailList(params));
}
export function usePhotoBoxAlbumDetailMutation() {
  const addMediaToAlbum = useMutation(
    ({ params }: { params: AddPhotoBoxMediaFileToAlbumParams }) => {
      return api.addMediaFileToAlbum(params);
    },
  );
  const deleteMediaFromAlbum = useMutation(
    ({ params }: { params: DeletePhotoBoxMediaFileFromAlbumParams }) => {
      return api.deleteMediaFileFromAlbum(params);
    },
  );
  return { addMediaToAlbum, deleteMediaFromAlbum };
}

/* 검색 */
export function useMediaExistDateListQuery({
  params,
}: {
  params: PhotoBoxMediaExistShootingDateParams;
}) {
  return useQuery(keys.getMediaExistShootingDate({ ...params }), () =>
    api.getMediaExistShootingDate({ ...params }),
  );
}
export function usePhotoBoxExtraListQuery({ projectId }: Pick<MediaListParams, 'projectId'>) {
  return useQuery(keys.getExtraList(projectId), () => api.getExtraList(projectId));
}
