import React, { lazy } from 'react';
const LandDisplacementDetailsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "LandDisplacementPage " */ 'features/landDisplacement/LandDisplacementPage'
    ),
);

function LandDisplacement() {
  return <LandDisplacementDetailsPage />;
}

export default LandDisplacement;
