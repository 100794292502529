import { DEFAULT_POLLING_INTERVAL, DEFAULT_QUERY_STALE_TIME } from 'shared/common/policies/request';
import { useMatchParams } from 'features/common/hooks';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { UseQueryOptions, useQueries, useQuery, useQueryClient } from 'react-query';
import { drawingVectorAtom } from 'stores/v3/snapshotDetail/twoD/annotation/drawingVector';
import api from './api';
import { keys } from './queryKeys';

export default function useDrawingVectorQuery(
  options: Pick<UseQueryOptions, 'enabled'> = {},
  id?: number,
) {
  const queryClient = useQueryClient();
  const { projectId, zoneId } = useMatchParams();
  const [enabled, setEnabled] = useAtom(drawingVectorAtom.enabled);

  useEffect(() => {
    setEnabled((prev) => Boolean(prev || options.enabled));
  }, [options.enabled]);

  const { data: drawingVectorResourceList } = useQuery(
    keys.resourceList(zoneId),
    () => api.list({ zoneId }),
    {
      refetchInterval: (data) => {
        return data?.some((x) => x.data?.progressStatus === 'RUNNING')
          ? DEFAULT_POLLING_INTERVAL
          : false;
      },
      onSuccess: () => {
        queryClient.invalidateQueries(keys.detail(id));
      },
    },
  );

  const { data: drawingVector } = useQuery(
    keys.detail(id),
    () => api.read({ projectId, resourceId: id }),
    {
      enabled: Boolean(id),
    },
  );

  const drawingVectorQueries = useQueries(
    drawingVectorResourceList?.map((x) => ({
      queryKey: keys.detail(x?.id),
      queryFn: () => api.read({ projectId, resourceId: x.id }),
      enabled,
      staleTime: DEFAULT_QUERY_STALE_TIME,
      refetchInterval: (d) => d?.progressStatus === 'RUNNING' && DEFAULT_POLLING_INTERVAL,
    })) ?? [],
  );

  const bulkPolygons = drawingVectorQueries?.reduce(
    (acc, x) => ({ ...acc, [x.data?.polygonVectorId]: x.data?.annotationList ?? [] }),
    {},
  );

  return {
    drawingVectorResourceList,
    drawingVector,
    drawingVectorResource: drawingVectorResourceList?.find((x) => x?.id === id),
    bulkPolygons,
  };
}
