import { PointCloudListParams } from 'shared/query/resource/pointCloud/types';

export const keys = {
  getPointCloudList: ({ snapshotId }: PointCloudListParams) => [
    'resource',
    'pointCloud',
    'list',
    snapshotId,
  ],
};
