import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import GetAppIcon from '@material-ui/icons/GetApp';
import BoundingBoxIcon from '@mui/icons-material/VideoStableOutlined';
import QRCodeLogo from 'assets/images/qrCodeLogo.svg';
import { isJapan, isPosco } from 'shared/common/customize';
import { QR_CODE_DOWNLOAD_FILE_TYPE } from 'shared/common/policies/file';
import { mapTypes, mapTypesJp } from 'shared/common/policies/map';
import {
  QRCodeCanvasSize,
  QRCodeLogoHeight,
  QRCodeLogoWidth,
  QRCodeSize,
} from 'shared/common/policies/sizes';
import { datumList } from 'shared/common/types';
import { convertDateFormat } from 'shared/common/utils/date';
import { downloadFile } from 'shared/common/utils/file';
import { ButtonDefault, ButtonPrimary } from 'features/common/button/ContainedButton';
import TextButton, { TextButtonWithBackground } from 'features/common/button/TextButton';
import { useMatchParams } from 'features/common/hooks';
import useGlobalDialog from 'features/common/hooks/useGlobalDialog';
import WidePopup from 'features/common/modal/WidePopup';
import PUHeader from 'features/common/pu/Header';
import CopyField from 'features/common/textfield/CopyField';
import FieldBoundaryDialog from 'features/project/infoPage/fieldBoundaryModal/FieldBoundaryDialog';
import GA from 'shared/ga';
import { PROJECT_SETTING_WORKER_BOUNDARY_BTN } from 'shared/ga/actions';
import { PROJECT } from 'shared/ga/category';
import { nls } from 'shared/locale/language';
import { QRCodeCanvas } from 'qrcode.react';
import { useProjectMutation } from 'shared/query';
import { Permission } from 'shared/query/permission/types';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import routes from 'pages/routes';
import { Project } from 'stores/data/types';
import styled from 'styled-components';
import { others } from 'shared/styles/colors/others';
import { scrollStyle } from 'shared/styles/colors/scrollbar';
import theme from 'shared/styles/mui/theme';
import ProjectPageLayout from '../ProjectPageLayout';

interface Props {
  data: Project;
  permission: Permission;
}

export default function ProjectInfoPage({ data, permission }: Props) {
  const history = useHistory();
  const { projectId } = useMatchParams();
  const { showConfirm } = useGlobalDialog();

  const { deleteProject } = useProjectMutation();
  const [QRCodePopupShow, setQRCodePopupShow] = useState(false);
  const [boundingBoxPopup, setBoundingBoxPopup] = useState<boolean>(false);
  const localizedmapTypes = isJapan() ? mapTypesJp : mapTypes;

  const hasProjectEditPermission = permission?.projectPut;
  const hasProjectDeletePermission = permission?.projectDelete;
  const hasSnapshotListPermission = permission?.snapshotGet;

  const QRCodeDownloadEnabled = isPosco() === false;
  const QRCodeWrapperClassName = 'QRCodeWrapper';
  const QRCodeCanvasStyle = {
    width: QRCodeCanvasSize,
    height: QRCodeCanvasSize,
  };
  const QRCodeImageSetttings = {
    src: QRCodeLogo,
    width: QRCodeLogoWidth,
    height: QRCodeLogoHeight,
    excavate: false,
  };

  const QRCodeValue = `${window.location.origin}/guard/${data?.otp}`;

  const onDeleteConfirm = () => {
    const successCb = () => history.push(routes.project.index.path);
    deleteProject.mutate({ projectId }, { onSuccess: successCb });
  };

  const onDeleteClick = () => {
    showConfirm({
      title: nls.projectDeleteConfirmTitle(),
      content: nls.projectDeleteConfirmMessage(),
      primaryButtonProps: {
        title: nls.delete(),
        onClick: onDeleteConfirm,
        color: 'secondary',
      },
    });
  };

  const openQRCodeModal = () => setQRCodePopupShow(true);
  const closeQRCodePopup = () => setQRCodePopupShow(false);

  const downloadQRCode = () => {
    let QRCodeCanvasElement = document.querySelector(
      `.${QRCodeWrapperClassName} canvas`,
    ) as HTMLCanvasElement;
    const QRCodeDataURL = QRCodeCanvasElement.toDataURL(QR_CODE_DOWNLOAD_FILE_TYPE);

    const name = `${data.name}.${QR_CODE_DOWNLOAD_FILE_TYPE.split('/').pop()}`;
    downloadFile(QRCodeDataURL, name);
    QRCodeCanvasElement = null;
  };

  const onClickOpenFieldBoundaryDialog = () => {
    setBoundingBoxPopup(true);
    GA.event(PROJECT, PROJECT_SETTING_WORKER_BOUNDARY_BTN);
  };

  return (
    <ProjectPageLayout project={data}>
      <PUHeader title={nls.projectInfo()}>
        {hasSnapshotListPermission && (
          <SnapshotListButton
            onClick={() => {
              const url = routes.snapshot.list.of({ projectId, zoneId: data.zoneIds[0] });
              history.push(url);
            }}
            endIcon={<ArrowForwardIcon />}
          >
            {nls.projectInfoSnapshotList()}
          </SnapshotListButton>
        )}
      </PUHeader>
      <Content>
        <ContentGroup>
          <Subheading>{nls.projectFormSubheadingInfo()}</Subheading>
          <ContentRow>
            <Label>{nls.projectFieldName()}</Label>
            {data.name}
          </ContentRow>
          <ContentRow>
            <Label>{nls.projectFieldDescription()}</Label>
            {data.description}
          </ContentRow>
          <ContentRow>
            <Label>{nls.projectFieldPeriod()}</Label>
            {convertDateFormat(data.constructionDate)}
            <InlineDivider />
            {convertDateFormat(data.completedDate)}
          </ContentRow>
          <ContentRow>
            <Label>{nls.projectFieldStatus()}</Label>
            {data.state ? nls.projectStatusCompleted() : nls.projectStatusInProgress()}
          </ContentRow>
        </ContentGroup>
        <ContentGroup>
          <Subheading>{nls.projectFormSubheadingInfoDetail()}</Subheading>
          <ContentRow>
            <Label>{nls.projectFieldCoord()}</Label>
            {translate(data.coordinate)}
          </ContentRow>
          <ContentRow>
            <Label>{nls.projectMapProvider()}</Label>
            {localizedmapTypes.find(({ value }) => value === data?.mapProvider)?.label}
          </ContentRow>
          <ContentRow>
            <Label>{nls.measureVolume()}</Label>
            {data.volumeUnit?.name}
          </ContentRow>
        </ContentGroup>
        {!isPosco() && (
          <ContentGroup>
            <Subheading>{nls.projectFormSubheadingLaborerLogoutDistance()}</Subheading>
            <ContentRow>
              <Label>{nls.projectFormDistance()}</Label>
              {data.data?.logoutByDistance}m
            </ContentRow>
          </ContentGroup>
        )}
      </Content>
      <Divider />
      <ButtonsWrapper>
        <LaborerContentsWrapper>
          {QRCodeDownloadEnabled && (
            <StyledTooltip title={nls.projectQRCodeDownloadTooltipMessage()}>
              <ButtonWithBackground startIcon={<GetAppIcon />} onClick={openQRCodeModal}>
                {nls.projectQRCodeDownload()}
              </ButtonWithBackground>
            </StyledTooltip>
          )}
          {!isPosco() && (
            <ButtonWithBackground
              startIcon={<BoundingBoxIcon />}
              onClick={onClickOpenFieldBoundaryDialog}
            >
              {nls.projectFieldBoundaryDialogButton()}
            </ButtonWithBackground>
          )}
        </LaborerContentsWrapper>
        <div>
          {hasProjectDeletePermission && (
            <ButtonDefault onClick={onDeleteClick}>{nls.delete()}</ButtonDefault>
          )}
          {hasProjectEditPermission && (
            <ButtonPrimary onClick={() => history.push(routes.project.edit.of({ projectId }))}>
              {nls.modified()}
            </ButtonPrimary>
          )}
        </div>
      </ButtonsWrapper>
      {QRCodeDownloadEnabled && (
        <WidePopup
          isShowing={QRCodePopupShow}
          closeWidePopup={closeQRCodePopup}
          title={nls.projectQRCodeDownload()}
        >
          <PopupBody>
            <Typography align="center" variant="h5">
              {nls.projectQRCodeForMeiday()}
            </Typography>
            <QRCodeWrapper className={QRCodeWrapperClassName}>
              <QRCodeCanvas
                includeMargin
                size={QRCodeSize}
                style={QRCodeCanvasStyle}
                value={QRCodeValue}
                imageSettings={QRCodeImageSetttings}
              />
            </QRCodeWrapper>
            <PopupBodyText>{nls.projectQRCodeDescription()}</PopupBodyText>
            <CopyFieldWrapper>
              <CopyField text={QRCodeValue} />
            </CopyFieldWrapper>
          </PopupBody>
          <PopupFooter>
            <ButtonWithBackground startIcon={<GetAppIcon />} onClick={downloadQRCode}>
              {nls.projectQRCodeImageDownload()}
            </ButtonWithBackground>
          </PopupFooter>
        </WidePopup>
      )}
      <FieldBoundaryDialog open={boundingBoxPopup} onClose={() => setBoundingBoxPopup(false)} />
    </ProjectPageLayout>
  );
}

function translate(coord: string) {
  const found = datumList.find(({ value }) => value === coord);
  if (found) {
    return `${found.desc}`;
  }
  return `EPSG ${coord}`;
}

const SnapshotListButton = styled(TextButton).attrs({ color: 'primary' })`
  margin: auto 0;
`;

const Content = styled.div`
  padding-top: 4rem;
  padding-bottom: 4rem;
  max-height: calc(100% - 13rem);
  min-height: 10rem;
  ${scrollStyle};
`;

const ContentGroup = styled.div`
  &:not(:last-child) {
    margin-bottom: 3.875rem;
  }
`;

const ContentRow = styled(Typography).attrs({ variant: 'body1', component: 'span' })`
  display: flex;
  color: ${theme.palette.text.primary};
  & + & {
    margin-top: 2.25rem;
  }
`;

const Subheading = styled(Typography).attrs({ variant: 'h6' })`
  display: block;
  margin-bottom: 2rem;
`;

const Label = styled(Typography).attrs({ variant: 'subtitle1', component: 'span' })`
  display: inline-block;
  width: 5rem;
  min-width: 5rem;
  margin-right: 1rem;
  color: ${theme.palette.text.secondary};
  &:lang(en) {
    width: 5.5rem;
    min-width: 5.5rem;
  }
`;

const InlineDivider = styled.span`
  display: inline-block;
  height: 2px;
  width: 1.5rem;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
  background-color: ${theme.palette.divider};
  align-self: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5rem;
  padding-bottom: 3.125rem;

  > :last-child {
    display: flex;
    gap: 1rem;
  }
`;

const ButtonWithBackground = styled(TextButtonWithBackground).attrs({
  color: 'primary',
  size: 'large',
})``;

const StyledTooltip = withStyles({
  tooltip: { maxWidth: 'none' },
})(Tooltip);

const PopupBody = styled.div`
  padding: 3.75rem 2.5rem;
`;

const PopupBodyText = styled(Typography).attrs({ align: 'center', variant: 'body2' })`
  white-space: pre-line;
`;

const PopupFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  padding: 2rem 4rem 2.5rem;
  border-top: 1px solid ${others.DIVIDER};
`;

const QRCodeWrapper = styled.div`
  canvas {
    display: block;
    margin: 0.125rem auto;
  }
`;

const CopyFieldWrapper = styled.div`
  padding: 0 4.5rem;
`;

const LaborerContentsWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;
