import { atom } from 'jotai';

type GlobalSnackbarAtom = {
  open: boolean;
  message: string;
  autoHideDuration?: number;
  icon?: React.ReactNode;
  type?: 'info' | 'success' | 'warning' | 'error' | 'custom';
  anchorOrigin?: 'topCenter' | 'bottomCenter';
};

export const globalSnackbarAtom = atom<GlobalSnackbarAtom>({
  open: false,
  message: '',
  type: 'info',
  icon: null,
  autoHideDuration: 3000,
  anchorOrigin: 'topCenter',
});
