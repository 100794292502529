import { FeatureCollection } from '@turf/helpers';
import { isPositioning } from 'shared/query/resource/typeGuards';
import {
  BaseResource,
  DrawingType,
  getRasterTmsUrl,
  ProgressStatus,
  RasterResource,
} from 'shared/query/resource/types';
import { LoadingStatus } from 'stores/data/types';

export type DrawingData = {
  status?: {
    list?: LoadingStatus;
    create?: LoadingStatus;
  };
  data?: Drawing[];
  item?: Drawing;
  uploadingData?: UploadingDrawing[];
  vectorLayers?: { [id: number]: FeatureCollection[] };
};

export type UploadingDrawing = {
  id?: number;
  tempId?: number;
  name?: string;
  type?: DrawingType;
  size?: number;
  file?: File;
  status?: LoadingStatus;
};

export type Drawing = DrawingRaster | DrawingVector | DrawingPositioning;

export type DrawingRaster = RasterResource<'DRAWING_RASTER'>;

export interface PositioningData {
  preview: string;
  variant: string;
  progressStatus: ProgressStatus;
  progressPercentage: number;
  id: number;
  data: {
    r: number;
    theta: number;
    center: [number, number];
    rotationAngle: number;
    opacity: number;
  };
}
export type DrawingPositioning = BaseResource<'DRAWING_POSITIONING', PositioningData>;

export interface VectorData {
  progressStatus: ProgressStatus;
  progressPercentage: number;
  coordinate: string;
  vectorSet: { file: string }[];
}
export type DrawingVector = BaseResource<'DRAWING_VECTOR', VectorData>;

export const isConversion = (d: Drawing) => getProgressStatus(d) === 'CONVERSION';
export const isEnhanceQuality = (d: Drawing) => getProgressStatus(d) === 'ENHANCE_QUALITY';
export const isError = (d: Drawing) => getProgressStatus(d) === 'ERROR';
export const isCanceled = (d: Drawing) => getProgressStatus(d) === 'CANCEL';
export const isDone = (d: Drawing) => getProgressStatus(d) === 'DONE';
export const getProgressStatus = (d: Drawing) => {
  return d?.conversionData?.progressStatus || '';
};
export const getPositioningData = (drawing: DrawingPositioning) => {
  return drawing?.conversionData?.data;
};
export const hasPositionData = (drawing: DrawingPositioning) => {
  const data = getPositioningData(drawing);
  return data?.r && data?.theta && data?.center && data?.rotationAngle != null;
};
export const getDrawingImageSrc = (drawing: DrawingPositioning) => {
  return drawing?.conversionData?.variant;
};

export const getDrawingRasterTmsUrl = (drawing: DrawingRaster) => {
  return getRasterTmsUrl(drawing, '@2x');
};
export const getTmsMeta = (drawing: DrawingRaster) => drawing?.conversionData?.meta;

export const getZoomLevelFromRasterMeta = (drawing: DrawingRaster) => {
  return getTmsMeta(drawing)?.zoomLevel;
};

export const getVectorSet = (drawing: DrawingVector) => drawing?.conversionData?.vectorSet;

/** TODO: B.E 와 협의 후 Opacity Data 저장 위치 정하기
 * or 수동 도면의 Opacity 저장을 제외할 수도 있음
 */
export const getOpacity = (d: Drawing) => {
  if (isPositioning(d)) {
    return (d as DrawingPositioning)?.conversionData?.data?.opacity;
  }
  return null;
};
