import React, { lazy } from 'react';
const FindPasswordPage = lazy(
  () =>
    import(/* webpackChunkName: "findPwPage" */ 'features/user/findPasswordPage/FindPassword'),
);

function FindPw() {
  return <FindPasswordPage />;
}

export default FindPw;
