import Folder from '@mui/icons-material/Folder';
import { Typography } from '@mui/material';
import React from 'react';
import { nls } from 'shared/locale/language';
import theme from 'shared/styles/mui/theme';
import styled from 'styled-components';

export default function ProjectListEmptyView() {
  return (
    <Container>
      <FolderIcon />
      <StyledTypography variant="h6" color="textSecondary" align="center" gutterBottom>
        {nls.projectEmptyViewMessage()}
      </StyledTypography>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 20rem);
  gap: 1rem;
`;

const FolderIcon = styled(Folder)`
  &.MuiSvgIcon-root {
    width: 5rem;
    height: 5rem;
    fill: ${theme.palette.action.disabledBackground};
  }
`;

const StyledTypography = styled(Typography)`
  color: ${theme.palette.text.disabled};
  text-align: center;
  white-space: pre-wrap;
`;
