import { apiAxios } from 'shared/common/api/common';
import { PermissionType } from 'shared/common/types';

export default {
  async update({
    projectId,
    projectUserId,
    data,
  }: {
    projectId: number;
    projectUserId: number;
    data: { permission: PermissionType };
  }) {
    const response = await apiAxios.patch(
      `api/projects/${projectId}/projectusers/${projectUserId}`,
      data,
    );
    return response?.data;
  },
};
