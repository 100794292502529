import React, { lazy } from 'react';
const CompareByGreenPage = lazy(
  () =>
    import(
      /* webpackChunkName: "compareByGreenPage" */ 'features/snapshot/comparePage/green/CompareByGreenPage'
    ),
);

function CompareGreenByGreen() {
  return <CompareByGreenPage />;
}

export default CompareGreenByGreen;
