import * as category from '../category';

/* 작업일지 보관함 & 검색 */
export const DAILY_SCHEDULE_ENTRY_BTN = `${category.DAILY_SCHEDULE}EntryBtn`;
export const DAILY_SCHEDULE_NEW_CREATE_BTN = `${category.DAILY_SCHEDULE}NewCreateBtn`;
export const DAILY_SCHEDULE_COPY_CREATE_BTN = `${category.DAILY_SCHEDULE}CopyCreateBtn`;
export const DAILY_SCHEDULE_ARCHIVE_PRE_BTN = `${category.DAILY_SCHEDULE}ArchivePreBtn`;
export const DAILY_SCHEDULE_ARCHIVE_NEXT_BTN = `${category.DAILY_SCHEDULE}ArchiveNextBtn`;
export const DAILY_SCHEDULE_ARCHIVE_CALENDAR_BTN = `${category.DAILY_SCHEDULE}ArchiveCalendarBtn`;
export const DAILY_SCHEDULE_SAVE_BTN = `${category.DAILY_SCHEDULE}SaveBtn`;
export const DAILY_SCHEDULE_EDIT_BTN = `${category.DAILY_SCHEDULE}EditBtn`;
export const DAILY_SCHEDULE_DEL_BTN = `${category.DAILY_SCHEDULE}DelBtn`;
export const DAILY_SCHEDULE_SEARCH_DAY_SORT = `${category.DAILY_SCHEDULE}SearchDaySort`;
export const DAILY_SCHEDULE_SEARCH_WEEK_SORT = `${category.DAILY_SCHEDULE}SearchWeekSort`;
export const DAILY_SCHEDULE_SEARCH_MONTH_SORT = `${category.DAILY_SCHEDULE}SearchMonthSort`;
export const DAILY_SCHEDULE_SEARCH_DATE_SORT = `${category.DAILY_SCHEDULE}SearchDateSort`;
export const DAILY_SCHEDULE_SEARCH_CALENDAR_BTN = `${category.DAILY_SCHEDULE}SearchCalendarBtn`;
export const DAILY_SCHEDULE_SEARCH_LOCAL_BTN = `${category.DAILY_SCHEDULE}SearchLocalBtn`;
export const DAILY_SCHEDULE_SEARCH_SEARCH_BTN = `${category.DAILY_SCHEDULE}SearchSearchBtn`;
export const DAILY_SCHEDULE_SEARCH_JOB_BTN = `${category.DAILY_SCHEDULE}SearchJobTab`;
export const DAILY_SCHEDULE_SEARCH_FERTILE_BTN = `${category.DAILY_SCHEDULE}SearchFertileTab`;
/* 엑셀 내보내기 */
export const DAILY_SCHEDULE_EXCEL_EXPORT_BTN = `${category.DAILY_SCHEDULE}ExcelExportEntryBtn`;
export const DAILY_SCHEDULE_EXCEL_EXPORT_START_BTN = `${category.DAILY_SCHEDULE}ExcelExportStartBtn`;
export const DAILY_EXCEL_EXPORT_END_BTN = `${category.DAILY_SCHEDULE}ExcelExportEndBtn`;
export const DAILY_EXCEL_EXPORT_BTN = `${category.DAILY_SCHEDULE}ExcelExportBtn`;
/* 이전 작업일지 복사 */
export const DAILY_SCHEDULE_COPY_CREATE_PREV_MO_BTN = `${category.DAILY_SCHEDULE}CopyCreatePrevMoBtn`;
export const DAILY_SCHEDULE_COPY_CREATE_NEXT_MO_BTN = `${category.DAILY_SCHEDULE}CopyCreateNextMoBtn`;
export const DAILY_SCHEDULE_COPY_CREATE_DLG_BTN = `${category.DAILY_SCHEDULE}CopyCreateDlgBtn`;
