/* 포인트 클라우드 las 목록 조회 */
import {
  PointCloudListParams,
  PointCloudResolutionParams,
} from 'shared/query/resource/pointCloud/types';
import { useMutation, useQuery, UseQueryOptions } from 'react-query';
import { keys } from 'shared/query/resource/pointCloud/queryKeys';
import api from 'shared/query/resource/pointCloud/api';
import { AxiosError } from 'axios';
import { Error } from 'shared/common/types';

export function useResourcePointCloudListQuery(
  { snapshotId }: PointCloudListParams,
  options?: UseQueryOptions<any, AxiosError<Error>>,
) {
  return useQuery(
    keys.getPointCloudList({ snapshotId }),
    () => api.getResourcePointCloudList({ snapshotId }),
    { ...options },
  );
}

/* 포인트 클라우드 las 해상도 변환 요청 */
export function useRequestPointCloudResolutionMutation() {
  const requestResolution = useMutation(
    ({ pointCloudId, resolution }: PointCloudResolutionParams) => {
      return api.requestResourcePointCloudResolution({ pointCloudId, resolution });
    },
  );
  return {
    requestResolution,
  };
}
