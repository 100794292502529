import { useZoneListQuery } from 'shared/query';
import { useEffect, useState } from 'react';
import { LatLngBoundsLiteral } from 'leaflet';
import { useMatchParams } from 'features/common/hooks';

function UseModalData() {
  const { projectId } = useMatchParams();
  const { data: zoneData } = useZoneListQuery({ projectId });
  const [boundingBox, setBoundingBox] = useState<LatLngBoundsLiteral[]>([]);

  const boundingBoxData =
    zoneData?.map((z) => z.latestSnapshot?.conversionData?.meta?.boundingBox) ?? [];
  const hasBoundingBoxData =
    boundingBoxData.length > 0 && !boundingBoxData.every((box) => box === undefined);

  useEffect(() => {
    if (!hasBoundingBoxData) return;
    setBoundingBox(
      boundingBoxData.map((z) => {
        if (!z) return [];
        return [
          [+z.miny, +z.minx],
          [+z.maxy, +z.maxx],
        ];
      }),
    );
  }, [zoneData]);

  return {
    zoneData,
    hasBoundingBoxData,
    boundingBox,
  };
}

export default UseModalData;
