import React, { lazy } from 'react';
const ProjectLaborerHistoryPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ProjectLaborerHistoryPage" */ 'features/project/laborerHistoryPage/ProjectLaborerHistoryPage'
    ),
);

function LaborerHistory() {
  return <ProjectLaborerHistoryPage />;
}

export default LaborerHistory;
