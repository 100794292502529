import { AnnotationType } from 'shared/query/annotation/types';

export const keys = {
  drawing: { list: (zoneId: number) => ['folder', 'drawing', 'list', { zoneId }] },
  annotation: {
    list: (zoneId: number, type: AnnotationType) => [
      'folder',
      'annotation',
      'list',
      { zoneId, type },
    ],
  },
};
