import axios, { AxiosError, CancelToken } from 'axios';
import { DEFAULT_QUERY_STALE_TIME } from 'shared/common/policies/request';
import { Error } from 'shared/common/types';
import { requestUploadFile, s3DirectFileUpload } from 'shared/query/fileUpload/s3Upload';
import { Permission } from 'shared/query/permission/types';
import { RelationResource } from 'shared/query/relation/types';
import { PrintOutRequestParam, Resource } from 'shared/query/resource/types';
import {
  UseQueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { S3FileUploadType } from '../fileUpload/types';
import api from './api';
import { keys } from './queryKeys';
import { ResourceType, TempFile } from './types';

type Props = {
  snapshotId: number;
  resourceId: number;
  projectId: number;
  zoneId: number;
  data: any;
  tempFile: TempFile;
  type: ResourceType;
  permission: Permission;
  token: string;
};
type UseResourceListQueryParams = Pick<Props, 'snapshotId' | 'permission'> &
  Partial<Pick<Props, 'type' | 'token'>>;

export function useResourceListQuery<T extends Resource>(
  { snapshotId, type, permission, token = '' }: UseResourceListQueryParams,
  options?: UseQueryOptions<T[], AxiosError<Error>, T[]>,
): {
  resources: T[];
  status: UseQueryResult['status'];
  refetch: UseQueryResult['refetch'];
} {
  const { enabled = true, ...rest } = options || {};
  const { data, status, refetch } = useQuery<T[], AxiosError<Error>, T[]>(
    keys.listBySnapshotId(snapshotId, type),
    () => api.listBySnapshotId<T>({ snapshotId, type, token }),
    {
      staleTime: DEFAULT_QUERY_STALE_TIME,
      enabled: Boolean(snapshotId && permission?.snapshotfileGet && enabled),
      ...rest,
    },
  );

  return { resources: (data ?? []) as T[], status, refetch };
}

export function useResourceListByZoneIdQuery(
  { zoneId, snapshotId, type }: Pick<Props, 'zoneId' | 'snapshotId' | 'type'>,
  options?: UseQueryOptions<RelationResource[], AxiosError<Error>>,
) {
  const { enabled = true, ...rest } = options ?? {};
  const { data, status } = useQuery(
    keys.listByZoneId(zoneId, snapshotId, type),
    () => api.listByZoneId({ snapshotId, zoneId, type }),
    {
      staleTime: DEFAULT_QUERY_STALE_TIME,
      enabled: Boolean(zoneId && type && enabled),
      ...rest,
    },
  );
  return { resources: (data ?? []) as RelationResource[], status };
}
export function useResourceListByProjectIdQuery<T extends Resource>(
  { projectId, type }: Pick<Props, 'projectId'> & Partial<Pick<Props, 'type'>>,
  options?: UseQueryOptions<T[], AxiosError<Error>, T[]>,
) {
  const { enabled, ...rest } = options ?? {};
  const { data, status } = useQuery(
    keys.listByProjectId(projectId, type),
    () => api.listByProjectId<T>({ projectId, type }),
    {
      staleTime: DEFAULT_QUERY_STALE_TIME,
      enabled: Boolean(projectId && enabled),
      ...rest,
    },
  );
  return { resources: (data ?? []) as T[], status };
}

export function useResourceQuery<T extends Resource>(
  { snapshotId, resourceId }: Pick<Props, 'snapshotId' | 'resourceId'>,
  options?: UseQueryOptions<T, AxiosError<Error>>,
) {
  const queryClient = useQueryClient();
  const { data, status } = useQuery<T, AxiosError<Error>>(
    keys.detail(resourceId),
    () => api.read({ snapshotId, resourceId }),
    {
      staleTime: DEFAULT_QUERY_STALE_TIME,
      enabled: Boolean(snapshotId && resourceId),
      onSuccess: (data) => {
        queryClient.setQueriesData(keys.list(), (prev: Resource[]) =>
          prev?.map((item) => (item.id === data.id ? data : item)),
        );
      },
      ...options,
    },
  );
  return { resource: data, status };
}

/**
 * 해당 작업은 레커시 api 를 활용한 임시적인 처리 입니다. 추후 api 교체가 필요 합니다.
 * 눈에 뛸수 있도록 쀼쪽에 코드를 놓아 두겠습니다.
 */
export function useResourceMutationQuery() {
  const getResource = useMutation(
    ({ snapshotId, resourceId }: Pick<Props, 'snapshotId' | 'resourceId'>) => {
      return api.read({ snapshotId, resourceId });
    },
  );
  const getResourceByProjectId = useMutation(
    ({ projectId, resourceId }: Pick<Props, 'projectId' | 'resourceId'>) => {
      return api.readByProjectId({ projectId, resourceId });
    },
  );
  return { getResource, getResourceByProjectId };
}

type CreationProps = Pick<Props, 'snapshotId' | 'tempFile'> & {
  onSuccess?: (tempFile) => void;
  onError?: (tempFile: TempFile, errorCode?: string) => void;
  onCancel?: (tempFile) => void;
  cancelToken?: CancelToken;
};

export function useResourceCreation() {
  const queryClient = useQueryClient();
  return useMutation<Resource, AxiosError<{ errorCode: string; message: string }>, CreationProps>(
    async ({ snapshotId, tempFile, cancelToken }: CreationProps) => {
      const { data: dataForUploads } = await requestUploadFile(tempFile);

      const { fields } = dataForUploads as S3FileUploadType;
      await s3DirectFileUpload(dataForUploads, tempFile?.file, cancelToken);

      const resource = await createResource(snapshotId, tempFile, fields, cancelToken);
      return resource;
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(keys.listByType(data?.type));
        variables?.onSuccess({ ...data, ...variables.tempFile });
      },
      onError: (error, variables) => {
        if (axios.isCancel(error)) {
          variables?.onCancel && variables.onCancel(variables.tempFile);
        } else {
          variables?.onError(variables.tempFile, error?.response?.data?.errorCode);
        }
      },
    },
  );
}

function createResource(
  snapshotId: number,
  tempFile: TempFile,
  fields: S3FileUploadType['fields'],
  cancelToken: CancelToken,
) {
  return api.create({
    snapshotId,
    type: tempFile?.type,
    name: tempFile?.name,
    filename: fields?.key || fields?.Key,
    additional: tempFile?.additional || {},
    cancelToken,
  });
}

export function useResourceMutation() {
  const queryClient = useQueryClient();
  const updateResource = useMutation((payload: any) => api.update(payload), {
    onSuccess: (res, v) => {
      queryClient.invalidateQueries(keys.detail(v.resourceId));
    },
  });
  const deleteResource = useMutation(
    ({ zoneId, resourceId }: { zoneId: number; resourceId: Resource['id']; type: ResourceType }) =>
      api.delete({ zoneId, resourceId }),
    {
      onSuccess: (_, v) => {
        queryClient.invalidateQueries(keys.listByType(v?.type));
      },
    },
  );
  const deleteResources = useMutation(
    ({ zoneId, resourceIds }: { zoneId: number; resourceIds: Resource['id'][] }) =>
      api.deleteBulk({ zoneId, resourceIds }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(keys.list());
      },
    },
  );
  const archiveResources = useMutation(
    ({ zoneId, resourceIds }: { zoneId: number; resourceIds: Resource['id'][] }) =>
      api.archive({ zoneId, resourceIds }),
  );
  const deleteEtc = useMutation(
    ({ projectId, resource }: { projectId: number; resource: Resource | RelationResource }) =>
      api.deleteEtc({ projectId, resourceId: resource?.id }),
    {
      onSuccess: (_, v) => {
        queryClient.invalidateQueries(keys.listByType(v?.resource?.type));
      },
    },
  );
  return { deleteResource, deleteResources, updateResource, archiveResources, deleteEtc };
}

export function usePrintoutCreation() {
  return useMutation(({ snapshotId, data }: { snapshotId: number; data: PrintOutRequestParam }) =>
    api.createWithoutFile({ snapshotId, data }),
  );
}

export function useBimTilesetMutation() {
  const queryClient = useQueryClient();
  const refreshAccessToken = useMutation(
    ({ zoneId }: Pick<Props, 'zoneId'>) => api.bim.refreshToken({ zoneId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(keys.listByType('BIM'));
      },
    },
  );
  return { refreshAccessToken };
}
