import { Z_INDEX } from 'shared/common/policies/2d';
import {
  MAX_ZOOM,
  MIN_ZOOM,
  UPDATE_WHEN_IDLE,
  UPDATE_WHEN_ZOOMING,
} from 'shared/common/policies/map';
import { getBounds, getRasterTmsUrl } from 'shared/query/resource/types';
import { Zone } from 'shared/query/zone/types';
import React, { useMemo } from 'react';
import { TileLayer } from 'react-leaflet';

interface Props {
  zoneData: Zone;
}

function OrthophotoLayer({ zoneData }: Props) {
  const waitingOrthophoto = !zoneData || !zoneData?.latestSnapshot;
  if (waitingOrthophoto) return null;

  const snapshotUrl = zoneData.latestSnapshot;
  const boundBox = zoneData.latestSnapshot.conversionData.meta.tileMap.boundingBox;
  const { zoomLevel } = zoneData.latestSnapshot.conversionData.meta;

  const bounds = useMemo(() => {
    if (!zoneData) return null;
    return getBounds(boundBox);
  }, [zoneData]);

  return (
    <TileLayer
      key={`field-boundary-orthophoto-${snapshotUrl.id}`}
      tms
      url={getRasterTmsUrl(snapshotUrl)}
      bounds={bounds}
      maxZoom={MAX_ZOOM}
      minZoom={MIN_ZOOM}
      maxNativeZoom={zoomLevel.max}
      minNativeZoom={zoomLevel.min}
      updateWhenZooming={UPDATE_WHEN_ZOOMING}
      updateWhenIdle={UPDATE_WHEN_IDLE}
      zIndex={Z_INDEX.ORTHOPHOTO}
    />
  );
}

export default OrthophotoLayer;
