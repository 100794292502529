import * as category from '../category';

/* 프로젝트 하위 */
export const PHOTO_BOX_BTN = `${category.PHOTO_BOX}BTN`;
export const PHOTO_BOX_CHECK_BOX = `${category.PHOTO_BOX}Cbox`;
export const PHOTO_BOX_OPEN_SECONDARY_BTN = `${category.PHOTO_BOX}OpenSecondaryBtn`;
export const PHOTO_BOX_EDIT_BTN = `${category.PHOTO_BOX}EditBtn`;
export const PHOTO_BOX_RESET_BTN = `${category.PHOTO_BOX}ResetBtn`;
export const PHOTO_BOX_DOWN_BTN = `${category.PHOTO_BOX}DownBtn`;
export const PHOTO_BOX_DELETE_BTN = `${category.PHOTO_BOX}DelBtn`;
export const PHOTO_BOX_DETAIL_BTN = `${category.PHOTO_BOX}DetailBtn`;
export const PHOTO_BOX_UPLOAD_BTN = `${category.PHOTO_BOX}UploadBtn`;
export const PHOTO_BOX_UPLOAD_SUCCESS = `${category.PHOTO_BOX}UploadSuccess`;
/* 포토박스 - 전체 메뉴 */
export const PHOTO_BOX_ALL_MENU = `${category.PHOTO_BOX}AllMenu`;
export const PHOTO_BOX_ALL_MENU_ALBUM_ADD_BTN = `${category.PHOTO_BOX}AllMenuAlbumAddBtn`;
export const PHOTO_BOX_ALBUM_ADD_POPUP_ALBUM_ADD_BTN = `${category.PHOTO_BOX}AlbumAddPopUpAlbumAddBtn`;
export const PHOTO_BOX_ALBUM_ADD_POPUP_ALBUM_EDIT_BTN = `${category.PHOTO_BOX}AlbumAddPopUpAlbumEditBtn`;
export const PHOTO_BOX_ALL_MENU_EDIT_BTN = `${category.PHOTO_BOX}AllMenuEditBtn`;
export const PHOTO_BOX_ALL_MENU_DOWN_BTN = `${category.PHOTO_BOX}AllMenuDownBtn`;
export const PHOTO_BOX_ALL_MENU_DELETE_BTN = `${category.PHOTO_BOX}AllMenuDelBtn`;
/* 포토박스 - 앨범 메뉴 */
export const PHOTO_BOX_ALBUM_MENU = `${category.PHOTO_BOX}AlbumMenu`;
export const PHOTO_BOX_ALBUM_MENU_ALBUM_ADD_BTN = `${category.PHOTO_BOX}AlbumMenuAlbumAddBtn`;
export const PHOTO_BOX_ALBUM_MENU_ALBUM_NAME_EDIT_BTN = `${category.PHOTO_BOX}AlbumMenuAlbumNameEditBtn`;
export const PHOTO_BOX_ALBUM_MENU_EDIT_BTN = `${category.PHOTO_BOX}AlbumMenuEditBtn`;
export const PHOTO_BOX_ALBUM_MENU_DELETE_FROM_ALBUM_BTN = `${category.PHOTO_BOX}AlbumMenuDelFromAlbumBtn`;
export const PHOTO_BOX_ALBUM_MENU_DOWN_BTN = `${category.PHOTO_BOX}AlbumMenuDownBtn`;
export const PHOTO_BOX_ALBUM_MENU_DELETE_BTN = `${category.PHOTO_BOX}AlbumMenuDelBtn`;
/* 미디어 상세 팝업 */
export const PHOTO_BOX_DETAIL_PHOTO_EDIT_BTN = `${category.PHOTO_BOX}DetailPhotoEditBtn`;
export const PHOTO_BOX_DETAIL_PHOTO_EDIT_SUCCESS = `${category.PHOTO_BOX}DetailPhotoEditSuccess`;
export const PHOTO_BOX_DETAIL_PHOTO_ALBUM_ADD_BTN = `${category.PHOTO_BOX}DetailPhotoAlbumAddBtn`;
export const PHOTO_BOX_DETAIL_PHOTO_DOWN_BTN = `${category.PHOTO_BOX}DetailPhotoDownBtn`;
export const PHOTO_BOX_DETAIL_PHOTO_DELETE_BTN = `${category.PHOTO_BOX}DetailPhotoDelBtn`;
export const PHOTO_BOX_DETAIL_VIDEO_EDIT_BTN = `${category.PHOTO_BOX}DetailVideoEditBtn`;
export const PHOTO_BOX_DETAIL_VIDEO_EDIT_SUCCESS = `${category.PHOTO_BOX}DetailVideoEditSuccess`;
export const PHOTO_BOX_DETAIL_VIDEO_ALBUM_ADD_BTN = `${category.PHOTO_BOX}DetailVideoAlbumAddBtn`;
export const PHOTO_BOX_DETAIL_VIDEO_DOWN_BTN = `${category.PHOTO_BOX}DetailVideoDownBtn`;
export const PHOTO_BOX_DETAIL_VIDEO_DELETE_BTN = `${category.PHOTO_BOX}DetailVideoDelBtn`;
/* 기타 */
export const PHOTO_BOX_UPLOAD_DRAG_DROP = `${category.PHOTO_BOX}UploadDragDrop`;
/* 검색 */
export const PHOTO_BOX_ALL_MENU_SEARCH_BTN = `${category.PHOTO_BOX}AllMenuSearchBtn`;
export const PHOTO_BOX_ALL_MENU_SEARCH_DATE_ALL_BTN = `${category.PHOTO_BOX}AllMenuSearchDateAllBtn`;
export const PHOTO_BOX_ALL_MENU_SEARCH_DATE_DAY_BTN = `${category.PHOTO_BOX}AllMenuSearchDateDayBtn`;
export const PHOTO_BOX_ALL_MENU_SEARCH_DATE_WEEK_BTN = `${category.PHOTO_BOX}AllMenuSearchDateWeekBtn`;
export const PHOTO_BOX_ALL_MENU_SEARCH_DATE_MONTH_BTN = `${category.PHOTO_BOX}AllMenuSearchDateMonthBtn`;
export const PHOTO_BOX_ALL_MENU_SEARCH_DATE_PERIOD_BTN = `${category.PHOTO_BOX}AllMenuSearchDatePeriodBtn`;
export const PHOTO_BOX_ALL_MENU_SEARCH_ZONE_BTN = `${category.PHOTO_BOX}AllMenuSearchZoneBtn`;
export const PHOTO_BOX_ALL_MENU_SEARCH_NAME_BTN = `${category.PHOTO_BOX}AllMenuSearchNameBtn`;
export const PHOTO_BOX_ALL_MENU_SEARCH_EXTRA_BTN = `${category.PHOTO_BOX}AllMenuSearchExtraBtn`;
export const PHOTO_BOX_ALL_MENU_SEARCH_MEMO_BTN = `${category.PHOTO_BOX}AllMenuSearchMemoBtn`;
export const PHOTO_BOX_ALL_MENU_SORT_NEWEST = `${category.PHOTO_BOX}AllMenuSortNewest`;
export const PHOTO_BOX_ALL_MENU_SORT_OLDEST = `${category.PHOTO_BOX}AllMenuSortOldest`;
export const PHOTO_BOX_UPLOAD_ZONE_BTN = `${category.PHOTO_BOX}UploadZoneBtn`;
export const PHOTO_BOX_UPLOAD_ALBUM_BTN = `${category.PHOTO_BOX}UploadAlbumBtn`;
export const PHOTO_BOX_UPLOAD_EXTRA_BTN = `${category.PHOTO_BOX}UploadExtraBtn`;
export const PHOTO_BOX_UPLOAD_MEMO_BTN = `${category.PHOTO_BOX}UploadMemoBtn`;
export const PHOTO_BOX_EDIT_POPUP_ZONE_BTN = `${category.PHOTO_BOX}EditPopupZoneBtn`;
export const PHOTO_BOX_EDIT_POPUP_ALBUM_BTN = `${category.PHOTO_BOX}EditPopupAlbumBtn`;
export const PHOTO_BOX_EDIT_POPUP_EXTRA_BTN = `${category.PHOTO_BOX}EditPopupExtraBtn`;
export const PHOTO_BOX_EDIT_POPUP_MEMO_BTN = `${category.PHOTO_BOX}EditPopupMemoBtn`;
export const PHOTO_BOX_SEARCH_DATE_ALL_BTN = `${category.PHOTO_BOX}SearchDateAllBtn`;
export const PHOTO_BOX_SEARCH_DATE_DAY_BTN = `${category.PHOTO_BOX}SearchDateDayBtn`;
export const PHOTO_BOX_SEARCH_DATE_WEEK_BTN = `${category.PHOTO_BOX}SearchDateWeekBtn`;
export const PHOTO_BOX_SEARCH_DATE_MONTH_BTN = `${category.PHOTO_BOX}SearchDateMonthBtn`;
export const PHOTO_BOX_SEARCH_DATE_PERIOD_BTN = `${category.PHOTO_BOX}SearchDatePeriodBtn`;
