import React, { lazy } from 'react';
const EngineReportPage = lazy(
  () =>
    import(
      /* webpackChunkName: "EngineReportPage" */ 'features/snapshot/engineReportPage/EngineReportPage'
    ),
);

function EnginReport() {
  return <EngineReportPage />;
}

export default EnginReport;
