import { BING_MAP_OPTIONS, GOOGLE_MAP_OPTIONS } from 'shared/common/policies/map';
import { useMatchParams } from 'features/common/hooks';
import { useProjectQuery, useVworldMapKeyQuery } from 'shared/query';
import React from 'react';
import { BingLayer } from 'react-leaflet-bing-v2';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import VWorldSatellite from './VWorldSatellite';

export default function SatelliteLayer(props) {
  const { projectId } = useMatchParams();
  const { data: project } = useProjectQuery(projectId);
  const { mapProvider } = project || {};
  const { data: vWorldMapKey } = useVworldMapKeyQuery();

  if (vWorldMapKey && mapProvider === 'VWORLD') {
    return <VWorldSatellite mapKey={vWorldMapKey} {...props} />;
  }

  if (mapProvider === 'BING') {
    return <BingLayer {...BING_MAP_OPTIONS} {...props} />;
  }

  return <ReactLeafletGoogleLayer {...GOOGLE_MAP_OPTIONS} />;
}
