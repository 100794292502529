import { apiAxios } from 'shared/common/api/common';

export default {
  create({ resourceId, snapshotId }: { resourceId: number; snapshotId: number }) {
    return apiAxios.post(`/api/v3/resources/${resourceId}/relations`, { snapshotId });
  },
  delete({ resourceId, snapshotRelationId }: { resourceId: number; snapshotRelationId: number }) {
    return apiAxios.delete(`/api/v3/resources/${resourceId}/relations/${snapshotRelationId}`);
  },
  annotation: {
    create({ annotationId, snapshotId }: { annotationId: number; snapshotId: number }) {
      return apiAxios.post(`/api/v3/annotations/${annotationId}/relations`, { snapshotId });
    },
    delete({
      annotationId,
      snapshotRelationId,
    }: {
      annotationId: number;
      snapshotRelationId: number;
    }) {
      return apiAxios.delete(`/api/v3/annotations/${annotationId}/relations/${snapshotRelationId}`);
    },
  },
  chain: {
    create({ resourceId, snapshotId }: { resourceId: number; snapshotId: number }) {
      return apiAxios.post(`/api/v3/earthwork-crossing-lines/${resourceId}/relations`, {
        snapshotId,
      });
    },
    delete({ resourceId, snapshotRelationId }: { resourceId: number; snapshotRelationId: number }) {
      return apiAxios.delete(
        `/api/v3/earthwork-crossing-lines/${resourceId}/relations/${snapshotRelationId}`,
      );
    },
  },
};
