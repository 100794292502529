import { apiAxios } from 'shared/common/api/common';
import {
  Project,
  ProjectPagination,
  ProjectPaginationParams,
  ProjectUnit,
} from 'shared/query/project/types';

/** @deprecated features 하위로 이동 완료 후 삭제 필요 */
export default {
  async create({ data }: { data: Partial<Project> }) {
    const response = await apiAxios.post(`/api/v2/projects`, data);
    return response.data;
  },
  async read({ projectId }: { projectId: number }) {
    const response = await apiAxios.get(`/api/v2/projects/${projectId}`);
    return response.data;
  },
  async update({ projectId, data }: { projectId: number; data: Partial<Project> }) {
    const response = await apiAxios.patch(`/api/v2/projects/${projectId}`, data);
    return response.data;
  },
  async delete({ projectId }: { projectId: number }) {
    const response = await apiAxios.delete(`/api/v2/projects/${projectId}`);
    return response.data;
  },
  async list({ searchKeyword, order = 'CONSTRUCT_DATE_DESC', page }: ProjectPaginationParams) {
    const response = await apiAxios.get<ProjectPagination>('/api/v4/projects', {
      params: {
        limit: 80,
        name: searchKeyword,
        order,
        page,
      },
    });
    return response.data;
  },
  async units({ projectId }: { projectId: number }) {
    const response = await apiAxios.get<{ results: ProjectUnit[] }>(
      `/api/v3/projects/${projectId}/units`,
    );
    return response.data?.results;
  },
  async unitCreate({ projectId, data }: { projectId: number; data: any }) {
    const response = await apiAxios.post(`/api/v3/projects/${projectId}/units`, data);
    return response.data;
  },
  async unitDelete({ projectId, unitId }: { projectId: number; unitId: number }) {
    const response = await apiAxios.delete(`/api/v3/projects/${projectId}/units/${unitId}`);
    return response.data;
  },
};
