import { apiAxios } from 'shared/common/api/common';
import {
  DrawingVector,
  DrawingVectorResource,
  DrawingVectorSummary,
  DrawingVectorVersion,
} from './types';

export default {
  list: async ({ zoneId }: { zoneId: number }) => {
    const response = await apiAxios.get<{ results: DrawingVectorResource[] }>(
      `/api/v3/zones/${zoneId}/resources?type=POLYGON_VECTOR`,
    );
    return (response?.data?.results ?? []) as DrawingVectorResource[];
  },
  async create({ snapshotId, data }: { snapshotId: number; data }): Promise<DrawingVectorResource> {
    const response = await apiAxios.post(
      `/api/v3/snapshots/${snapshotId}/resources/resource-to-annotation`,
      data,
    );
    return response?.data;
  },

  async readResource({
    snapshotId,
    id,
  }: {
    snapshotId: number;
    id: number;
  }): Promise<DrawingVectorResource> {
    const response = await apiAxios.get(
      `/api/v3/snapshots/${snapshotId}/resources/resource-to-annotation/${id}`,
    );
    return response?.data;
  },

  async summaries({ snapshotId }: { snapshotId: number }): Promise<DrawingVectorSummary[]> {
    const response = await apiAxios.get(
      `/api/v3/snapshots/${snapshotId}/resources/resource-to-annotation?type=POLYGON_VECTOR`,
    );
    return response?.data?.results || [];
  },

  async read({
    projectId,
    resourceId,
  }: {
    projectId: number;
    resourceId: number;
  }): Promise<DrawingVector> {
    const response = await apiAxios.get(
      `/api/v4/earthwork/project/${projectId}/subgrade-quantity/polygon-vector/${resourceId}/bulk-polygon`,
    );
    return response?.data?.result;
  },

  async update({
    drawingVectorId,
    data,
  }: {
    drawingVectorId: number;
    data: Partial<DrawingVector>;
  }): Promise<DrawingVector> {
    const response = await apiAxios.post(
      `/api/v4/earthwork/subgrade-quantity/polygon-vector/${drawingVectorId}`,
      data,
    );
    return response?.data;
  },

  async delete({ snapshotId, id }: { snapshotId: number; id: number }) {
    const response = await apiAxios.delete(
      `/api/v3/snapshots/${snapshotId}/resources/resource-to-annotation/${id}`,
    );
    return response?.data;
  },

  async updateInfo({
    drawingVectorId,
    bulkPolygonId,
    data,
  }: {
    drawingVectorId: number;
    bulkPolygonId: number;
    data: { name?: string; completionDate?: string };
  }) {
    const response = await apiAxios.post(
      `/api/v4/earthwork/subgrade-quantity/polygon-vector/${drawingVectorId}/bulk-polygon/${bulkPolygonId}`,
      data,
    );
    return response?.data;
  },

  version: {
    list: async ({ projectId, resourceId }: { projectId: number; resourceId: number }) => {
      const response = await apiAxios.get<{ result: DrawingVectorVersion[] }>(
        `/api/v4/earthwork/project/${projectId}/subgrade-quantity/polygon-vector/${resourceId}/version`,
      );
      return response?.data?.result || [];
    },
    update: async ({
      projectId,
      versionId,
      data,
    }: {
      projectId: number;
      versionId: number;
      data: Partial<DrawingVectorVersion>;
    }) => {
      const response = await apiAxios.post(
        `/api/v4/earthwork/project/${projectId}/subgrade-quantity/polygon-vector/version/${versionId}`,
        data,
      );
      return response;
    },
    delete: async ({ projectId, versionId }: { projectId: number; versionId: number }) => {
      const response = await apiAxios.delete(
        `/api/v4/earthwork/project/${projectId}/subgrade-quantity/polygon-vector/version/${versionId}`,
      );
      return response;
    },
  },
};
