import React, { lazy } from 'react';
const SnapshotFormPage = lazy(
  () =>
    import(
      /* webpackChunkName: "SnapshotFormPage" */ 'features/snapshot/formPage/SnapshotFormPage'
    ),
);

function Edit(props) {
  return <SnapshotFormPage {...props} isEditPage />;
}

export default Edit;
