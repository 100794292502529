import { OBJECTDETECTION_CLASS_COLOR } from 'shared/styles/colors/symbols';
import { atom } from 'jotai';

export const GivenObjectDetectionClassName = {
  Plane: 'plane',
  Ship: 'ship',
  SmallVehicle: 'smallVehicle',
  LargeVehicle: 'largeVehicle',
  Uncategorized: 'uncategorized',
} as const;

export type GivenObjectDetectionClassName =
  typeof GivenObjectDetectionClassName[keyof typeof GivenObjectDetectionClassName];

export type ObjectDetectionState = {
  detectedClassName: string;
  visible: boolean;
  color: string;
};

export const GivenObjectDetectionClassColors = {
  plane: OBJECTDETECTION_CLASS_COLOR.RED,
  ship: OBJECTDETECTION_CLASS_COLOR.GREEN,
  smallVehicle: OBJECTDETECTION_CLASS_COLOR.YELLOW,
  largeVehicle: OBJECTDETECTION_CLASS_COLOR.PURPLE,
  uncategorized: OBJECTDETECTION_CLASS_COLOR.BLUE,
} as const;

const objectDetectionEnabledAtom = atom<boolean>(false);
const objectDetectionExpandedAtom = atom<boolean>(false);
const objectDetectionStatesAtom = atom<ObjectDetectionState[]>([]);
const selectedRightSideObjectClassAtom = atom<string>('');
const selectedFeatureIdAtom = atom<string | false>(false);
const hiddenFeatureIdsAtom = atom<string[]>([]);

export const objectDetectionAtom = {
  enabled: objectDetectionEnabledAtom,
  expanded: objectDetectionExpandedAtom, //accordion expanded
  states: objectDetectionStatesAtom,
  selectedRightSideClass: selectedRightSideObjectClassAtom, // right side class name
  selectedFeatureId: selectedFeatureIdAtom,
  hiddenFeatureIds: hiddenFeatureIdsAtom,
};
