import React, { lazy } from 'react';
const ProjectMemberPage = lazy(
  () =>
    import(
      /* webpackChunkName: "projectMemberPage" */ 'features/project/memberPage/ProjectMemberPage'
    ),
);

function ProjectMember() {
  return <ProjectMemberPage />;
}

export default ProjectMember;
