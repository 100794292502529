import {
  PointCloudListParams,
  PointCloudListResult,
  PointCloudResolutionParams,
  PointCloudResolutionResult,
  V4Response,
} from 'shared/query/resource/pointCloud/types';
import { apiAxios } from 'shared/common/api/common';

export default {
  async getResourcePointCloudList({ snapshotId }: PointCloudListParams) {
    const response = await apiAxios.get<V4Response<PointCloudListResult, unknown>>(
      `/api/v4/resource/point-cloud/snapshot/${snapshotId}`,
    );
    return response.data.result.pointCloudList;
  },
  async requestResourcePointCloudResolution({
    pointCloudId,
    resolution,
  }: PointCloudResolutionParams) {
    const response = await apiAxios.post<V4Response<PointCloudResolutionResult, unknown>>(
      `/api/v4/resource/point-cloud/${pointCloudId}/resolution`,
      { resolution },
    );
    return response.data.result.pointCloud;
  },
};
