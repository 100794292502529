import { PermissionType } from 'shared/common/types';
import { useMutation } from 'react-query';
import api from './api';

export function useProjectInviteeMutation(projectId: number, inviteeId: number) {
  const update = useMutation((payload: { permission: PermissionType }) =>
    api.update({ projectId, inviteeId, data: payload }),
  );
  return { update };
}
