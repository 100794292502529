import { create } from 'apisauce';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { DEFAULT_HEADERS, DEFAULT_REQUEST_TIMEOUT } from 'shared/common/policies/request';
import {
  convertKeysToCamelCase,
  convertKeysToSnakeCase,
  getJWTToken,
  getRefreshToken,
  isTokenRefreshing,
  lock,
  modifyJwtToken,
  needToRefreshToken,
  setRequestTime,
  unlock,
} from 'shared/common/utils';
import * as urls from '../urls';
import { feedback } from 'shared/services/feedback.service';
import { nls } from 'shared/locale/language';

const axiosRequestTransformer = (data, headers) => {
  if (headers['Content-Type'] === 'application/json') {
    if (data instanceof Array) {
      return JSON.stringify(data.map((item) => convertKeysToSnakeCase({ ...item })));
    } else {
      return JSON.stringify(convertKeysToSnakeCase({ ...data }));
    }
  } else {
    // if (headers['Content-Type'] === 'multipart/form-data') { .. etc
    return data;
  }
};

const axiosResponseTransformer = (data, headers) => {
  if (!data || !headers['content-type'].includes('application/json')) {
    return data;
  }
  return convertKeysToCamelCase(JSON.parse(data));
};

export const apiAxios = axios.create({
  baseURL: urls.baseUrl,
  headers: DEFAULT_HEADERS,
  timeout: DEFAULT_REQUEST_TIMEOUT,
});
/**
 * FIXME 통합 api header 개발 시 제거 될 예정
 */
export const pureAxios = axios.create();

/**
 * @deprecated 대신에 apiAxios를 사용. URL에 '/auth'를 추가해서 사용해야함
 */
export const authClient = create({
  baseURL: urls.authUrl,
  timeout: DEFAULT_REQUEST_TIMEOUT,
  headers: DEFAULT_HEADERS,
});

/**
 * @deprecated 대신에 apiAxios를 사용. URL에 '/api'를 추가해서 사용해야함
 */
export const defaultClient = create({
  baseURL: urls.apiUrl,
  timeout: DEFAULT_REQUEST_TIMEOUT,
  headers: DEFAULT_HEADERS,
});

/**
 * @deprecated 대신에 apiAxios를 사용. URL에 '/api'를 추가하고, header에 Content-Type을 추가로 정의하여 사용해야함.
 */
export const multiClient = create({
  baseURL: urls.apiUrl,
  headers: {
    ...DEFAULT_HEADERS,
    'Content-Type': 'multipart/form-data',
  },
});

const handleResponseError = (error: AxiosError) => {
  const { request } = error;
  if (request?.status === 0 || request?.status === 403) {
    feedback.alert(nls.WAF_ERROR());
  }
  return Promise.reject(error);
};
apiAxios.interceptors.request.use(checkToken, handleResponseError);
apiAxios.interceptors.response.use((response) => response, handleResponseError);
pureAxios.interceptors.response.use((response) => response, handleResponseError);
authClient.axiosInstance.interceptors.response.use((response) => response, handleResponseError);
defaultClient.axiosInstance.interceptors.response.use((response) => response, handleResponseError);
multiClient.axiosInstance.interceptors.response.use((response) => response, handleResponseError);
apiAxios.defaults.transformRequest = [axiosRequestTransformer];
apiAxios.defaults.transformResponse = [axiosResponseTransformer];

export function setAuthHeader(token: any, client) {
  if (token) {
    client.setHeader('Watch-Token', token);
  } else {
    client.setHeader('Authorization', `JWT ${getJWTToken()}`);
  }
}

let refreshSubscribers = [];
const onTokenRefreshed = (accessToken) => {
  if (refreshSubscribers?.length === 0) return 0;
  refreshSubscribers.map((callback) => callback(accessToken));
  refreshSubscribers = [];
};

const addRefreshSubscriber = (callback) => {
  refreshSubscribers.push(callback);
};

async function checkToken(config: AxiosRequestConfig) {
  // data에 저장할 경우 formData 형식으로 된 경우가 있어서 params에 token 관련 데이터를 저장하도록 함.
  // 토큰이 필요없을 경우라고 정의해놓았을 경우 헤더에 토큰 설정을 하지 않는다.
  if (config.params?.noToken) {
    const { noToken, ...restConfig } = config?.params;
    return { ...config, params: restConfig };
  }

  // watch token으로 API 조회해야할 경우 헤더를 Watch-Token으로 설정한다.
  if (config.params?.token) {
    const { token, ...rest } = config?.params;
    return { ...config, params: rest, headers: { 'Watch-Token': token } };
  }

  const jwtToken = getJWTToken();
  // 토큰이 없을 경우
  if (!jwtToken) {
    return Promise.reject(new Error('no token'));
  }
  // 토큰이 필요 없는 경우
  if (!needToRefreshToken()) {
    // 혹시 다른데서 토큰이 리프레시되고 그 사이에 쌓인 요청이 있다면 쌓여있는 요청을 처리.
    onTokenRefreshed(jwtToken);
    return { ...config, headers: { ...config.headers, Authorization: `JWT ${jwtToken}` } };
  }

  // 토큰 갱신중일 경우 요청을 쌓아둔다.
  if (isTokenRefreshing()) {
    const retryOriginalRequest = new Promise((resolve) => {
      addRefreshSubscriber((accessToken) => {
        config.headers.Authorization = `JWT ${accessToken}`;
        resolve(config);
      });
    });
    return retryOriginalRequest;
  }

  // 토큰 갱신
  const accessToken = await requestRefreshToken();

  // 토큰 갱신 완료 후 쌓아둔 요청을 처리.
  onTokenRefreshed(accessToken);

  // 토큰 갱신 완료 후 요청을 처리.
  return { ...config, headers: { ...config.headers, Authorization: `JWT ${accessToken}` } };
}
async function requestRefreshToken() {
  lock();
  const reqTime = new Date().getTime();
  const { data } = await axios.post(
    `/auth/refresh`,
    { refresh: getRefreshToken() },
    { headers: { Authorization: `JWT ${getJWTToken()}` }, baseURL: urls.baseUrl },
  );
  setRequestTime(reqTime);
  modifyJwtToken(convertKeysToCamelCase(data));
  unlock();
  return data?.access;
}
