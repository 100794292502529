import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Menu, MenuItem } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React, { useState } from 'react';
import styled from 'styled-components';

interface Props {
  folderItemList: { name: string; isCurrent: boolean; isLoading: boolean; onClick: () => void }[];
}

export default function ProjectPathNavigationMenu({ folderItemList }: Props) {
  const [moreAnchorEl, setMoreAnchorEl] = useState<HTMLElement | null>(null);

  function handleClick(fn) {
    setMoreAnchorEl(null);
    fn?.();
  }

  return (
    <>
      <IconButton size="small" onClick={(e) => setMoreAnchorEl(e.currentTarget)}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={moreAnchorEl}
        open={Boolean(moreAnchorEl)}
        onClose={() => setMoreAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {folderItemList.map((folderItem) => (
          <StyledMenuItem key={folderItem.name} onClick={() => handleClick(folderItem.onClick)}>
            {folderItem.name}
          </StyledMenuItem>
        ))}
      </Menu>
    </>
  );
}

const StyledMenuItem = styled(MenuItem).attrs({ variant: 'body2' })`
  padding: 0.25rem 1rem;
  min-width: 11.25rem;
`;
