import React, { lazy } from 'react';
const ProjectFileArchivePage = lazy(
  () =>
    import(
      /* webpackChunkName: "ProjectFileArchivePage" */ 'features/project/fileArchivePage/ProjectFileArchivePage'
    ),
);

function Archive() {
  return <ProjectFileArchivePage />;
}

export default Archive;
