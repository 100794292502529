import React from 'react';
import styled from 'styled-components';
import { others } from 'shared/styles/colors/others';
import { COLOR_CHIP } from 'shared/styles/colors/symbols';
import { hexToRgba } from 'shared/styles/colors/utils';
import theme from 'shared/styles/mui/theme';

/**
 * Figma Link
 * https://www.figma.com/file/R3wgOYka5xJVqd6F1VJubN/%F0%9F%91%A9%E2%80%8D%F0%9F%92%BBPlatform-Styles-%F0%9F%96%A5?type=design&node-id=2870-23230&mode=design&t=zqK7DkLMp3sG3u56-4
 */

interface Props {
  label: string;
  colorType: keyof typeof CHIP_COLOR_SET;
  borderRadius?: 'rounded' | 'circle';
  style?: React.CSSProperties;
}

/** @todo primary color type 추가해서, colorType null 일 때 프라이머리가 나오도록 설정 */
export default function OutlinedChip({ label, colorType, borderRadius = 'rounded', style }: Props) {
  return (
    <StyledChip $colorType={colorType || 'blue'} $borderRadius={borderRadius} style={style}>
      {label}
    </StyledChip>
  );
}

const StyledChip = styled.div<{
  $colorType: keyof typeof CHIP_COLOR_SET;
  $borderRadius?: 'rounded' | 'circle';
}>`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  text-decoration: none;
  text-transform: none;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1;
  padding: 0.25rem 0.375rem;
  border-radius: ${({ $borderRadius }) => ($borderRadius === 'circle' ? '1rem' : '0.25rem')};
  background-color: ${({ $colorType }) => CHIP_COLOR_SET[$colorType].background};
  border: 1px solid ${({ $colorType }) => CHIP_COLOR_SET[$colorType].border};
  color: ${({ $colorType }) => CHIP_COLOR_SET[$colorType].text};
`;

const CHIP_COLOR_SET = {
  primary: {
    border: 'rgba(0, 172, 193, 0.08)',
    background: 'rgba(0, 172, 193, 0.08)',
    text: theme.palette.primary.main,
  },
  warning: {
    background: '#FFF5E5',
    border: hexToRgba(theme.palette.warning.main, 0.1),
    text: theme.palette.warning.main,
  },
  blue: {
    background: '#EAF2FD',
    border: '#D7E7FB',
    text: COLOR_CHIP.BLUE,
  },
  orange: {
    background: hexToRgba(COLOR_CHIP.ORANGE, 0.1),
    border: hexToRgba(COLOR_CHIP.ORANGE, 0.2),
    text: COLOR_CHIP.ORANGE,
  },
  purple: {
    background: hexToRgba(COLOR_CHIP.PURPLE, 0.1),
    border: hexToRgba(COLOR_CHIP.PURPLE, 0.2),
    text: COLOR_CHIP.PURPLE,
  },
  green: {
    background: hexToRgba(COLOR_CHIP.GREEN, 0.1),
    border: hexToRgba(COLOR_CHIP.GREEN, 0.2),
    text: COLOR_CHIP.GREEN,
  },
  grey: {
    background: theme.palette.background.paper,
    border: others.BORDER,
    text: theme.palette.text.secondary,
  },
  disabled: {
    background: theme.palette.background.paper,
    border: others.BORDER,
    text: theme.palette.grey[500],
  },
};
