import React, { lazy } from 'react';
const CompareChangeDetectionPage = lazy(
  () =>
    import(
      /* webpackChunkName: "compareChangeDetectionPage" */ 'features/snapshot/comparePage/changeDetection/CompareChangeDetectionPage'
    ),
);

function CompareChangeDetection() {
  return <CompareChangeDetectionPage />;
}

export default CompareChangeDetection;
