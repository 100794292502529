import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { getLocale, nls } from 'shared/locale/language';

/** Date 정책
 *  1. ISO 8601 국제 표준 형식을 사용한다.
 *  2. 언어에 관련 없이 YYYY-MM-DD HH:MM:SS 형식을 사용한다.
 *  3. 요일이 필요한 경우 YYYY-MM-DD (ddd) 형식을 사용한다.
 */

type CommonLocaleDate =
  | 'DEFAULT_DATE'
  | 'DATE_WITH_DAY'
  | 'DATE_WITH_TIME'
  | 'DATE_WITH_TIME_SECOND';

export function getCommonLocaleDate(
  date: string | Date,
  type: CommonLocaleDate = 'DEFAULT_DATE',
): string {
  try {
    const parsedDate = typeof date === 'string' ? new Date(date) : date;

    const options = { locale: getLocale() };
    switch (type) {
      case 'DATE_WITH_DAY':
        return format(parsedDate, 'yyyy-MM-dd (eee)', options);
      case 'DATE_WITH_TIME':
        return format(parsedDate, 'yyyy-MM-dd HH:mm', options);
      case 'DATE_WITH_TIME_SECOND':
        return format(parsedDate, 'yyyy-MM-dd HH:mm:ss', options);
      case 'DEFAULT_DATE':
      default:
        return format(parsedDate, 'yyyy-MM-dd', options);
    }
  } catch (e) {
    return '';
  }
}

/* public function */
export const dateFormat = {
  /** 2021-02-24 */
  DASH: 'yyyy-MM-dd',
  /** 2021.02.24 */
  DOT: 'yyyy.MM.dd',
  /** 2021년 2월 24일 */
  LOCALE: 'PPP',
  /** 2021년 2월 24일 (금) */
  LOCALE_WITH_DAY: 'PPP (ccc)',
  /** 2021년 2월 24일 오전 10:00 */
  LOCALE_WITH_DAY_TIME: 'PPP a h:mm',
  /** 10월 1일 or 1st October */
  LOCALE_WITHOUT_YEAR: nls.localeDateWithoutYearFormat(),
  /** 2021년 2월 24일 or 1st October 2022 */
  LOCALE_WITHOUT_COMMA: nls.localeDateWithoutCommaFormat(),
  /** 15:00 */
  TIME: 'HH:mm',
  /** 15:00:00 */
  TIME_WITH_SECOND: 'HH:mm:ss',
};

export const setToMidnight = (date: Date) => {
  return format(date, "yyyy-MM-dd'T'00:00:00+00:00");
};

/** @deprecated */
export const dateToString = (date: Date, formatStr: string = dateFormat.DASH): string => {
  if (!date) return '';
  return format(date, formatStr, { locale: getLocale() });
};

/**
 * ISO-8601 형식의 날짜 문자열을 날짜 타입으로 변환한다.
 * @example 2019-01-09
 * @param str
 * @deprecated
 */
export const stringToDate = (str: string): Date => {
  if (!str) return null;

  return parseISO(str);
};

/**
 * ISO-8601 형식의 날짜 문자열을 다른 형식의 날짜 문자열로 변환한다.
 * @example
 * @param str
 * @param format default dateFormat.LOCALE
 * @deprecated
 */
export const convertDateFormat = (str: string, format = dateFormat.LOCALE): string => {
  if (!str) return '';

  return dateToString(stringToDate(str), format);
};

/**
 * 지금 날짜와 비교 날짜 간의 차이를 반환한다.
 * @param now
 * @param date
 * @deprecated
 */
export const timeAgo = (now: Date, date: Date): string => {
  const diff = Math.max(0, now.valueOf() - date.valueOf());
  const days = diff / (1000 * 60 * 60 * 24);
  const months = days / 30;
  if (months >= 1) {
    return nls.monthsAgo(Math.floor(months));
  }

  const weeks = days / 7;
  if (weeks >= 1) {
    return nls.weeksAgo(Math.floor(weeks));
  }

  const hours = days * 24;
  if (hours >= 1) {
    return nls.hoursAgo(Math.floor(hours));
  }

  const minutes = hours * 60;
  if (minutes >= 1) {
    return nls.minutesAgo(Math.floor(minutes));
  }

  const seconds = minutes * 60;
  return nls.secondsAgo(Math.floor(seconds));
};
