import { DEFAULT_POLLING_INTERVAL } from 'shared/common/policies/request';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import api from './api';
import { keys } from './queryKeys';
import { Volume } from './types';

export const useVolumeMutation = () => {
  const queryClient = useQueryClient();
  const createVolume = useMutation(
    ({ snapshotId, polygonId, data }: Parameters<typeof api.volumes.create>[0]) =>
      api.volumes.create({ snapshotId, polygonId, data }),
    {
      onSuccess: (data, v) => {
        queryClient.invalidateQueries(keys.volume.listByPolygonId(v?.polygonId));
        queryClient.invalidateQueries(keys.volume.detail(v?.polygonId, data?.id));
      },
    },
  );
  return { createVolume };
};

export const useVolumeListQuery = ({ snapshotId, polygonId }) => {
  const { data: volumes, status } = useQuery<Volume[]>(
    keys.volume.listByPolygonId(polygonId),
    () => api.volumes.list({ snapshotId, polygonId }),
    {
      enabled: Boolean(snapshotId && polygonId),
    },
  );
  return { volumes, status };
};

export const useVolumeQuery = ({
  snapshotId,
  polygonId,
  volumeId,
}: Parameters<typeof api.volumes.read>[0]) => {
  const [refetchInterval, setRefetchInterval] = useState<number | false>(false);
  const queryClient = useQueryClient();

  return useQuery(
    keys.volume.detail(polygonId, volumeId),
    () => api.volumes.read({ snapshotId, polygonId, volumeId }),
    {
      refetchInterval,
      enabled: Boolean(snapshotId && polygonId && volumeId),
      onSuccess: (newVolume) => {
        setRefetchInterval(
          newVolume?.calculationStatus === 'IN_PROGRESS' ? DEFAULT_POLLING_INTERVAL : false,
        );
        queryClient.setQueriesData<Volume[]>(keys.volume.list(), (prev) => {
          return prev?.map((v) => (v.id === volumeId ? newVolume : v));
        });
      },
    },
  );
};
