import { Extension } from 'shared/common/types';

export const formatFileSize = (bytes: number) => {
  if (bytes === 0 || !bytes) {
    return '';
  }
  const k = 1024;
  const dm = 2;
  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const size = parseFloat((bytes / k ** i).toFixed(dm)).toString();

  return `${size} ${units[i]}`;
};

export const isValidFormat = (file: File, extension: Extension) => {
  return file?.name && file.name.toLowerCase().match(extension);
};
export const filterValidExtension = (files: File[], extension: Extension) => {
  return files.filter((file) => isValidFormat(file, extension));
};

export const downloadFile = (url: string, name?: string) => {
  const downloadName = name ?? getFileNameFromPath(url);
  const element = document.createElement('a');
  element.setAttribute('href', url);
  element.setAttribute('download', downloadName);

  element.style.display = 'none';

  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const getFileNameFromPath = (path: string) => {
  const splitLink = (path || '').split('/');
  return splitLink[splitLink.length - 1].split(/\.[a-z]+/)[0];
};
