import React, { lazy } from 'react';
const DtmEditorPage = lazy(
  () =>
    import(
      /* webpackChunkName: "dtmEditorPage" */ 'features/snapshot/dtmEditorPage/DtmEditorPage'
    ),
);

function DtmEditor() {
  return <DtmEditorPage />;
}

export default DtmEditor;
