import TableRow from '@material-ui/core/TableRow';
import styled, { css } from 'styled-components';
import theme from 'shared/styles/mui/theme';

const headerRowStyle = css`
  max-height: 2.25rem;
  height: 1.875rem;

  th {
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
`;
const StyledTableRow = styled(TableRow)<{ $header?: boolean }>`
  ${({ $header = false }) => $header && headerRowStyle}

  th,
  td {
    background: ${theme.palette.background.paper};
    padding-left: 1rem;
    padding-right: 1rem;
    border-bottom: 1px solid ${theme.palette.divider};
    min-width: 7.5rem;

    &:first-of-type {
      padding-left: 0.375rem;
      padding-right: 0rem;
      min-width: 3rem;
    }

    &:nth-of-type(2) {
      padding-left: 0rem;
    }
  }

  /* 마지막 행에도 border-bottom을 줄 경우, 테이블 자체의 border-bottom과 겹쳐 보이게 됨 */
  /* 그렇다고 마지막 행의 border-bottom을 없애면 참여자가 적어서 스크롤 없을 때의 표시가 의도와 다르게 됨 */
  /* 따라서 마지막 행의 경우만 border-bottom 대신 box-shadow를 사용하여 경계선 나타내도록 함 */
  &:last-child td {
    border-bottom: none;
    box-shadow: 0 1px ${theme.palette.divider};
  }
`;

export default StyledTableRow;
