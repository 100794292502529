import React, { useEffect, useState } from 'react';
import { Typography, Drawer } from '@material-ui/core';
import theme from 'shared/styles/mui/theme';
import { ReactComponent as MobileAppIcon } from 'assets/images/iconMobileAppIcon.svg';
import { ReactComponent as MeissaMobileIcon } from 'assets/images/iconMeissaPlatformMobile.svg';
import MobileAppIconPng from 'assets/images/iconMobileAppIconPng.png';
import { ButtonPrimary } from 'features/common/button/ContainedButton';
import styled from 'styled-components';
import TextButton from 'features/common/button/TextButton';
import { useHistory, useLocation } from 'react-router';
import routes from 'pages/routes';
import { nls } from 'shared/locale/language';
import GA from 'shared/ga';
import { MOBILE_WEB } from 'shared/ga/category';
import {
  MOBILE_WEB_BANNER_GO_TO_APP_BTN,
  MOBILE_WEB_POPUP_GO_TO_APP_BTN,
  MOBILE_WEB_POPUP_STAY_WEB_BTN,
} from 'shared/ga/actions/mobileWeb';
import { isPosco } from 'shared/common/customize';
import Cookies from 'js-cookie';

function AppUsageInducementModal() {
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState<boolean>(false);

  const ua = window.navigator.userAgent.toLowerCase();
  const isAndroid = /android/gi.test(ua);
  const isIOS = /iphone|ipod/gi.test(ua);
  const isMobile = isAndroid || isIOS;

  if (isPosco()) return null;

  const showBannerArea = location.pathname === routes.login.path && isMobile;

  useEffect(() => {
    const modalClosed = Cookies.get('isOpenAppUsageInducementModal');
    if (!modalClosed && isMobile) {
      setOpen(true);
    }
  }, []);
  useEffect(() => {
    if (location.pathname === routes.mobile.path) {
      setOpen(false);
    }
  }, [location]);

  const goToDeepLinkByBanner = () => {
    GA.event(MOBILE_WEB, MOBILE_WEB_BANNER_GO_TO_APP_BTN);
    history.push(routes.mobile.path);
  };
  const goToDeepLinkByDrawer = () => {
    GA.event(MOBILE_WEB, MOBILE_WEB_POPUP_GO_TO_APP_BTN);
    history.push(routes.mobile.path);
  };
  const onCloseModal = () => {
    GA.event(MOBILE_WEB, MOBILE_WEB_POPUP_STAY_WEB_BTN);
    setOpen(false);
  };
  const onClickCloseModal = () => {
    Cookies.set('isOpenAppUsageInducementModal', 'true', { expires: 30 });
    GA.event(MOBILE_WEB, MOBILE_WEB_POPUP_STAY_WEB_BTN);
    setOpen(false);
  };

  return (
    <>
      {showBannerArea && (
        <TopBannerWrapper>
          <IconCommentWrapper>
            <MobileAppIcon />
            <CommentTypography>{nls.appInducementBannerMessage()}</CommentTypography>
          </IconCommentWrapper>
          <GoToAppButton onClick={goToDeepLinkByBanner}>
            {nls.appInducementBannerButtonLabel()}
          </GoToAppButton>
        </TopBannerWrapper>
      )}

      <CustomDrawer anchor="bottom" open={open} onClose={onCloseModal}>
        <DrawerContentWrapper>
          <IconLabelWrapper>
            <DrawerIcon src={MobileAppIconPng} alt="mobileAppIcon" />
            <MeissaMobileIcon />
          </IconLabelWrapper>
          <DrawerTypography>{nls.appInducementDrawerMessage()}</DrawerTypography>
          <DrawerGoToAppButton onClick={goToDeepLinkByDrawer}>
            {nls.appInducementDrawerDeepLinkButtonLabel()}
          </DrawerGoToAppButton>
          <DrawerCloseButton onClick={onClickCloseModal}>
            {nls.appInducementDrawerCloseButtonLabel()}
          </DrawerCloseButton>
        </DrawerContentWrapper>
      </CustomDrawer>
    </>
  );
}

export default AppUsageInducementModal;

const TopBannerWrapper = styled.div`
  position: relative;
  top: 0;
  width: 100%;
  height: 5rem;
  padding: 0.5rem 1rem;
  background-color: ${theme.palette.common.white};
  z-index: ${theme.zIndex.modal};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const IconCommentWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
`;
const CommentTypography = styled(Typography)`
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 160%;
  text-transform: capitalize;
  color: ${theme.palette.common.black};
`;
const GoToAppButton = styled(ButtonPrimary)`
  width: 8rem;
  height: 3rem;
`;
const CustomDrawer = styled(Drawer)`
  .MuiPaper-root {
    border-radius: 1.25rem 1.25rem 0 0;
  }
`;
const IconLabelWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
`;
const DrawerContentWrapper = styled.div`
  padding: 2rem 1rem 2.5rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const DrawerIcon = styled.img`
  width: 4rem;
  height: 4rem;
`;
const DrawerTypography = styled(Typography).attrs({
  variant: 'subtitle1',
  color: 'textSecondary',
})`
  font-size: 1.25rem;
  white-space: pre;
  text-align: center;
  font-weight: 600;
  margin-bottom: 1.5rem;
`;
const DrawerGoToAppButton = styled(ButtonPrimary)`
  width: 100%;
  margin-bottom: 0.5rem;
`;
const DrawerCloseButton = styled(TextButton).attrs({
  color: 'primary',
})`
  width: 100%;
  height: 2.5rem;
`;
