import React, { lazy } from 'react';
const ReadOnlyPageContainer = lazy(
  () => import(/* webpackChunkName: "readOnly" */ 'features/readOnlyPage/PageContainer'),
);

function Watch() {
  return <ReadOnlyPageContainer />;
}

export default Watch;
