import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import Cancel from '@material-ui/icons/Cancel';
import Search from '@material-ui/icons/Search';
import theme from 'shared/styles/mui/theme';
import React from 'react';
import styled from 'styled-components';

type Props = TextFieldProps & {
  variant?: 'outlined' | 'filled';
};

export default function SearchField({ variant = 'outlined', value, onChange, ...rest }: Props) {
  const valueStr = (value as string) || '';
  return (
    <StyledTextField
      {...rest}
      variant="outlined"
      $customVariant={variant}
      value={value}
      onChange={onChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment: valueStr?.length > 0 && (
          <InputAdornment position="end">
            <IconButton
              edge="end"
              size="small"
              onClick={(e) => onChange?.({ target: { value: '' } } as any)}
            >
              <Cancel />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

const StyledTextField = styled(TextField)<{ $customVariant: 'outlined' | 'filled' }>`
  width: 100%;
  margin: 0;
  padding: 0;
  border-radius: 0.25rem;

  .MuiInputBase-root {
    height: 2.5rem;
  }
  .MuiInputBase-input {
    font-size: 1rem;
    font-weight: 400;
    line-height: 175%;
  }

  .MuiSvgIcon-root {
    color: ${theme.palette.text.secondary};
  }
  .MuiIconButton-sizeSmall {
    padding: 0;
  }
  .MuiIconButton-edgeEnd {
    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  ${({ $customVariant }) =>
    $customVariant === 'filled' &&
    `
    background-color: ${theme.palette.grey[100]};
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${theme.palette.grey[100]};
    }
    `}
`;
