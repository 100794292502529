import React, { lazy } from 'react';
const SnapshotPrintPage = lazy(
  () =>
    import(
      /* webpackChunkName: "snapshotPrintPage" */ 'features/snapshot/printPage/SnapshotPrintPage'
    ),
);

function SnapshotPrint() {
  return <SnapshotPrintPage />;
}

export default SnapshotPrint;
