import React, { lazy } from 'react';
const MobileRedirectPage = lazy(
  () =>
    import(/* webpackChunkName: "mobileRedirectPage" */ 'features/redirect/MobileRedirectPage'),
);

import { mobile } from 'shared/common/policies/redirect';

function Mobile() {
  return <MobileRedirectPage {...mobile} />;
}

export default Mobile;
