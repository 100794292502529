import DateFnsUtils from '@date-io/date-fns';
import { DatePickerProps, KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { getLocale } from 'shared/locale/language';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'shared/styles/mui/theme';
import { getInputComponentBorderColor, getInputComponentBorderWidth } from './style/Styles';

type Props = DatePickerProps & { width?: string; height?: string };

export default function DatePicker({
  value = null,
  variant = 'dialog',
  format = 'yyyy-MM-dd',
  placeholder = '',
  ...rest
}: Props) {
  const locale = getLocale();
  const [open, setOpen] = useState<boolean>(false);
  const { views, onMonthChange, onChange, shouldDisableDate, width, height, onOpen } = rest;

  const showDatePicker = () => {
    if (rest?.disabled) {
      return;
    }
    setOpen(true);
  };
  const onCloseDatePicker = () => {
    setOpen(false);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
      <StyledDatePicker
        open={open}
        value={value}
        views={views}
        $width={width}
        disableToolbar
        onOpen={onOpen}
        $height={height}
        variant={variant}
        onChange={onChange}
        inputVariant="outlined"
        onClick={showDatePicker}
        format={format}
        placeholder={placeholder}
        onClose={onCloseDatePicker}
        onMonthChange={onMonthChange}
        shouldDisableDate={shouldDisableDate}
        inputProps={{
          readOnly: true,
        }}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
}

const StyledDatePicker = styled(KeyboardDatePicker)<
  DatePickerProps & { $width?: string; $height?: string }
>`
  width: ${({ $width }) => $width || '17.5rem'};
  border-radius: 0.25rem;

  .MuiInputBase-root {
    background: ${theme.palette.background.paper};
    padding-right: 0;
    height: ${({ $height }) => $height};
  }

  ${({ $height }) =>
    $height === '2.5rem' &&
    css`
      input {
        padding: 0 0 0 0.875rem;
      }
    `}

  fieldset,
  .MuiInputBase-root:hover input:not(:disabled) fieldset,
  .Mui-focused fieldset.MuiOutlinedInput-notchedOutline,
  .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    ${({ $height }) => $height !== '2.5rem' && getInputComponentBorderWidth}
    ${getInputComponentBorderColor}
    padding: 1.1rem 1.5rem 1.2rem 0.8rem;
    display: flex;
    justify-content: center;
  }
  fieldset,
  .MuiInputBase-root:hover input:not(:disabled) fieldset,
  .Mui-focused fieldset.MuiOutlinedInput-notchedOutline,
  .MuiOutlinedInput-root.Mui-disabled
    .MuiOutlinedInput-notchedOutline
    .hazard-event-history-header-date-picker {
    padding: 0;
  }

  .MuiOutlinedInput-adornedEnd {
    padding-right: 1px;
  }

  button:focus {
    outline: 0;
  }
`;
