import { CancelToken } from 'axios';
import { apiAxios } from 'shared/common/api/common';
import { PlanLevelResourceParams, Resource, ResourceType } from './types';
import { V4Response } from 'shared/query/types';

interface Props {
  snapshotId: number;
  resourceId: number;
  resourceIds: number[];
  zoneId: number;
  projectId: number;
  data: any;
  type: ResourceType;
  token: string;
}
export default {
  v4: {
    async create(data: {
      projectId: number;
      zoneId: number;
      filePath: string;
      fileName: string;
      fileSize: number;
      parentFolderId: number;
    }) {
      const response = await apiAxios.post(
        `/api/v4/resource/project/${data.projectId}/storage`,
        data,
      );
      return response?.data?.result;
    },
    async update(data: { resourceId: number; fileName?: string; description?: string }) {
      const response = await apiAxios.put(`/api/v4/resource/storage/${data.resourceId}`, data);
      return response?.data?.result;
    },
    async delete({ resourceId }: Pick<Props, 'resourceId'>) {
      const response = await apiAxios.delete(`/api/v4/resource/${resourceId}`);
      return response?.data?.result;
    },
  },
  async listBySnapshotId<T extends Resource>({
    snapshotId,
    type,
    token,
  }: Pick<Props, 'snapshotId'> & Partial<Pick<Props, 'type' | 'token'>>) {
    const response = await apiAxios.get<{ results: T[] }>(
      `/api/v3/snapshots/${snapshotId}/resources?type=${type ?? ''}`,
      {
        params: { token },
      },
    ); // watchtoken으로 읽을 경우
    return response?.data?.results ?? [];
  },
  async listByZoneId<RelationResource>({
    zoneId,
    snapshotId,
    type,
  }: Pick<Props, 'zoneId' | 'snapshotId' | 'type'>) {
    const response = await apiAxios.get<{ results: RelationResource[] }>(
      `/api/v3/zones/${zoneId}/resources?snapshot_id=${snapshotId}&type=${type ?? ''}`,
    );
    return response?.data?.results;
  },
  async listByProjectId<T extends Resource>({
    projectId,
    type,
  }: Pick<Props, 'projectId'> & Partial<Pick<Props, 'type'>>) {
    const response = await apiAxios.get<{ results: Resource[] }>(
      `/api/v3/projects/${projectId}/resources?type=${type ?? ''}`,
    );
    return response?.data?.results;
  },
  async create({
    snapshotId,
    cancelToken,
    type,
    name,
    filename,
    additional = {},
  }: Pick<Props, 'snapshotId'> & {
    type: ResourceType;
    name: string;
    filename: string;
    additional?: any;
    cancelToken: CancelToken;
  }) {
    const response = await apiAxios.post<Resource>(
      `/api/v3/snapshots/${snapshotId}/resources`,
      { type, name, filename, ...additional },
      { cancelToken, timeout: 3600_000 },
    );
    return response?.data;
  },
  async read<T extends Resource>({
    snapshotId,
    resourceId,
  }: Pick<Props, 'snapshotId' | 'resourceId'>) {
    const response = await apiAxios.get<T>(
      `/api/v3/snapshots/${snapshotId}/resources/${resourceId}`,
    );
    return response?.data;
  },
  async readByProjectId({ projectId, resourceId }: Pick<Props, 'projectId' | 'resourceId'>) {
    const response = await apiAxios.get<Resource>(
      `/api/v4/project/${projectId}/resource/${resourceId}`,
    );
    return response?.data;
  },
  async update({ snapshotId, resourceId, data }: Props) {
    const response = await apiAxios.patch<Resource>(
      `/api/v3/snapshots/${snapshotId}/resources/${resourceId}`,
      data,
    );
    return response?.data;
  },
  async delete({ zoneId, resourceId }: Pick<Props, 'zoneId' | 'resourceId'>) {
    const response = await apiAxios.delete<{ id: number }>(
      `/api/v3/zones/${zoneId}/resources/${resourceId}`,
    );
    return response?.data;
  },
  async deleteBulk({ zoneId, resourceIds }: Pick<Props, 'zoneId' | 'resourceIds'>) {
    const response = await apiAxios.delete<{ ids: number[]; message: string }>(
      `/api/v3/zones/${zoneId}/resources/bulk-delete`,
      { data: { resource_ids: resourceIds } },
    );
    return response?.data;
  },
  async deleteEtc({ projectId, resourceId }: Pick<Props, 'projectId' | 'resourceId'>) {
    const response = await apiAxios.delete<{ id: number }>(
      `/api/v3/projects/${projectId}/etcs/${resourceId}`,
    );
    return response?.data;
  },
  async archive({ zoneId, resourceIds }: Pick<Props, 'zoneId' | 'resourceIds'>) {
    const response = await apiAxios.post<{
      data: { status: string; zipUrl?: string };
      message: string;
    }>(`/api/v3/zones/${zoneId}/resources/archive`, { resourceIds });
    return response?.data;
  },
  async createWithoutFile<T extends Resource>({
    snapshotId,
    data,
  }: Pick<Props, 'snapshotId'> & { data }) {
    const response = await apiAxios.post<T>(
      `/api/v3/snapshots/${snapshotId}/resources/without-file`,
      data,
    );
    return response?.data;
  },
  bim: {
    async refreshToken({ zoneId }: Pick<Props, 'zoneId'>) {
      const response = await apiAxios.post(
        `/api/v3/zones/${zoneId}/resources/bim/refresh-asset-access-token`,
      );
      return response?.data;
    },
  },
  async createPlanLevelResourceFile({ snapshotId, data, cancelToken }: PlanLevelResourceParams) {
    const response = await apiAxios.post<V4Response<{ id: string }, { meta: unknown }>>(
      `/api/v4/resource/plan-level/snapshot/${snapshotId}`,
      data,
      { cancelToken },
    );
    return response.data;
  },
};
