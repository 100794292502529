import React, { lazy } from 'react';
const ProjectInfoPageContainer = lazy(
  () =>
    import(/* webpackChunkName: "projectInfoPage" */ 'features/project/infoPage/PageContainer'),
);

function ProjectInfo() {
  return <ProjectInfoPageContainer />;
}

export default ProjectInfo;
